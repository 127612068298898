import React, { useState } from 'react';
import {
    makeStyles,
    Backdrop
} from '@material-ui/core';
import { startPageLoader, closePageLoader } from '../helpers/commonHelper';
import { filterObjectByKeyName } from '../helpers/iterationHelper';
import MySlider from './mySlider';
import MyVerticalSlider from './myVerticalSlider';
import MySliderVertical from './mySliderVertical';
import MyVerticalSliderDecor from './myVerticalSliderDecor';
import MyVerticalSliderPopup from './myVerticalSliderPopup';
const useStyles = makeStyles((theme) => ({
    root: {},
    icon: {
        width: 40,
        zIndex: 999,
    },
    iconhovered: {
        width: 300,
        height: 300,
        borderRadius: 10,
    },
    image: {
        width: 40,
        height: 40,
        borderRadius: 10,
        border: '1px solid #707070',
        boxShadow: '1px 1px 2px 2px rgba(0, 0, 0, 0.1)',
    },
}));
const CategorySwitcher = props => {
    const classes = useStyles();
    const { hoverDecor, wrapperClass, iconWrapperClass, parentObj, title, isSlidable, arrowWidth, arrowHeight, arrowFill, clickOpensPopup, relState, changeScene, zIndex, openPopup, isVerticalSlider, forPopup, isPopupOpen } = props;
    const subCategories = Object.values(parentObj).filter(x => typeof x === "object");
    const isOrderPresent = subCategories.filter(x => x.displayOrder).length === subCategories.length;
    let orderedArr = [];
    if (isOrderPresent) {
        subCategories.map(x => orderedArr[x.displayOrder - 1] = x)
    }
    const arrToMap = orderedArr.length ? orderedArr : subCategories;
    //const sortedArr = sortArrayOfObj(subCategories, "name");
    const handleClick = (obj, event) => {
        // console.log('change')
        if (clickOpensPopup) {
            const modalPos = {
                top: event?.target?.getBoundingClientRect().y + 20,
                left: event?.target?.getBoundingClientRect().x
            }
            const relState = event?.target?.parentElement?.getAttribute("data-state");
            const zIndex = event?.target?.parentElement?.getAttribute("data-zIndex");
            // console.log(relState, zIndex)
            openPopup(obj, modalPos, relState, obj.zIndex);
        } else {
            startPageLoader();
            changeScene(relState, { ...obj, zIndex: obj.zIndex });
            closePageLoader();
        }
    }
    const findStateName = (keyName) => {
        let result = {
            relState: "",
            zIndex: 0
        }
        switch (keyName) {
            case "ceilingDecor": result.relState = "currCeilingDecor"; result.zIndex = 80; break;
            case "centerDecor": result.relState = "currCenterDecor"; result.zIndex = 70; break;
            case "floorDecor": result.relState = "currFloorDecor"; result.zIndex = 60; break;
            case "floors": result.relState = "currFloor"; result.zIndex = 20; break;
            case "leftSideDecor": result.relState = "currLeftDecor"; result.zIndex = 40; break;
            // case "productVersions": result.relState = "currProd"; result.zIndex = 50; break;
            case "rightSideDecor": result.relState = "currRightDecor"; result.zIndex = 40; break;
            case "walls": result.relState = "currWallDecor"; result.zIndex = 10; break;
            case "wallsDecor": result.relState = "currWallDecor"; result.zIndex = 30; break;
        }
        return result;
    }
    const renderIcon = (obj, key) => {
        let dataRelState = relState;
        let dataIndex = zIndex;
        // console.log(obj)
        if (relState === "decor" && obj) {
            // console.log(obj)
            let decorObj = filterObjectByKeyName(JSON.parse(sessionStorage.getItem("scene")), "decor");
            Object.keys(decorObj).forEach(key => {
                if (decorObj.decorations.name === obj.name) {
                    let dataVal = findStateName(key);
                    dataRelState = dataVal.relState;
                    dataIndex = dataVal.zIndex;
                }
            })
        }
        return (
            obj &&
            <>
                <div key={key} className={iconWrapperClass} style={{ backgroundColor: obj.isDefault && '#E6E5E8' }} onClick={e => handleClick(obj, e)} data-state={dataRelState} data-zIndex={dataIndex}>
                    {obj.icon && <img src={obj.icon} className="icon" />}
                    {obj.name && <h6>{obj.name}</h6>}
                </div>
            </>
        )
    }

    document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
    });

    const onMouseIn = (obj) => {
        props.setHovered(true);
        props.setSelectedItem(obj);
    }
    const onMouseOut = () => {
        props.setHovered(false);
        props.setSelectedItem(null);
    }

    const renderIconPopup = (obj, key) => {
        let dataRelState = relState;
        let dataIndex = zIndex;
        // console.log(obj)
        if (relState === "decor" && obj) {
            let decorObj = filterObjectByKeyName(JSON.parse(sessionStorage.getItem("scene")), "decor");
            Object.keys(decorObj).forEach(key => {
                if (decorObj.decorations.name === obj.name) {
                    let dataVal = findStateName(key);
                    dataRelState = dataVal.relState;
                    dataIndex = dataVal.zIndex;
                }
            })
        }
        // console.log(obj)
        return (
            obj &&
            <>
                <div key={key} className={iconWrapperClass} style={{ backgroundColor: obj.isDefault && '#E6E5E8' }} onClick={e => handleClick(obj, e)} data-state={dataRelState} data-zIndex={dataIndex}>
                    {obj.icon && <img src={obj.icon} onMouseUp={() => onMouseIn(obj)} onMouseDown={() => onMouseIn(obj)} onMouseEnter={() => onMouseIn(obj)} onMouseMove={() => onMouseIn(obj)} onMouseOver={() => onMouseIn(obj)} onMouseOut={() => onMouseOut()} className={obj.isDefault ? classes.image : classes.icon} />}
                    {obj.name && <h6 style={{ fontSize: 14 }}>{obj.name}</h6>}
                </div>
            </>
        )
    }
    return (
        <div className={wrapperClass}>
            <h5 style={{ fontWeight: 'bold' }}>{parentObj.name}</h5>
            {
                isSlidable ?
                    <MySlider hoverDecor={hoverDecor} isPopupOpen={isPopupOpen} numOfSlides={arrToMap.length} arrowWidth={arrowWidth} arrowHeight={arrowHeight} arrowFill={arrowFill}>
                        {arrToMap.map((category, index) => renderIcon(category, index))}
                    </MySlider> :
                    isVerticalSlider && forPopup ?
                        <MyVerticalSliderPopup content={arrToMap} renderIcon={renderIconPopup} relState={parentObj.relState} zIndex={parentObj.zIndex} /> :
                        isVerticalSlider ?
                            <MyVerticalSlider content={arrToMap} renderIcon={renderIcon} relState={parentObj.relState} zIndex={parentObj.zIndex} /> :
                // <MyVerticalSliderDecor content={arrToMap} renderIcon={renderIcon} relState={parentObj.relState} zIndex={parentObj.zIndex}/>
                arrToMap.map((category, index) => renderIcon(category, index))
            }

        </div>
    )
}
export default CategorySwitcher;