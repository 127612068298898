import { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import MySlider from '../components/mySlider';
import SceneCard from '../components/sceneCard';
import firebase from 'firebase';
import { firebaseConfig } from '../config/firebase.utils';
import { closePageLoader } from '../helpers/commonHelper';
import { getUser } from '../services/Settings';
import { useStateValue } from '../services/state/State';
import { actions } from '../services/state/Reducer';
import cancelDarkIcon from '../assets/cancel-dark.svg';

const ScenePage = props => {
    const history = useHistory();
    const [state, dispatch] = useStateValue();
    const { products, user } = state;
    const [scenes, setScenes] = useState({});
    const [walls, setWalls] = useState(null);
    const [floors, setFloors] = useState(null);
    const [decorations, setDecorations] = useState(null);
    const [clientScenes, setClientScenes] = useState({});
    const [sku, setSKU] = useState(null);

    const loadScenes = () => {
        // console.log(sku)
        const product = products.find(p => p.sku === sku);
        const productsVersions = [];
        product.versions.forEach((version, index) => {
            if (index === 0) {
                productsVersions.push({
                    [`productVersion${index + 1}`]: {
                        icon: version.icon,
                        image: version.scenario,
                        name: version.name,
                        isDefault: true
                    }
                })
            } else {
                productsVersions.push({
                    [`productVersion${index + 1}`]: {
                        icon: version.icon,
                        image: version.scenario,
                        name: version.name,
                    }
                })
            }
        })
        const dbRefObject = firebase.database().ref().child('scenes');
        dbRefObject.on('value', snap => {
            const snaps = [];
            Object.values(snap.val()).forEach(item => {
                if (item.products && item.products[sku]) {
                    // console.log(item.products[sku])
                    snaps.push(item);
                }
            });
            setScenes(snaps);
            closePageLoader();
        });

        const dbRefObject1 = firebase.database().ref().child('scenes');
        dbRefObject1.on('value', snap => {
            const snaps = [];
            Object.values(snap.val()).forEach(item => {
                if (item.clients && item.clients[user.id] && item.clients[user.id][sku]) {
                    console.log(item.clients[user.id][sku], item.image)
                    snaps.push(item);
                }
            });
            setClientScenes(snaps);
            closePageLoader();
        });
    }

    useEffect(() => {
        if (history.action === "PUSH") {
            window.location.reload();
        }
        const currentUser = getUser();
        if (!currentUser) {
            history.replace('/login');
        } else {
            if (props && props.match && props.match.params) {
                // console.log(props.match.params.sku);
                const skuFromUrl = props.match.params.sku;
                setSKU(skuFromUrl);
                if (!products) {
                    firebase.firestore().collection('clients').doc(currentUser.id === 'ITEK-100' ? currentUser.id : currentUser.email).collection('products').onSnapshot(snapshot => {
                        const products = [];
                        snapshot.docs.forEach(doc => products.push(doc.data()));
                        dispatch({ type: actions.SET_PRODUCTS, payload: products });
                    });
                }
            }
        }
    }, []);

    useEffect(() => {
        if (products && sku) {
            loadScenes();
        }
    }, [products, sku]);

    const goBack = e => {
        // dispatch({ type: actions.SET_LAST_LOCATION, payload: 'virtual-scenario'});
        // history.replace('/dashboard');
        history.goBack();
    }

    if (JSON.parse(localStorage.getItem('itek_user')) && JSON.parse(localStorage.getItem('itek_user')).userType === 'admin') {
        history.goBack();
        return null;
    }

    return (
        <div className="scenes-page">
            <div style={{ zIndex: 10, position: 'absolute', top: 38, right: 50, cursor: 'pointer' }}>
                <img className="close-icon" src={cancelDarkIcon} onClick={goBack}></img>
            </div>
            <div className="head">
                <div className="heading">
                    <h3>Welcome to the</h3>
                    <h1>Virtual Scenario</h1>
                </div>
                <div className="subheading">
                    <h6>Please select the scene you like to customize</h6>
                </div>
            </div>
            {/* <div className="slider">
                <MySlider isInfinite numOfSlides={Object.keys(scenes).length} >
                    {
                        scenes &&
                        Object.values(scenes).map((scene, index) =>
                            <SceneCard key={index} scene={scene} sku={sku} />
                        )
                    }
                </MySlider>
            </div> */}
            <div className="slider">
                <MySlider isInfinite numOfSlides={Object.keys(clientScenes).length} >
                    {
                        clientScenes &&
                        Object.values(clientScenes).map((scene, index) =>
                            <SceneCard key={index} scene={scene} sku={sku} user={user} />
                        )
                    }
                </MySlider>
            </div>
        </div>
    )
}
export default withRouter(ScenePage);
