import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import {
    Box,
    Grid,
    makeStyles,
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Dialog,
    Divider,
    SvgIcon,
    IconButton,
    CardContent,
    CircularProgress,
    Backdrop,
    Popover
} from '@material-ui/core';
import firebase from 'firebase';
import { closePageLoader, startPageLoader } from '../helpers/commonHelper';
import { useStateValue } from '../services/state/State';
import { actions } from '../services/state/Reducer';
import { getUser, removeUser, walls, floors } from '../services/Settings';
import Logo from '../assets/images/logo.png';
import DownloadCloud from '../assets/download-cloud.svg';
import UploadCloud from '../assets/upload-cloud.svg';
import UploadProducts from '../assets/uploadproducts.svg';
import DownloadProducts from '../assets/downloadproducts.svg';
import UploadDB from '../assets/uploaddb.svg';
import DownloadDB from '../assets/downloaddb.svg';
import FileDropzone from '../components/FileDropzone';
import {
    Edit as EditIcon,
    ChevronLeft as LeftIcon,
    ChevronRight as RightIcon,
    X as XIcon,
    Save as SaveIcon
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
        width: '100%',
        height: 25,
        backgroundColor: '#efefef',
        borderWidth: 0,
        // borderRadius: 5,
        // paddingLeft: 30,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight,
        textAlign: 'right',
        fontSize: 12,
    },
    image: {
        width: 60,
        borderRadius: 20,
        border: '1px solid #707070',
        boxShadow: '1px 1px 2px 2px rgba(0, 0, 0, 0.1)',
        marginTop: 15,
        marginBottom: 15
    },
    button: {
        'text-transform': 'none',
    },
    buttonRight: {
        'text-transform': 'none',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    buttonLabel: {
        fontSize: 10,
        color: '#000',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight,
    },
    roundButtonLabel: {
        color: '#000',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight,
        fontSize: 14,
    },
    roundButtonLabel1: {
        color: '#000',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 10,
    },
    slogan: {
        textAlign: 'justify',
        fontSize: 12,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight
    },
    labelStyle: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight,
        color: '#fff',
        fontSize: 15
    },
    labelNameStyle: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight,
    },
    inputLabelStyle: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight,
        color: '#000'
    },
    icon: {
        color: '#000',
        fontSize: 100,
    },
    downIcon: {
        color: '#fff',
        fontSize: 10,
    },
    tableHeader: {
        backgroundColor: '#FFB300',
        height: 30
    },
    titleStyle: {
        backgroundColor: '#353535',
        marginTop: 10
    },
    columnName: {
        color: '#fff',
        fontFamily: theme.typography.fontFamily,
    },
    oddItem: {
        backgroundColor: '#f0f0f0'
    },
    cell: {
        cursor: 'pointer',
        color: '#fff'
    },
    seeMoreYellow: {
        color: '#ffb302',
        fontSize: 13,
        fontWeight: theme.typography.fontWeightLight
    },
    seeMoreGrey: {
        color: 'grey',
        fontSize: 13,
        fontWeight: theme.typography.fontWeightLight
    },
    modal: {
        width: '20%',
        height: 500,
        borderRadius: 10,
        backgroundColor: '#f7f7f7',
        borderColor: '#707070',
        // opacity: 0.98,
        position: 'absolute',
        left: '10%',
        top: '15%',
        boxShadow: '1px 1px 3px 3px rgba(211,211,211, 0.1)',
    },
    modalTypography: {
        color: 'grey',
        fontSize: 15,
        fontWeight: theme.typography.fontWeightLight
    },
    close: {
        position: 'absolute',
        top: 20,
        right: 20,
        borderRadius: 15,
        height: 29,
        width: 29,
        backgroundColor: 'black',
    },
    checkboxList: {
        backgroundColor: '#fff',
        border: '1px solid #707070',
        maxWidth: 250,
        height: 300
    },
    roundButton: {
        border: '1px solid #000',
        width: 70,
        height: 30,
        borderRadius: 20,
        'text-transform': 'none',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    popover: {
        width: 300,
        padding: theme.spacing(1),
    },
    popoverAlert: {
        width: 350,
        padding: theme.spacing(1),
        height: 150,
        borderRadius: 10,
        backgroundColor: '#f5f5f5',
        opacity: 0.5,
        zIndex: 10,
    }
}));

const AdmindashboardPage = props => {
    const classes = useStyles();
    const history = useHistory();
    const [state, dispatch] = useStateValue();
    const { user, products, lastLocation } = state;
    //SET SCENE
    const [sceneId, setSceneId] = useState('');
    const [sceneIdName, setSceneIdName] = useState('');
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [subCategoryName, setSubCategoryName] = useState('');
    const [subCategoryDropDown, setSubCategoryDropDown] = useState(false);
    const [subCategoryIconURL, setSubCategoryIconURL] = useState('');
    const [zAxis, setZAxis] = useState('');
    const [name, setName] = useState('');
    const [name1, setName1] = useState('');
    const [lowResURL, setLowResURL] = useState('');
    const [highResURL, setHighResURL] = useState('');
    const [iconURL, setIconURL] = useState('');

    //SET VERSION FOR SCENES
    const [versionSceneId, setVersionSceneId] = useState('');
    const [clientId, setClientID] = useState('');
    const [productSKU, setProductSKU] = useState('');
    const [sceneName, setSceneName] = useState('');
    const [sceneImage, setSceneImage] = useState('');
    const [versionsArray, setVersionsArray] = useState('');
    const [versionName, setVersionName] = useState('');
    const [versionzIndex, setVersionzIndex] = useState('');
    const [versionIconURL, setVersionIconURL] = useState('');
    const [productImageURL, setProductImageURL] = useState('');
    const [productImageHighResURL, setProductImageHighResURL] = useState('');
    const [isDefault, setIsDefault] = useState(false);
    //SET PRODUCT
    const [sku, setSKU] = useState('');
    const [customerID, setCustomerID] = useState('');
    const [productName, setProductName] = useState('');
    const [group, setGroup] = useState('');
    const [description, setDescription] = useState('');
    const [clientSKU, setClientSKU] = useState('');
    const [thumbnailURL, setThumbnailURL] = useState('');
    const [productVersionName, setProductVersionName] = useState('');
    const [productVersionIconURL, setProductVersionIconURL] = useState('');
    const [productVersionID, setProductVersionID] = useState('');
    const [xmlPath, setXMLPath] = useState('');
    const [htmlPath, setHTMLPath] = useState('');

    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);

    const [scenes, setScenes] = useState(null);
    const [clients, setClients] = useState(null);
    const [clientProducts, setClientProducts] = useState(null);
    const [uploadJSON, setUploadJSON] = useState(false);
    const [uploadJSON1, setUploadJSON1] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState(null);
    const [files1, setFiles1] = useState(null);

    const [newCategoryPopover, setNewCategoryPopover] = useState('');
    const [newSubCategoryPopover, setNewSubCategoryPopover] = useState('');
    const [newSubCategoryIconURLPopover, setNewSubCategoryIconURLPopover] = useState('');
    const [newSubCategoryZAxisPopover, setNewSubCategoryZAxisPopover] = useState('');

    const [categoryPopover, setCategoryPopover] = useState(false);
    const [subCategoryPopover, setSubCategoryPopover] = useState(false);

    const [removeAlert, setRemoveAlert] = useState(false);
    const [addAlert, setAddAlert] = useState(false);

    const [removeAlert1, setRemoveAlert1] = useState(false);
    const [addAlert1, setAddAlert1] = useState(false);

    const [removeAlert2, setRemoveAlert2] = useState(false);
    const [addAlert2, setAddAlert2] = useState(false);

    const [removeAlert3, setRemoveAlert3] = useState(false);
    const [addAlert3, setAddAlert3] = useState(false);

    const [showSCTextField, setShowSCTextField] = useState(false);
    const [showVersionNameTextField, setShowVersionNameTextField] = useState(false);

    const remRef = React.useRef();
    const addRef = React.useRef();

    const remRef1 = React.useRef();
    const addRef1 = React.useRef();

    const remRef2 = React.useRef();
    const addRef2 = React.useRef();

    const remRef3 = React.useRef();
    const addRef3 = React.useRef();

    const categoryRef = React.useRef();
    const subCategoryRef = React.useRef();

    useEffect(() => {
        startPageLoader();
        const currentUser = getUser();
        if (!currentUser) {
            history.replace('/login');
        } else {
            dispatch({ type: actions.SET_USER, payload: currentUser });
            getScenes();
            getClients();
            getCategories();
            closePageLoader();
        }
    }, []);

    const handleAddCategory = () => {
        setCategoryPopover(false);
    };

    const handleAddSubCategory = () => {
        firebase.firestore().collection('settings').doc('settings').get().then(doc => {
            firebase.firestore().collection('settings').doc('settings').update({
                subCategories: {
                    ...doc.data().subCategories,
                    [category]: subCategories && subCategories.length > 0 ? [...subCategories, {
                        name: newSubCategoryPopover,
                        url: newSubCategoryIconURLPopover,
                        zIndex: newSubCategoryZAxisPopover,
                    }] : [{
                        name: newSubCategoryPopover,
                        url: newSubCategoryIconURLPopover,
                        zIndex: newSubCategoryZAxisPopover,
                    }]
                }
            }).then(() => {
                setNewSubCategoryPopover('');
                setNewSubCategoryIconURLPopover('');
                setNewSubCategoryZAxisPopover('');
            })
        })
        setSubCategoryPopover(false);
    };

    const getScenes = () => {
        const dbRefObject = firebase.database().ref().child('scenes');
        dbRefObject.on('value', snap => {
            const snaps = [];
            Object.keys(snap.toJSON()).forEach(item => {
                snaps.push(item);
            });
            setScenes(snaps);
            closePageLoader();
        });
    }

    const getClients = () => {
        firebase.firestore().collection('clients').get().then((docs) => {
            const clients = [];
            docs.docs.forEach((doc) => clients.push({
                email: doc.id,
                id: doc.data().id,
            }));
            setClients(clients);
        });
    }

    const getCategories = () => {
        firebase.firestore().collection('settings').doc('settings').onSnapshot((doc) => {
            const data = [];
            doc.data().categories.forEach((item) => data.push(item));
            setCategories(data);
        });
    }

    const getCategoriesIfSceneExist = (id) => {
        const scenesRef = firebase.database().ref().child('scenes');
        if (id !== "") {
            scenesRef.child(id).get().then(child => {
                if (child.toJSON()) {
                    setCategories(Object.keys(child.toJSON()));
                }
            })
        }
    }

    const getSubCategoryIfExist = (subCategory) => {
        const scenesRef = firebase.database().ref().child('scenes');
        if (subCategory !== "") {
            scenesRef.child(sceneId).child(category).child(subCategory).get().then(child => {
                if (child.toJSON()) {
                    setSubCategoryName(child.toJSON().name);
                    setSubCategoryIconURL(child.toJSON().icon);
                    setZAxis(child.toJSON().zIndex);
                }
            })
        }
    }

    const getSubCategories = (selectedCategory) => {
        setShowSCTextField(false);
        setShowVersionNameTextField(false);
        setSubCategoryDropDown('');
        setSubCategoryIconURL('');
        setZAxis('');
        setName('');
        setName1('');
        const scenesRef = firebase.database().ref().child('scenes');
        scenesRef.child(sceneId).child(selectedCategory.toLowerCase()).get().then(child => {
            if (child.toJSON()) {
                const data = Object.keys(child.toJSON())
                    .filter(a => a !== 'name');
                data.push('Create a new subcategory');
                setSubCategories(data);
            }
        })
        // firebase.firestore().collection('settings').doc('settings').onSnapshot((doc) => {
        //     setSubCategories(doc.data().subCategories[selectedCategory]);
        // });
    }

    const getCategoryName = (categoryId) => {
        const scenesRef = firebase.database().ref().child('scenes');
        console.log(sceneId, categoryId)
        scenesRef.child(sceneId).child(categoryId.toLowerCase()).get().then(child => {
            console.log(child.toJSON())
            if (child.toJSON()) {
                setSceneIdName(child.toJSON().name);
            }
        })
    };

    const getSubCategoryDetails = (selectedSubCategory) => {
        setName('');
        setName1('');
        const scenesRef = firebase.database().ref().child('scenes');
        scenesRef.child(sceneId).child(category.toLowerCase()).child(selectedSubCategory.toLowerCase()).get().then(child => {
            if (child.toJSON()) {
                const data = Object.keys(child.toJSON()).filter(a => a !== 'name' && a !== 'icon' && a !== 'zIndex');
                data.push('Create a new version');
                setVersionsArray(data);
                setSubCategoryIconURL(child.toJSON().icon);
                setZAxis(child.toJSON().zIndex);
            }
        })
        getSubCategoryIfExist(selectedSubCategory);
        // firebase.firestore().collection('settings').doc('settings').onSnapshot((doc) => {
        //     setSubCategories(doc.data().subCategories[selectedCategory]);
        // });
    }

    const getClientProducts = (clientId) => {
        firebase.firestore().collection('clients').doc(clientId).collection('products').get().then((docs) => {
            const clientProducts = [];
            docs.docs.forEach((doc) => clientProducts.push(doc.id));
            setClientProducts(clientProducts);
        });
    }

    const getProductDetails = (sku, clientId) => {
        if (sku === '' || clientId === '') {
            setProductName('');
            setGroup('');
            setClientSKU('');
            setDescription('');
            setThumbnailURL('');
        } else {
            firebase.firestore().collection('clients').doc(clientId).collection('products').doc(sku).get().then((doc) => {
                if (doc.data()) {
                    setProductName(doc.data().name);
                    setGroup(doc.data().group);
                    setClientSKU(doc.data().clientSKU);
                    setDescription(doc.data().description);
                    setThumbnailURL(doc.data().thumbnail);
                } else {
                    setProductName('');
                    setGroup('');
                    setClientSKU('');
                    setDescription('');
                    setThumbnailURL('');
                }
            });
        }
    }

    const getProductVersionDetails = (sku, clientId, version) => {
        console.log(sku, clientId, version)
        if (sku === '' || clientId === '' || version === '') {
            setProductVersionID('');
            setProductVersionName('');
            setProductVersionIconURL('');
            setXMLPath('');
            setHTMLPath('');
        } else {
            firebase.firestore().collection('clients').doc(clientId).collection('products').doc(sku).get().then((doc) => {
                if (doc.data() && doc.data().versions) {
                    doc.data().versions.forEach(v => {
                        if (v.name.toLowerCase() === version.toLowerCase()) {
                            console.log(v)
                            setProductVersionID(v.version);
                            setProductVersionName(v.name);
                            setProductVersionIconURL(v.icon);
                            setXMLPath(v.embededLink);
                            setHTMLPath(v.frameUrl);
                        }
                    })
                }
            });
        }
    }

    const handleOnClick = () => {
        removeUser();
        history.replace('/login');
    };

    function toTitleCase(str) {
        return str.replace(
          /(\w*\W*|\w*)\s*/g,
          function(txt) {
          return(txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
          }
        ); 
      }

    const handleAddScene = () => {
        let existingScene = "";
        const newcategory = category;
        const scenesRef = firebase.database().ref().child('scenes');
        if (subCategoryName === "" || subCategoryIconURL === ""  || zAxis === "" || name === "") {
            scenesRef.child(sceneId).child(newcategory.toLowerCase()).child('name').set(sceneIdName);
        } else {
            scenesRef.get().then((s) => Object.keys(s.val()).forEach(scene => {
                console.log(scene)
                if (scene === sceneId) {
                    existingScene = scene;
                }
            })).then(() => {
                if (existingScene === "") {
                    // console.log('not here')
                    scenesRef.child(sceneId).child(newcategory.toLowerCase()).set({
                        [subCategory.toLowerCase()]: {
                            [name.toLowerCase()]: {
                                name: name1,
                                image: lowResURL,
                                highRes: highResURL,
                                icon: iconURL,
                                isDefault: true,
                                id: name.toLowerCase(),
                            },
                            zIndex: zAxis,
                            icon: subCategoryIconURL,
                            name: subCategoryName !== "" ? subCategoryName : subCategory,
                        },
                        name: sceneIdName,
                    })
                } else {
                    console.log(subCategory)
                    scenesRef.child(sceneId).child(newcategory.toLowerCase()).get().then(child => {
                        if (child.toJSON()) {
                            scenesRef.child(sceneId).child(newcategory.toLowerCase()).child(subCategory.toLowerCase()).get().then(child => {
                                if (child.toJSON()) {
                                    scenesRef.child(sceneId).child(newcategory.toLowerCase()).child(subCategory.toLowerCase()).child(name.toLowerCase()).set({
                                        name: name1,
                                        image: lowResURL,
                                        highRes: highResURL,
                                        icon: iconURL,
                                        isDefault: false,
                                        id: name.toLowerCase(),
                                    })
                                } else {
                                    scenesRef.child(sceneId).child(newcategory.toLowerCase()).child(subCategory.toLowerCase()).child(name.toLowerCase()).set({
                                        name: name1,
                                        image: lowResURL,
                                        highRes: highResURL,
                                        icon: iconURL,
                                        isDefault: true,
                                        id: name.toLowerCase(),
                                    })
                                }
                            })
                            scenesRef.child(sceneId).child(newcategory.toLowerCase()).child(subCategory.toLowerCase()).child('icon').set(subCategoryIconURL)
                            scenesRef.child(sceneId).child(newcategory.toLowerCase()).child(subCategory.toLowerCase()).child('name').set(toTitleCase(subCategoryName !== "" ? subCategoryName : subCategory))
                            scenesRef.child(sceneId).child(newcategory.toLowerCase()).child(subCategory.toLowerCase()).child('zIndex').set(zAxis)
                        } else {
                            scenesRef.child(sceneId).child(newcategory.toLowerCase()).set({
                                [subCategory.toLowerCase()]: {
                                    [name.toLowerCase()]: {
                                        name: name1,
                                        image: lowResURL,
                                        highRes: highResURL,
                                        icon: iconURL,
                                        isDefault: true,
                                        id: name.toLowerCase(),
                                    },
                                    zIndex: zAxis,
                                    icon: subCategoryIconURL,
                                    name: toTitleCase(subCategoryName !== "" ? subCategoryName : subCategory),
                                },
                                name: sceneIdName,
                            })
                        }
                    })
                }
            })
        }
        // console.log(subCategory, subCategoryName, subCategoryIconURL, zAxis, name)
        alert('Added Successfully.');
        setName1('');
        setName('');
        setLowResURL('');
        setHighResURL('');
        setIconURL('');
        getScenes();
        setAddAlert(false);
    };

    const handleRemoveScene = () => {
        if (sceneId === '' || category === '' || subCategory === '') {
            alert('Scene ID, category and sub category are required to delete a item.')
        } else {
            const newcategory = newCategoryPopover === "" ? category : newCategoryPopover
            const scenesRef = firebase.database().ref().child('scenes');
            scenesRef.child(sceneId).child(newcategory.toLowerCase()).child(subCategory.toLowerCase()).get().then(item => {
                if (item.val().name === name) {
                    scenesRef.child(sceneId).child(newcategory.toLowerCase()).child(subCategory.toLowerCase()).remove()
                } else {
                    alert('Scene does not exist');
                }
            }).then(() => alert('Scene successfully removed.'));
        }
        setRemoveAlert(false);
    };

    const handleAddSceneVersion = () => {
        const scenesRef = firebase.database().ref().child('scenes');
        if (versionSceneId === '' && clientId === '' && productSKU === '' && sceneName === '' && versionName === '' && versionIconURL === '' && productImageURL === '') {

        } else if (versionName === '' || versionIconURL === '' || productImageURL === '') {
            console.log('here');
            scenesRef.child(versionSceneId).child('clients').child(clientId).get().then(child => {
                if (child.toJSON()) {
                    scenesRef.child(versionSceneId).child('clients').child(clientId).child(productSKU).child('name').set(sceneName)
                    scenesRef.child(versionSceneId).child('clients').child(clientId).child(productSKU).child('image').set(sceneImage)
                }
            })
        } else {
            scenesRef.child(versionSceneId).get().then(child => {
                scenesRef.child(versionSceneId).child('clients').child(clientId).child(productSKU).get().then(child => {
                    if (child.toJSON()) {
                        scenesRef.child(versionSceneId).child('clients').child(clientId).child(productSKU).child(versionName.toLowerCase()).set({
                            name: versionName,
                            icon: versionIconURL,
                            image: productImageURL,
                            highRes: productImageHighResURL,
                            zIndex: versionzIndex,
                        })
                    } else {
                        scenesRef.child(versionSceneId).child('clients').child(clientId).get().then(child => {
                            if (child.toJSON()) {
                                scenesRef.child(versionSceneId).child('clients').child(clientId).child(productSKU).set({
                                    name: sceneName,
                                    image: sceneImage,
                                    [versionName.toLowerCase()]: {
                                        name: versionName,
                                        icon: versionIconURL,
                                        image: productImageURL,
                                        highRes: productImageHighResURL,
                                        zIndex: versionzIndex,
                                    }
                                })
                            } else {
                                scenesRef.child(versionSceneId).child('clients').get().then(child => {
                                    if (child.toJSON()) {
                                        scenesRef.child(versionSceneId).child('clients').child(clientId).set({
                                            [productSKU]: {
                                                name: sceneName,
                                                image: sceneImage,
                                                [versionName.toLowerCase()]: {
                                                    name: versionName,
                                                    icon: versionIconURL,
                                                    image: productImageURL,
                                                    highRes: productImageHighResURL,
                                                    zIndex: versionzIndex,
                                                }
                                            }
                                        })
                                    } else {
                                        scenesRef.child(versionSceneId).get().then(child => {
                                            if (child.toJSON()) {
                                                scenesRef.child(versionSceneId).child('clients').set({
                                                    [clientId]: {
                                                        [productSKU]: {
                                                            name: sceneName,
                                                            image: sceneImage,
                                                            [versionName.toLowerCase()]: {
                                                                name: versionName,
                                                                icon: versionIconURL,
                                                                image: productImageURL,
                                                                highRes: productImageHighResURL,
                                                                zIndex: versionzIndex,
                                                                isDefault: true,
                                                            }
                                                        }
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
            }).then(() => alert('Successfully added.'))
            setVersionName('');
            setVersionzIndex('');
            setVersionIconURL('');
            setProductImageURL('');
            setProductImageHighResURL('');
        }
        setAddAlert1(false);
    };

    const handleRemoveSceneVersion = () => {
        const scenesRef = firebase.database().ref().child('scenes');
        scenesRef.child(versionSceneId).child('clients').child(clientId).child(productSKU).child(versionName.toLowerCase()).remove().then(() => alert('Successfully removed.'))
        setVersionName('');
        setVersionzIndex('');
        setVersionIconURL('');
        setProductImageURL('');
        setRemoveAlert1(false);
    };

    const handleSaveProduct = () => {
        console.log(customerID)
        if (sku === '' || clientSKU === '' || productName === '' || description === '' || group === '') {
            alert('Please fill all fields for product');
        } else {
            startPageLoader();
            firebase.firestore().collection('clients').doc(customerID).collection('products').doc(sku).get().then((doc) => {
                if (!doc.data()) {
                    firebase.firestore().collection('clients').doc(customerID).collection('products').doc(sku).set({
                        sku: sku,
                        clientSKU: clientSKU,
                        group: group,
                        name: productName,
                        description: description,
                        thumbnail: thumbnailURL,
                    }).then(() => {
                        closePageLoader();
                        setSKU('');
                        setClientSKU('');
                        setCustomerID('');
                        setGroup('');
                        setProductName('');
                        setDescription('');
                        setThumbnailURL('');
                        alert('Product uploaded successfully');
                    })
                } else {
                    firebase.firestore().collection('clients').doc(customerID).collection('products').doc(sku).update({
                        clientSKU: clientSKU,
                        group: group,
                        name: productName,
                        description: description,
                        thumbnail: thumbnailURL,
                    }).then(() => {
                        closePageLoader();
                        setSKU('');
                        setCustomerID('');
                        setClientSKU('');
                        setGroup('');
                        setProductName('');
                        setDescription('');
                        setThumbnailURL('');
                        alert('Product updated successfully');
                    })
                }
            })
        }
        setAddAlert3(false);
    };

    const handleRemoveProduct = () => {
        if (sku === '' || customerID === '') {
            alert('Product SKU and customer ID is required to remove product');
        } else {
            startPageLoader();
            firebase.firestore().collection('clients').doc(customerID).collection('products').doc(sku).delete().then(() => {
                closePageLoader();
                alert('Product removed successfully');
            }).catch(() => closePageLoader());
        }
        setRemoveAlert3(false);
    };

    const handleAddProductVersion = () => {
        if (sku === '' || customerID === '') {
            alert('Product SKU and customer ID is required to add version');
        } else {
            startPageLoader();
            let versions = [];
            firebase.firestore().collection('clients').doc(customerID).collection('products').doc(sku).get().then((doc) => {
                if (doc.data() && doc.data().versions) {
                    versions = doc.data().versions;
                }
            }).then(() => {
                if (versions.find(v => v.name === productVersionName)) {
                    const index = versions.findIndex(v => v.name === productVersionName);
                    versions[index] = {
                        embededLink: xmlPath,
                        frameUrl: htmlPath,
                        name: productVersionName,
                        icon: productVersionIconURL,
                        version: productVersionName,
                        id: productVersionID,
                    }
                } else {
                    versions.push({
                        embededLink: xmlPath,
                        frameUrl: htmlPath,
                        name: productVersionName,
                        icon: productVersionIconURL,
                        version: productVersionName,
                        id: productVersionID,
                        claimedQuotes: 0,
                        quotesPerMonth: 20
                    });
                }
                firebase.firestore().collection('clients').doc(customerID).collection('products').doc(sku).update({
                    versions: versions
                }).then(() => {
                    closePageLoader();
                    setProductVersionID('');
                    setProductVersionName('');
                    setProductVersionIconURL('');
                    setXMLPath('');
                    setHTMLPath('');
                    alert('Product version updated successfully');
                }).catch(() => closePageLoader())
            }).catch(() => closePageLoader());
        }
        setAddAlert2(false);
    };

    const handleRemoveProductVersion = () => {
        if (sku === '' || customerID === '' || productVersionName === '') {
            alert('Product SKU, customer ID and version name is required to remove version');
        } else {
            startPageLoader();
            let versions = [];
            firebase.firestore().collection('clients').doc(customerID).collection('products').doc(sku).get().then((doc) => {
                if (doc.data() && doc.data().versions) {
                    doc.data().versions.forEach(version => {
                        if (version.name !== productVersionName) {
                            versions.push(version);
                        }
                    });
                }
            }).then(() => firebase.firestore().collection('clients').doc(customerID).collection('products').doc(sku).update({
                versions: versions
            }).then(() => {
                closePageLoader();
                alert('Product version removed successfully');
            }).catch(() => closePageLoader())
            ).catch(() => closePageLoader());
        }
        setProductVersionID('');
        setProductVersionName('');
        setProductVersionIconURL('');
        setXMLPath('');
        setHTMLPath('');
        setRemoveAlert2(false);
    };

    const handleDownloadProducts = () => {
        if (clientId === '') {
            alert('Please select Client ID and then try downloading json of that client');
        } else {
            const products = [];
            firebase.firestore().collection('clients').doc(clientId).collection('products').get().then((doc) => {
                doc.docs.forEach(item => products.push({
                    productId: item.id,
                    productDetail: { ...item.data() }
                }))
            }).then(() => {
                download(JSON.stringify(products), `${clientId}-products.json`, 'application/json');
            });
        }
    };

    const handleUploadProducts = () => {
        const fileReader = new FileReader();
        fileReader.readAsText(files, "UTF-8");
        const client = clients.find(c => c.id === clientId).email;
        fileReader.onload = e => {
            setUploading(true);
            const productsData = JSON.parse(e.target.result);
            for (const item of productsData) {
                firebase.firestore().collection('clients').doc(client).collection('products').doc(item.productId).set({
                    name: item.productDetail.name ? item.productDetail.name : '',
                    clientSKU: item.productDetail.clientSKU ? item.productDetail.clientSKU : '',
                    description: item.productDetail.description ? item.productDetail.description : '',
                    gallery: item.productDetail.gallery ? [...item.productDetail.gallery] : [],
                    galleryFiles: item.productDetail.galleryFiles ? [...item.productDetail.galleryFiles] : [],
                    group: item.productDetail.group ? item.productDetail.group : '',
                    sku: item.productDetail.sku ? item.productDetail.sku : '',
                    thumbnail: item.productDetail.thumbnail ? item.productDetail.thumbnail : '',
                    versions: item.productDetail.versions ? [...item.productDetail.versions] : [],
                })
            }
            setUploading(false);
            setUploadJSON(false);
        };
    };

    const handleDownloadClients = async () => {
        const clients = [];
        await firebase.firestore().collection('clients').get().then(async (doc) => {
            for (const item of doc.docs) {
                await firebase.firestore().collection('clients').doc(item.id).collection('products').get().then((product) => {
                    const products = [];
                    product.docs.forEach(pItem => products.push({
                        productId: pItem.id,
                        productDetail: { ...pItem.data() }
                    }))
                    clients.push({
                        clientId: item.id,
                        clientDetails: { ...item.data() },
                        products: products
                    })
                })
            }
            download(JSON.stringify(clients), `clients.json`, 'application/json');
        });
    };

    const handleUploadClients = () => {
        const fileReader = new FileReader();
        fileReader.readAsText(files1, "UTF-8");
        fileReader.onload = e => {
            setUploading(true);
            const clientsData = JSON.parse(e.target.result);
            for (const item of clientsData) {
                firebase.firestore().collection('clients').doc(item.clientId).set({
                    password: item.clientDetails.password,
                    phoneNo: item.clientDetails.phoneNo,
                    name: item.clientDetails.name,
                    approved: item.clientDetails.approved,
                    email: item.clientDetails.email,
                    company: item.clientDetails.company,
                    id: item.clientDetails.id,
                }).then(() => {
                    for (const productItem of item.products) {
                        firebase.firestore().collection('clients').doc(item.clientId).collection('products').doc(productItem.productId).set({
                            name: productItem.productDetail.name ? productItem.productDetail.name : '',
                            clientSKU: productItem.productDetail.clientSKU ? productItem.productDetail.clientSKU : '',
                            description: productItem.productDetail.description ? productItem.productDetail.description : '',
                            gallery: productItem.productDetail.gallery ? [...productItem.productDetail.gallery] : [],
                            galleryFiles: productItem.productDetail.galleryFiles ? [...productItem.productDetail.galleryFiles] : [],
                            group: productItem.productDetail.group ? productItem.productDetail.group : '',
                            sku: productItem.productDetail.sku ? productItem.productDetail.sku : '',
                            thumbnail: productItem.productDetail.thumbnail ? productItem.productDetail.thumbnail : '',
                            versions: productItem.productDetail.versions ? [...productItem.productDetail.versions] : [],
                        })
                    }
                })
            }
            setUploading(false);
            setUploadJSON1(false);
        };
    };

    const handleShowUploadDialog = () => {
        if (clientId === '') {
            alert('Please select Client ID and then try uploading json for that client');
        } else {
            setUploadJSON(true);
        }
    }

    const handleShowUploadDialog1 = () => {
        setUploadJSON1(true);
    }

    const download = (text, name, type) => {
        var file = new Blob([text], { type: type });
        var isIE = /*@cc_on!@*/false || !!document.documentMode;
        if (isIE) {
            window.navigator.msSaveOrOpenBlob(file, name);
        }
        else {
            var a = document.createElement('a');
            a.href = URL.createObjectURL(file);
            a.download = name;
            a.click();
        }
    }

    const getSceneNameIfExists = (versionSceneId, clientId, productSku) => {
        const scenesRef = firebase.database().ref().child('scenes');
        console.log({ versionSceneId, clientId, productSku })
        setVersionName('');
        setVersionIconURL('');
        setProductImageURL('');
        scenesRef.child(versionSceneId).child('clients').child(clientId).child(productSku).get().then(child => {
            console.log(child.toJSON())
            if (child.toJSON()) {
                setSceneName(child.toJSON().name)
                setSceneImage(child.toJSON().image)
            } else {
                setSceneName('');
            }
        })
    };

    const getVersionDetailsIfExist = (version) => {
        const scenesRef = firebase.database().ref().child('scenes');
        console.log({ versionSceneId, clientId, productSKU })
        if (version != '') {
            scenesRef.child(versionSceneId).child('clients').child(clientId).child(productSKU).child(version).get().then(child => {
                console.log(child.toJSON())
                if (child.toJSON()) {
                    setVersionIconURL(child.toJSON().icon);
                    setProductImageURL(child.toJSON().image);
                    setProductImageHighResURL(child.toJSON().highRes);
                    setVersionzIndex(child.toJSON().zIndex);
                } else {
                    setVersionIconURL('');
                    setProductImageURL('');
                    setProductImageHighResURL('');
                    setVersionzIndex('');
                }
            })
        } else {
            setVersionIconURL('');
            setProductImageURL('');
            setProductImageHighResURL('');
            setVersionzIndex('');
        }
    }

    const getSceneIfExists = (name) => {
        setLowResURL('');
        setHighResURL('');
        setIconURL('');
        console.log(sceneId, category, subCategory, name)
        const scenesRef = firebase.database().ref().child('scenes');
        if (name !== '' && sceneId !== '' && category !== '' && subCategory !== null) {
            scenesRef.child(sceneId).child(category.toLowerCase()).child(subCategory.toLowerCase()).child(name.toLowerCase()).get().then(child => {
                console.log(child.toJSON(), name)
                if (child.toJSON()) {
                    setName1(child.toJSON().name);
                    setLowResURL(child.toJSON().image);
                    setHighResURL(child.toJSON().highRes);
                    setIconURL(child.toJSON().icon);
                } else {
                    setName1('');
                    setLowResURL('');
                    setHighResURL('');
                    setIconURL('');
                }
            })
        } else {
            setName1('');
            setLowResURL('');
            setHighResURL('');
            setIconURL('');
        }
    }

    if (JSON.parse(localStorage.getItem('itek_user')) && JSON.parse(localStorage.getItem('itek_user')).userType === 'user') {
        history.replace('./login');
        return null;
    }

    return (
        <>
            <div className="dashboard-page">
                <Box mt={1} ml={15} mr={15}>
                    <Grid container spacing={1}>
                        <Grid item lg={6} md={6} xs={6} align="left">
                            {/* <Typography>
                                iTek
                    </Typography>
                            <Typography className={classes.slogan} >
                                Multi-media control app
                    </Typography> */}
                            <img src={Logo} style={{ width: 154, height: 78, marginTop: -15 }} />
                            <Typography className={classes.slogan} >
                                Multi-media control app
                    </Typography>

                        </Grid>
                        <Grid item lg={4} md={4} xs={4} align="left">
                            {/* <input value={searchText} placeholder="Search" onChange={handleSearchTextChange} className={classes.input} />
                            <SearchIcon className={classes.icon} /> */}
                        </Grid>
                        <Grid item lg={2} md={2} xs={2} align="right">
                            <Typography className={classes.labelNameStyle}>
                                Welcome {user.name}
                            </Typography>
                            <Button
                                variant="text"
                                className={classes.buttonRight}
                                onClick={handleOnClick}
                            >
                                <Typography style={{ marginRight: -30 }} className={classes.buttonLabel}>
                                    Logout
                            </Typography>
                            </Button>
                            <Typography className={classes.buttonLabel}>
                                Admin Panel
                            </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} xs={12}>
                            <Box minWidth={900}>
                                <div className={classes.tableHeader} />
                                <Grid container spacing={1}>
                                    <Grid item lg={4} md={4} sm={6} xs={6}>
                                        <div className={classes.titleStyle} align="center">
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                className={classes.labelStyle}
                                            >
                                                Set Scenes
                                            </Typography>
                                        </div>
                                        <Grid container spacing={1} style={{ marginTop: 10 }}>
                                            <Grid item lg={3} md={3}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.inputLabelStyle}
                                                >
                                                    Scene ID
                                            </Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9}>
                                                <input value={sceneId} onChange={(e) => {
                                                    getCategoriesIfSceneExist(e.target.value);
                                                    setSceneId(e.target.value)
                                                }} className={classes.input} />
                                            </Grid>
                                            <Grid item lg={3} md={3}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.inputLabelStyle}
                                                >
                                                    Category
                                            </Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9}>
                                                <Grid container spacing={1}>
                                                    <Grid item lg={2} md={2} sm={2}>
                                                        <IconButton ref={categoryRef} onClick={() => setCategoryPopover(!categoryPopover)}>
                                                            <EditIcon size={20} style={{ marginTop: -10 }} />
                                                        </IconButton>
                                                        {/* <Popover anchorEl={categoryRef.current} open={categoryPopover} classes={{ paper: classes.popover }}>
                                                            <Box mt={1} p={1}>
                                                                <input value={newCategoryPopover} onChange={(e) => setNewCategoryPopover(e.target.value)} className={classes.input} />
                                                                <Box mt={1}>
                                                                    <Button
                                                                        variant="text"
                                                                        className={classes.roundButton}
                                                                        onClick={handleAddCategory}
                                                                    >
                                                                        <Typography className={classes.roundButtonLabel}>
                                                                            Set
                                                                </Typography>
                                                                    </Button>
                                                                </Box>
                                                            </Box>
                                                        </Popover> */}
                                                    </Grid>
                                                    <Grid item lg={10} md={10} sm={10}>
                                                        {categoryPopover ? 
                                                        <input value={category} placeholder="Category ID" onChange={(e) => {
                                                            setCategory(e.target.value);
                                                            getCategoryName(e.target.value);
                                                        }} className={classes.input} /> : 
                                                        <Select
                                                            value={category}
                                                            onChange={(e) => {
                                                                setShowSCTextField(false);
                                                                setCategory(e.target.value);
                                                                getCategoryName(e.target.value);
                                                                getSubCategories(e.target.value);
                                                            }}
                                                            className={classes.input}
                                                            disabled={sceneId === ''}
                                                        >
                                                            {categories && categories.map(c => <MenuItem value={c}>{c}</MenuItem>)}
                                                        </Select>}
                                                    </Grid>
                                                </Grid>
                                                {/* <input value={category} onChange={(e) => setCategory(e.target.value)} className={classes.input} /> */}
                                            </Grid>
                                            <Grid item lg={3} md={3} style={{ marginBottom: 20 }}/>
                                            <Grid item lg={9} md={9} style={{ marginBottom: 20 }}>
                                            <input value={sceneIdName} placeholder="Category Custom Name" onChange={(e) => setSceneIdName(e.target.value)} className={classes.input} />
                                            </Grid>
                                            {/* <Grid item lg={3} md={3} />
                                            <Grid item lg={9} md={9}>
                                                <Select
                                                    value={subCategoryDropDown}
                                                    label="Select Sub Category"
                                                    onChange={(e) => {
                                                        setSubCategoryDropDown(e.target.value);
                                                        getSubCategoryDetails(e.target.value);
                                                    }}
                                                    className={classes.input}
                                                >
                                                    {subCategories && subCategories.length > 0 && subCategories.map((sc) => <MenuItem value={sc}>{sc}</MenuItem>)}
                                                </Select>
                                            </Grid> */}
                                            <Grid item lg={3} md={3} style={{ marginTop: 20 }}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.inputLabelStyle}
                                                >
                                                    Sub Category
                                            </Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9} style={{ marginTop: 20 }}>
                                                <Grid container spacing={1}>
                                                    <Grid item lg={2}>
                                                        <IconButton ref={categoryRef} onClick={() => {
                                                            setShowSCTextField(!showSCTextField);
                                                            setName('');
                                                            setName1('');
                                                            setLowResURL('');
                                                            setHighResURL('');
                                                            setIconURL('');
                                                            // setSubCategoryIconURL('');
                                                            // setZAxis('');
                                                        }}>
                                                            <EditIcon size={20} style={{ marginTop: -10 }} />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item lg={10}>
                                                        {!showSCTextField ? <Select
                                                            value={subCategory}
                                                            label="Select Sub Category"
                                                            disabled={sceneId === ''}
                                                            onChange={(e) => {
                                                                // setShowVersionNameTextField(!showVersionNameTextField);
                                                                setName('');
                                                                setName1('');
                                                                setLowResURL('');
                                                                setHighResURL('');
                                                                setIconURL('');
                                                                setSubCategory(e.target.value);
                                                                if (e.target.value !== 'Create a new subcategory') {
                                                                    getSubCategoryDetails(e.target.value);
                                                                } else {
                                                                    setSubCategory('');
                                                                    setSubCategoryIconURL('');
                                                                    setZAxis('');
                                                                    setShowSCTextField(true);
                                                                }
                                                            }}
                                                            className={classes.input}
                                                        >
                                                            {subCategories && subCategories.length > 0 && subCategories.map((sc) => <MenuItem value={sc}>{sc}</MenuItem>)}
                                                        </Select> : <input placeholder="Sub Category ID" value={subCategory} onChange={(e) => {
                                                            // if (subCategories.find(s => s.name === e.target.value)) {
                                                            //     const item = subCategories.find(s => s.name === e.target.value);
                                                            //     setSubCategoryIconURL(item.url);
                                                            //     setZAxis(item.zAxis ? item.zAxis : item.zIndex);
                                                            // } else {
                                                            //     setSubCategoryIconURL('');
                                                            //     setZAxis('');
                                                            // }
                                                            setSubCategory(e.target.value);
                                                        }} className={classes.input} />}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item lg={3} md={3} style={{ marginBottom: 20 }} />
                                            <Grid item lg={9} md={9} style={{ marginBottom: 20 }}>
                                                <input value={subCategoryName} onChange={(e) => setSubCategoryName(e.target.value)} placeholder="Sub Category Name" className={classes.input} />
                                            </Grid>
                                            <Grid item lg={3} md={3} />
                                            <Grid item lg={9} md={9}>
                                                <input value={subCategoryIconURL} onChange={(e) => {
                                                    setSubCategoryDropDown(null);
                                                    setSubCategoryIconURL(e.target.value);
                                                }} placeholder="Sub Category Icon URL" className={classes.input} />
                                            </Grid>
                                            <Grid item lg={3} md={3} style={{ marginBottom: 20 }} />
                                            <Grid item lg={9} md={9} style={{ marginBottom: 20 }}>
                                                <input value={zAxis} onChange={(e) => setZAxis(e.target.value)} placeholder="Z-Axis" className={classes.input} />
                                            </Grid>
                                            <Grid item lg={3} md={3} style={{ marginTop: 20 }} />
                                            <Grid item lg={9} md={9} style={{ marginTop: 20 }}>
                                                <Grid container spacing={1}>
                                                    <Grid item lg={2}>
                                                        <IconButton ref={categoryRef} onClick={() => {
                                                            setShowVersionNameTextField(!showVersionNameTextField);
                                                            setName('');
                                                            setName1('');
                                                            setLowResURL('');
                                                            setHighResURL('');
                                                            setIconURL('');
                                                        }}>
                                                            <EditIcon size={20} style={{ marginTop: -10 }} />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item lg={10}>
                                                        {!showVersionNameTextField ? <Select
                                                            value={name}
                                                            label="Select Version"
                                                            disabled={subCategory === ''}
                                                            onChange={(e) => {
                                                                setName(e.target.value);
                                                                if (e.target.value !== 'Create a new version') {
                                                                    getSceneIfExists(e.target.value);
                                                                } else {
                                                                    setName('');
                                                                    setName1('');
                                                                    setLowResURL('');
                                                                    setHighResURL('');
                                                                    setIconURL('');
                                                                    setShowVersionNameTextField(true);
                                                                }
                                                            }}
                                                            className={classes.input}
                                                        >
                                                            {versionsArray && versionsArray.length > 0 && versionsArray.map((sc) => <MenuItem value={sc}>{sc}</MenuItem>)}
                                                        </Select> : <input value={name} onChange={(e) => {
                                                            setName(e.target.value);
                                                            // getSceneIfExists(e.target.value);
                                                        }} placeholder="Name" className={classes.input} />}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item lg={3} md={3} style={{ marginTop: 10 }} />
                                            <Grid item lg={9} md={9}>
                                                <input value={name1} onChange={(e) => {
                                                    setName1(e.target.value);
                                                }} placeholder="Version Name" className={classes.input} />
                                            </Grid>
                                            <Grid item lg={3} md={3} style={{ marginTop: 10 }} />
                                            <Grid item lg={9} md={9}>
                                                <input value={lowResURL} onChange={(e) => setLowResURL(e.target.value)} placeholder="Low Res URL" className={classes.input} style={{ marginTop: 10 }} />
                                            </Grid>
                                            <Grid item lg={3} md={3} style={{ marginTop: 10 }} />
                                            <Grid item lg={9} md={9}>
                                                <input value={highResURL} onChange={(e) => setHighResURL(e.target.value)} placeholder="High Res URL" className={classes.input} style={{ marginTop: 10 }} />
                                            </Grid>
                                            <Grid item lg={3} md={3} style={{ marginTop: 10 }} />
                                            <Grid item lg={9} md={9} style={{ marginTop: 10 }}>
                                                <input value={iconURL} onChange={(e) => setIconURL(e.target.value)} placeholder="Icon URL" className={classes.input} />
                                            </Grid>
                                            <Grid item lg={6} md={6}></Grid>
                                            <Grid item lg={3} md={3} align="right">
                                                <Button
                                                    ref={remRef}
                                                    variant="text"
                                                    className={classes.roundButton}
                                                    onClick={() => setRemoveAlert(true)}
                                                >
                                                    <Typography className={classes.roundButtonLabel}>
                                                        Remove
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item lg={3} md={3} align="right">
                                                <Button
                                                    ref={addRef}
                                                    variant="text"
                                                    className={classes.roundButton}
                                                    onClick={() => setAddAlert(true)}
                                                >
                                                    <Typography className={classes.roundButtonLabel}>
                                                        Add
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={6} xs={6} align="center">
                                        <div className={classes.titleStyle}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                className={classes.labelStyle}
                                            >
                                                Set Versions for Scenes
                                            </Typography>
                                        </div>
                                        <Grid container spacing={1} style={{ marginTop: 10 }}>
                                            <Grid item lg={3} md={3}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.inputLabelStyle}
                                                >
                                                    Scene ID
                                            </Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9}>
                                                <Select
                                                    value={versionSceneId}
                                                    onChange={(e) => setVersionSceneId(e.target.value)}
                                                    className={classes.input}
                                                >
                                                    {/* <MenuItem value="">
                                                        <em></em>
                                                    </MenuItem> */}
                                                    {scenes && Object.values(scenes).map((scene, index) =>
                                                        <MenuItem value={scene}>{scene}</MenuItem>
                                                    )}
                                                </Select>
                                                {/* <input value={versionSceneId} onChange={(e) => setVersionSceneId(e.target.value)} className={classes.input} /> */}
                                            </Grid>
                                            <Grid item lg={3} md={3} >
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.inputLabelStyle}
                                                >
                                                    Client ID
                                            </Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9}>
                                                <Select
                                                    value={clientId}
                                                    onChange={(e) => {
                                                        setClientID(e.target.value);
                                                        getClientProducts(e.target.value);
                                                    }}
                                                    className={classes.input}
                                                >
                                                    {/* <MenuItem value="">
                                                        <em></em>
                                                    </MenuItem> */}
                                                    {clients && clients.map((client, index) =>
                                                        <MenuItem value={client.email}>{client.email}</MenuItem>
                                                    )}
                                                </Select>
                                                {/* <input value={clientId} onChange={(e) => setClientID(e.target.value)} className={classes.input} /> */}
                                            </Grid>
                                            <Grid item lg={3} md={3} >
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.inputLabelStyle}
                                                >
                                                    Product SKU
                                            </Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9} >
                                                <Select
                                                    value={productSKU}
                                                    onChange={(e) => {
                                                        setProductSKU(e.target.value);
                                                        getSceneNameIfExists(versionSceneId, clientId, e.target.value);
                                                    }}
                                                    className={classes.input}
                                                >
                                                    <MenuItem value="">
                                                        <em></em>
                                                    </MenuItem>
                                                    {clientProducts && clientProducts.map((product, index) =>
                                                        <MenuItem value={product}>{product}</MenuItem>
                                                    )}
                                                </Select>
                                                {/* <input value={productSKU} onChange={(e) => setProductSKU(e.target.value)} className={classes.input} /> */}
                                            </Grid>
                                            <Grid item lg={3} md={3}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.inputLabelStyle}
                                                >
                                                    Scene Name
                                            </Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9}>
                                                <input value={sceneName} placeholder="Scene Name" onChange={(e) => setSceneName(e.target.value)} className={classes.input} />
                                            </Grid>
                                            <Grid item lg={3} md={3} style={{ marginBottom: 20 }} >
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.inputLabelStyle}
                                                >
                                                    Scene Image
                                            </Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9} style={{ marginBottom: 20 }}>
                                                <input value={sceneImage} placeholder="Scene Image" onChange={(e) => setSceneImage(e.target.value)} className={classes.input} />
                                            </Grid>
                                            <Grid item lg={3} md={3} />
                                            <Grid item lg={9} md={9}>
                                                <input value={versionName} onChange={(e) => {
                                                    setVersionName(e.target.value)
                                                    getVersionDetailsIfExist(e.target.value);
                                                }} placeholder="Version Name" className={classes.input} />
                                            </Grid>
                                            <Grid item lg={3} md={3} />
                                            <Grid item lg={9} md={9}>
                                                <input value={versionIconURL} onChange={(e) => setVersionIconURL(e.target.value)} placeholder="Icon URL" className={classes.input} />
                                            </Grid>
                                            <Grid item lg={3} md={3} />
                                            <Grid item lg={9} md={9} >
                                                <input value={productImageURL} onChange={(e) => setProductImageURL(e.target.value)} placeholder="Product Image URL Low Res" className={classes.input} />
                                            </Grid>
                                            <Grid item lg={3} md={3} />
                                            <Grid item lg={9} md={9}>
                                                <input value={productImageHighResURL} onChange={(e) => setProductImageHighResURL(e.target.value)} placeholder="Product Image URL High Res" className={classes.input} />
                                            </Grid>
                                            <Grid item lg={3} md={3} />
                                            <Grid item lg={9} md={9}>
                                                <input value={versionzIndex} onChange={(e) => {
                                                    setVersionzIndex(e.target.value)
                                                }} placeholder="Version z Axis" className={classes.input} />
                                            </Grid>
                                            <Grid item lg={3} md={3} style={{ marginBottom: 20 }} >
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.inputLabelStyle}
                                                >
                                                    Is Default
                                            </Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9} align="right" style={{ marginBottom: 20 }}>
                                                <Checkbox
                                                    value={isDefault}
                                                    checked={isDefault}
                                                    onChange={() => setIsDefault(!isDefault)}
                                                />
                                            </Grid>
                                            <Grid item lg={6} md={6}></Grid>
                                            <Grid item lg={3} md={3} align="right">
                                                <Button
                                                    ref={remRef1}
                                                    variant="text"
                                                    className={classes.roundButton}
                                                    onClick={() => setRemoveAlert1(true)}
                                                >
                                                    <Typography className={classes.roundButtonLabel}>
                                                        Remove
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item lg={3} md={3} align="right">
                                                <Button
                                                    ref={addRef1}
                                                    variant="text"
                                                    className={classes.roundButton}
                                                    onClick={() => setAddAlert1(true)}
                                                >
                                                    <Typography className={classes.roundButtonLabel}>
                                                        Add
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Box mt={5} align="center">
                                            <Grid container spacing={2} align="center">
                                                <Grid item lg={2} md={2} sm={2} />
                                                <Grid item lg={4} md={4} sm={4}>
                                                    <img src={DownloadProducts} onClick={handleDownloadProducts} style={{ width: 50, cursor: 'pointer' }} />
                                                    <Typography className={classes.roundButtonLabel1}>
                                                        Download Products
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={4} md={4} sm={4}>
                                                    <img src={UploadProducts} onClick={handleShowUploadDialog} style={{ width: 50, cursor: 'pointer' }} />
                                                    <Typography className={classes.roundButtonLabel1}>
                                                        Upload Products
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={2} md={2} sm={2} />
                                            </Grid>
                                        </Box>
                                        <Box mt={5} align="center">
                                            <Grid container spacing={2}>
                                                <Grid item lg={2} md={2} sm={2} />
                                                <Grid item lg={4} md={4} sm={4}>
                                                    <img src={DownloadDB} onClick={handleDownloadClients} style={{ width: 50, cursor: 'pointer' }} />
                                                    <Typography className={classes.roundButtonLabel1}>
                                                        Download Entire Data
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={4} md={4} sm={4}>
                                                    <img src={UploadDB} onClick={handleShowUploadDialog1} style={{ width: 50, cursor: 'pointer' }} />
                                                    <Typography className={classes.roundButtonLabel1}>
                                                        Upload Entire Data
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={2} md={2} sm={2} />
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={6} xs={6} align="center">
                                        <div className={classes.titleStyle}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                className={classes.labelStyle}
                                            >
                                                Set Products
                                            </Typography>
                                        </div>
                                        <Grid container spacing={1} style={{ marginTop: 10 }}>
                                            <Grid item lg={3} md={3}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.inputLabelStyle}
                                                >
                                                    SKU
                                            </Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9}>
                                                <input value={sku} onChange={(e) => {
                                                    setSKU(e.target.value);
                                                    getProductDetails(e.target.value, customerID);
                                                }} className={classes.input} />
                                            </Grid>
                                            <Grid item lg={3} md={3} style={{ marginTop: 20 }}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.inputLabelStyle}
                                                >
                                                    Customer ID
                                            </Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9} style={{ marginTop: 20 }}>
                                                <Select
                                                    value={customerID}
                                                    onChange={(e) => {
                                                        setClientID(e.target.value);
                                                        setCustomerID(e.target.value);
                                                        getProductDetails(sku, e.target.value);
                                                    }}
                                                    className={classes.input}
                                                >
                                                    {/* <MenuItem value="">
                                                        <em></em>
                                                    </MenuItem> */}
                                                    {clients && clients.map((client, index) =>
                                                        <MenuItem value={client.email}>{client.email}</MenuItem>
                                                    )}
                                                </Select>
                                                {/* <input value={customerID} onChange={(e) => setCustomerID(e.target.value)} className={classes.input} /> */}
                                            </Grid>
                                            <Grid item lg={3} md={3} style={{ marginTop: 10 }}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.inputLabelStyle}
                                                >
                                                    Name
                                            </Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9} style={{ marginTop: 10 }}>
                                                <input value={productName} onChange={(e) => setProductName(e.target.value)} className={classes.input} />
                                            </Grid>
                                            <Grid item lg={3} md={3} style={{ marginTop: 10 }}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.inputLabelStyle}
                                                >
                                                    Group
                                            </Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9}>
                                                <input value={group} onChange={(e) => setGroup(e.target.value)} className={classes.input} style={{ marginTop: 10 }} />
                                            </Grid>
                                            <Grid item lg={3} md={3} style={{ marginTop: 10 }}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.inputLabelStyle}
                                                >
                                                    Description
                                            </Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9}>
                                                <input value={description} onChange={(e) => setDescription(e.target.value)} className={classes.input} style={{ marginTop: 10 }} />
                                            </Grid>
                                            <Grid item lg={3} md={3} style={{ marginTop: 10 }}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.inputLabelStyle}
                                                >
                                                    Client SKU
                                            </Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9} style={{ marginTop: 10 }}>
                                                <input value={clientSKU} onChange={(e) => setClientSKU(e.target.value)} className={classes.input} />
                                            </Grid>
                                            <Grid item lg={3} md={3} style={{ marginTop: 10 }}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.inputLabelStyle}
                                                >
                                                    Thumbnail URL
                                            </Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9} style={{ marginTop: 10 }}>
                                                <input value={thumbnailURL} onChange={(e) => setThumbnailURL(e.target.value)} className={classes.input} />
                                            </Grid>
                                            <Grid item lg={6} md={6}></Grid>
                                            <Grid item lg={3} md={3} align="right">
                                                <Button
                                                    ref={remRef3}
                                                    variant="text"
                                                    className={classes.roundButton}
                                                    onClick={() => setRemoveAlert3(true)}
                                                >
                                                    <Typography className={classes.roundButtonLabel}>
                                                        Remove
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item lg={3} md={3} align="right">
                                                <Button
                                                    ref={addRef3}
                                                    variant="text"
                                                    className={classes.roundButton}
                                                    onClick={() => setAddAlert3(true)}
                                                >
                                                    <Typography className={classes.roundButtonLabel}>
                                                        Save
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item lg={3} md={3} style={{ marginTop: 10 }}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.inputLabelStyle}
                                                >
                                                    Version Name
                                            </Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9} style={{ marginTop: 10 }}>
                                                <input value={productVersionName} onChange={(e) => {
                                                    setProductVersionName(e.target.value);
                                                    getProductVersionDetails(sku, customerID, e.target.value);
                                                }} className={classes.input} />
                                            </Grid>
                                            <Grid item lg={3} md={3} style={{ marginTop: 10 }}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.inputLabelStyle}
                                                >
                                                    Version Icon URL
                                            </Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9} style={{ marginTop: 10 }}>
                                                <input value={productVersionIconURL} onChange={(e) => setProductVersionIconURL(e.target.value)} className={classes.input} />
                                            </Grid>
                                            <Grid item lg={3} md={3} style={{ marginTop: 10 }}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.inputLabelStyle}
                                                >
                                                    Version ID
                                            </Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9}>
                                                <input value={productVersionID} onChange={(e) => setProductVersionID(e.target.value)} className={classes.input} style={{ marginTop: 10 }} />
                                            </Grid>
                                            <Grid item lg={3} md={3} style={{ marginTop: 10 }}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.inputLabelStyle}
                                                >
                                                    XML Path
                                            </Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9}>
                                                <input value={xmlPath} onChange={(e) => setXMLPath(e.target.value)} className={classes.input} style={{ marginTop: 10 }} />
                                            </Grid>
                                            <Grid item lg={3} md={3} style={{ marginTop: 10 }}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.inputLabelStyle}
                                                >
                                                    HTML Path
                                            </Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9} style={{ marginTop: 10 }}>
                                                <input value={htmlPath} onChange={(e) => setHTMLPath(e.target.value)} className={classes.input} />
                                            </Grid>
                                            <Grid item lg={6} md={6}></Grid>
                                            <Grid item lg={3} md={3} align="right">
                                                <Button
                                                    ref={remRef2}
                                                    variant="text"
                                                    className={classes.roundButton}
                                                    onClick={() => setRemoveAlert2(true)}
                                                >
                                                    <Typography className={classes.roundButtonLabel}>
                                                        Remove
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item lg={3} md={3} align="right">
                                                <Button
                                                    ref={addRef2}
                                                    variant="text"
                                                    className={classes.roundButton}
                                                    onClick={() => setAddAlert2(true)}
                                                >
                                                    <Typography className={classes.roundButtonLabel}>
                                                        Add
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <Dialog
                maxWidth="md"
                fullWidth
                onClose={() => setUploadJSON(false)}
                open={uploadJSON}
            >
                <Box m={3}>
                    <Grid container spacing={3} className={classes.modalTitle}>
                        <Grid
                            item
                            xs={10}
                            md={11}
                            lg={11}
                        >
                            <Typography
                                variant="h3"
                                color="textPrimary"
                                className={classes.embededTitle}
                            >
                                Upload JSON
              </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            md={1}
                            lg={1}
                            align="right"
                        >
                            <IconButton
                                onClick={() => setUploadJSON(false)}
                            >
                                <SvgIcon fontSize="small">
                                    <XIcon />
                                </SvgIcon>
                            </IconButton>
                        </Grid>
                        <Grid item lg={12} md={12} xs={12}>
                            <Divider />
                            <CardContent>
                                <FileDropzone fileType=".json" buttonTitle="Upload JSON" file={files} setFile={setFiles} handleUploadImage={handleUploadProducts} />
                            </CardContent>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
            <Dialog
                maxWidth="md"
                fullWidth
                onClose={() => setUploadJSON1(false)}
                open={uploadJSON1}
            >
                <Box m={3}>
                    <Grid container spacing={3} className={classes.modalTitle}>
                        <Grid
                            item
                            xs={10}
                            md={11}
                            lg={11}
                        >
                            <Typography
                                variant="h3"
                                color="textPrimary"
                                className={classes.embededTitle}
                            >
                                Upload JSON
              </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            md={1}
                            lg={1}
                            align="right"
                        >
                            <IconButton
                                onClick={() => setUploadJSON1(false)}
                            >
                                <SvgIcon fontSize="small">
                                    <XIcon />
                                </SvgIcon>
                            </IconButton>
                        </Grid>
                        <Grid item lg={12} md={12} xs={12}>
                            <Divider />
                            <CardContent>
                                <FileDropzone fileType=".json" buttonTitle="Upload JSON" file={files1} setFile={setFiles1} handleUploadImage={handleUploadClients} />
                            </CardContent>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
            {uploading && (
                <Backdrop className={classes.backdrop} open={uploading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            <Popover anchorEl={remRef.current} style={{ opacity: 0.95, backdropFilter: 'blur(1px)' }} open={removeAlert} align="center" classes={{ paper: classes.popoverAlert }}>
                <Grid container spacing={1} align="center">
                    <Grid item lg={12} md={12} sm={12}>
                        <label style={{ marginTop: 20, marginBottom: 20 }}>Do you want to remove this scene?</label>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} />
                    <Grid item lg={3} md={3} sm={3}>
                        <Button
                            variant="text"
                            className={classes.roundButton}
                            onClick={handleRemoveScene}
                        >
                            <Typography className={classes.roundButtonLabel}>
                                Yes
                                    </Typography>
                        </Button>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                        <Button
                            variant="text"
                            className={classes.roundButton}
                            onClick={() => setRemoveAlert(false)}
                        >
                            <Typography className={classes.roundButtonLabel}>
                                No
                                    </Typography>
                        </Button>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} />
                </Grid>
            </Popover>
            <Popover anchorEl={addRef.current} style={{ opacity: 0.95, backdropFilter: 'blur(1px)' }} open={addAlert} align="center" classes={{ paper: classes.popoverAlert }}>
                <Grid container spacing={1} align="center">
                    <Grid item lg={12} md={12} sm={12}>
                        <label style={{ marginTop: 20, marginBottom: 20 }}>Do you want to modify this scene?</label>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} />
                    <Grid item lg={3} md={3} sm={3}>
                        <Button
                            variant="text"
                            className={classes.roundButton}
                            onClick={handleAddScene}
                        >
                            <Typography className={classes.roundButtonLabel}>
                                Yes
                                    </Typography>
                        </Button>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                        <Button
                            variant="text"
                            className={classes.roundButton}
                            onClick={() => setAddAlert(false)}
                        >
                            <Typography className={classes.roundButtonLabel}>
                                No
                                    </Typography>
                        </Button>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} />
                </Grid>
            </Popover>
            <Popover anchorEl={remRef1.current} style={{ opacity: 0.95, backdropFilter: 'blur(1px)' }} open={removeAlert1} align="center" classes={{ paper: classes.popoverAlert }}>
                <Grid container spacing={1} align="center">
                    <Grid item lg={12} md={12} sm={12}>
                        <label style={{ marginTop: 20, marginBottom: 20 }}>Do you want to remove this scene version?</label>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} />
                    <Grid item lg={3} md={3} sm={3}>
                        <Button
                            variant="text"
                            className={classes.roundButton}
                            onClick={handleRemoveSceneVersion}
                        >
                            <Typography className={classes.roundButtonLabel}>
                                Yes
                                    </Typography>
                        </Button>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                        <Button
                            variant="text"
                            className={classes.roundButton}
                            onClick={() => setRemoveAlert1(false)}
                        >
                            <Typography className={classes.roundButtonLabel}>
                                No
                                    </Typography>
                        </Button>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} />
                </Grid>
            </Popover>
            <Popover anchorEl={addRef1.current} style={{ opacity: 0.95, backdropFilter: 'blur(1px)' }} open={addAlert1} align="center" classes={{ paper: classes.popoverAlert }}>
                <Grid container spacing={1} align="center">
                    <Grid item lg={12} md={12} sm={12}>
                        <label style={{ marginTop: 20, marginBottom: 20 }}>Do you want to modify this scene version?</label>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} />
                    <Grid item lg={3} md={3} sm={3}>
                        <Button
                            variant="text"
                            className={classes.roundButton}
                            onClick={handleAddSceneVersion}
                        >
                            <Typography className={classes.roundButtonLabel}>
                                Yes
                                    </Typography>
                        </Button>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                        <Button
                            variant="text"
                            className={classes.roundButton}
                            onClick={() => setAddAlert1(false)}
                        >
                            <Typography className={classes.roundButtonLabel}>
                                No
                                    </Typography>
                        </Button>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} />
                </Grid>
            </Popover>
            <Popover anchorEl={remRef2.current} style={{ opacity: 0.95, backdropFilter: 'blur(1px)' }} open={removeAlert2} align="center" classes={{ paper: classes.popoverAlert }}>
                <Grid container spacing={1} align="center">
                    <Grid item lg={12} md={12} sm={12}>
                        <label style={{ marginTop: 20, marginBottom: 20 }}>Do you want to remove this product version?</label>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} />
                    <Grid item lg={3} md={3} sm={3}>
                        <Button
                            variant="text"
                            className={classes.roundButton}
                            onClick={handleRemoveProductVersion}
                        >
                            <Typography className={classes.roundButtonLabel}>
                                Yes
                                    </Typography>
                        </Button>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                        <Button
                            variant="text"
                            className={classes.roundButton}
                            onClick={() => setRemoveAlert2(false)}
                        >
                            <Typography className={classes.roundButtonLabel}>
                                No
                                    </Typography>
                        </Button>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} />
                </Grid>
            </Popover>
            <Popover anchorEl={addRef2.current} style={{ opacity: 0.95, backdropFilter: 'blur(1px)' }} open={addAlert2} align="center" classes={{ paper: classes.popoverAlert }}>
                <Grid container spacing={1} align="center">
                    <Grid item lg={12} md={12} sm={12}>
                        <label style={{ marginTop: 20, marginBottom: 20 }}>Do you want to modify this product version?</label>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} />
                    <Grid item lg={3} md={3} sm={3}>
                        <Button
                            variant="text"
                            className={classes.roundButton}
                            onClick={handleAddProductVersion}
                        >
                            <Typography className={classes.roundButtonLabel}>
                                Yes
                                    </Typography>
                        </Button>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                        <Button
                            variant="text"
                            className={classes.roundButton}
                            onClick={() => setAddAlert2(false)}
                        >
                            <Typography className={classes.roundButtonLabel}>
                                No
                                    </Typography>
                        </Button>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} />
                </Grid>
            </Popover>
            <Popover anchorEl={remRef3.current} style={{ opacity: 0.95, backdropFilter: 'blur(1px)' }} open={removeAlert3} align="center" classes={{ paper: classes.popoverAlert }}>
                <Grid container spacing={1} align="center">
                    <Grid item lg={12} md={12} sm={12}>
                        <label style={{ marginTop: 20, marginBottom: 20 }}>Do you want to remove this product?</label>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} />
                    <Grid item lg={3} md={3} sm={3}>
                        <Button
                            variant="text"
                            className={classes.roundButton}
                            onClick={handleRemoveProduct}
                        >
                            <Typography className={classes.roundButtonLabel}>
                                Yes
                                    </Typography>
                        </Button>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                        <Button
                            variant="text"
                            className={classes.roundButton}
                            onClick={() => setRemoveAlert3(false)}
                        >
                            <Typography className={classes.roundButtonLabel}>
                                No
                                    </Typography>
                        </Button>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} />
                </Grid>
            </Popover>
            <Popover anchorEl={addRef3.current} style={{ opacity: 0.95, backdropFilter: 'blur(1px)' }} open={addAlert3} align="center" classes={{ paper: classes.popoverAlert }}>
                <Grid container spacing={1} align="center">
                    <Grid item lg={12} md={12} sm={12}>
                        <label style={{ marginTop: 20, marginBottom: 20 }}>Do you want to modify this product?</label>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} />
                    <Grid item lg={3} md={3} sm={3}>
                        <Button
                            variant="text"
                            className={classes.roundButton}
                            onClick={handleSaveProduct}
                        >
                            <Typography className={classes.roundButtonLabel}>
                                Yes
                                    </Typography>
                        </Button>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                        <Button
                            variant="text"
                            className={classes.roundButton}
                            onClick={() => setAddAlert3(false)}
                        >
                            <Typography className={classes.roundButtonLabel}>
                                No
                                    </Typography>
                        </Button>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} />
                </Grid>
            </Popover>
        </>
    )
}
export default withRouter(AdmindashboardPage);
