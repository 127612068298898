import React, {useEffect} from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history';
import customizeScene from './pages/customizeScene';
import scenePage from './pages/scenePage';
import loginPage from './pages/loginPage';
import registerPage from './pages/registerPage';
import forgotPasswordPage from './pages/forgotPasswordPage';
import dashboardPage from './pages/dashboardPage';
import AdmindashboardPage from './pages/AdmindashboardPage';
import detailsPage from './pages/detailsPage';
import './scss/main.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getUser, setUser } from './services/Settings';
import { useStateValue } from './services/state/State';
import { actions } from './services/state/Reducer';

const history = createBrowserHistory();

function App() {
  const [state, dispatch] = useStateValue();
  const user = getUser();
  
  useEffect(() => {
    if (user) {
      setUser(user);
      dispatch({ type: actions.SET_USER, payload: user });
    }
  }, []);

  return (
    <>
      <BrowserRouter>
        <Switch>
        <Route
                exact
                path="/"
                render={() => {
                    return (
                      user ? user.email === 'admin' ? <Redirect to="/admin-dashboard" /> :
                      <Redirect to="/dashboard" /> :
                      <Redirect to="/login" /> 
                    )
                }}
              />
          <Route exact path="/login" component={loginPage} />
          <Route exact path="/register" component={registerPage} />
          <Route exact path="/forgotpassword" component={forgotPasswordPage} />
          <Route exact path="/dashboard" component={dashboardPage} />
          <Route exact path="/admin-dashboard" component={AdmindashboardPage} />
          <Route exact path="/details/:sku" component={detailsPage} />
          <Route exact path="/virtual-scenario/:sku" component={scenePage} />
          {/* <Route exact path="/customize-scene" component={customizeScene} /> */}
        </Switch>
      </BrowserRouter>
      {/* <img src={scenes?.scene01?.floors?.stone["Dark Concrete"]} alt="" width="500" height="500"/> */}
    </>
  );
}
export default App;




// {
//   "rules": {
//     ".read": "now < 1608834600000",  // 2020-12-25
//     ".write": "now < 1608834600000",  // 2020-12-25
//   }
// }




//firebase storage
// rules_version = '2';
// service firebase.storage {
//   match /b/{bucket}/o {
//     match /{allPaths=**} {
//       allow read, write: if request.auth != null;
//     }
//   }
// }
