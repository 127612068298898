import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import {
    Box, Grid, makeStyles, Button, Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import firebase from 'firebase';
import { closePageLoader, startPageLoader } from '../helpers/commonHelper';
import { useStateValue } from '../services/state/State';
import { actions } from '../services/state/Reducer';
import { getUser, removeUser } from '../services/Settings';
import {
    Search as SearchIcon,
    ChevronDown as DownIcon,
    X as XIcon
} from 'react-feather';
import Logo from '../assets/images/logo.png';

const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
        width: '100%',
        height: 30,
        backgroundColor: '#efefef',
        borderWidth: 0,
        borderRadius: 5,
        paddingLeft: 30,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight
    },
    image: {
        width: 60,
        borderRadius: 20,
        border: '1px solid #707070',
        boxShadow: '1px 1px 2px 2px rgba(0, 0, 0, 0.1)',
        marginTop: 15,
        marginBottom: 15
    },
    button: {
        'text-transform': 'none',
    },
    buttonRight: {
        'text-transform': 'none',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    buttonLabel: {
        fontSize: 10,
        color: '#000',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight,
    },
    slogan: {
        textAlign: 'justify',
        fontSize: 12,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight
    },
    labelStyle: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight
    },
    icon: {
        color: 'darkgrey',
        fontSize: 10,
        marginLeft: -30
    },
    downIcon: {
        color: '#fff',
        fontSize: 10,
    },
    tableHeader: {
        backgroundColor: '#FFB300'
    },
    columnName: {
        color: '#fff',
        fontFamily: theme.typography.fontFamily,
    },
    oddItem: {
        backgroundColor: '#f0f0f0'
    },
    cell: {
        cursor: 'pointer',
        color: '#fff'
    },
    seeMoreYellow: {
        color: '#ffb302',
        fontSize: 13,
        fontWeight: theme.typography.fontWeightLight
    },
    seeMoreGrey: {
        color: 'grey',
        fontSize: 13,
        fontWeight: theme.typography.fontWeightLight
    },
    modal: {
        width: '20%',
        height: 500,
        borderRadius: 10,
        backgroundColor: '#f7f7f7',
        borderColor: '#707070',
        // opacity: 0.98,
        position: 'absolute',
        left: '10%',
        top: '15%',
        boxShadow: '1px 1px 3px 3px rgba(211,211,211, 0.1)',
    },
    modalTypography: {
        color: 'grey',
        fontSize: 15,
        fontWeight: theme.typography.fontWeightLight
    },
    close: {
        position: 'absolute',
        top: 20,
        right: 20,
        borderRadius: 15,
        height: 29,
        width: 29,
        backgroundColor: 'black',
    },
    checkboxList: {
        backgroundColor: '#fff',
        border: '1px solid #707070',
        maxWidth: 250,
        height: 300
    }
}));

const DashboardPage = props => {
    const classes = useStyles();
    const history = useHistory();
    const [state, dispatch] = useStateValue();
    const { user, products, lastLocation } = state;
    const [searchText, setSearchText] = useState('');
    const [filterModal, setFilterModal] = useState(false);
    const [selectedType, setSelectedType] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    useEffect(() => {
        // if (lastLocation === 'virtual-scenario') {
        //     dispatch({ type: actions.SET_LAST_LOCATION, payload: ''});
        //     window.location.reload();
        // }
        // dispatch({ type: actions.SET_ROTATOR_API, payload: null });
        startPageLoader();
        const currentUser = getUser();
        if (!currentUser) {
            history.replace('/login');
        } else {
            dispatch({ type: actions.SET_USER, payload: currentUser });
            try {
                firebase.firestore().collection('clients').doc(currentUser.id === 'ITEK-100' ? currentUser.id : currentUser.email).collection('products').onSnapshot(snapshot => {
                    const products = [];
                    snapshot.docs.forEach(doc => products.push(doc.data()));
                    dispatch({ type: actions.SET_PRODUCTS, payload: products });
                    closePageLoader();
                }).catch((err) => {
                    removeUser();
                    history.replace('/login')
                });
            } catch (error) {
                console.log(error)
                
            }
        }
        // uncomment this line to upload url.
        // uploadURLs();
    }, []);

    // useEffect(() => {
    //     if (products) {
    //         uploadURLs();
    //     }
    // }, [products]);

    // To upload product urls in firestore
    // const uploadURLs = () => {
    //     firebase.firestore().collection('clients').doc('ITEK-100').collection('products').doc('A000').get().then((value) => {
    //         const dataArray = [];
    //         let data = [...products[0].versions];
    //         // I need to change only last character from name of image
    //         for (var i = 1; i < 10; i++) {
    //             dataArray.push(`https://app.itektools.com/360v/A000_Sofa2P_Blue02/360_assets/A000_Sofa2P_Blue02/images/Sofa%2001%20360%20Color%2001_01000${i}.jpg`)
    //         }
    //         for (var i = 10; i < 100; i++) {
    //             // need to remove only one zero from name of image before ${i}
    //             dataArray.push(`https://app.itektools.com/360v/A000_Sofa2P_Blue02/360_assets/A000_Sofa2P_Blue02/images/Sofa%2001%20360%20Color%2001_0100${i}.jpg`)
    //         }
    //         dataArray.push(`https://app.itektools.com/360v/A000_Sofa2P_Blue02/360_assets/A000_Sofa2P_Blue02/images/Sofa%2001%20360%20Color%2001_010100.jpg`)
    //         data[0].urls = dataArray; 
    //         firebase.firestore().collection('clients').doc('ITEK-100').collection('products').doc('A000').update({
    //             // change name for every image urls
    //             versions: data
    //         }).then(msg => console.log(msg))
    //             .catch(err => console.log(err))
    //     })
    // };

    const handleOnClick = () => {
        removeUser();
        history.replace('/login');
    };

    const handleDetailClick = (sku) => {
        history.push(`/details/${sku}`);
    };

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value);
        searchFilterFunction(e.target.value);
    };

    const searchFilterFunction = text => {
        let newData = products.filter(item => {
            const nameData = `${item.name.toUpperCase()}`;
            let skuData = '';
            if (item.clientSKU) {
                skuData = `${item.clientSKU.toUpperCase()}`;
            }
            const groupData = `${item.group.toUpperCase()}`;
            const descriptionData = `${item.description.toUpperCase()}`;
            const textData = text.toUpperCase();
            return nameData.indexOf(textData) > -1 
            || skuData.indexOf(textData) > -1 
            || groupData.indexOf(textData) > -1 
            || descriptionData.indexOf(textData) > -1;
        });
        if (text === '') {
            setFilteredData([]);
        } else {
            setFilteredData(newData);
        }
    };

    const handleSortByGroup = (e) => {
        const data = [];
        products.forEach(item => data.push(item.group));
        setFilteredData(data);
        setSelectedType('group');
        setFilterModal(true);
    };

    const handleSortBySKU = (e) => {
        const data = [];
        products.forEach(item => data.push(item.clientSKU));
        setFilteredData(data);
        setSelectedType('sku');
        setFilterModal(true);
    };

    const applyGroupAscendingOrder = () => {
        let data = [...products];
        data.sort((a, b) => (a.group > b.group) ? 1 : ((b.group > a.group) ? -1 : 0));
        dispatch({ type: actions.SET_PRODUCTS, payload: data });
    };

    const applyGroupDescendingOrder = () => {
        let data = [...products];
        data.sort((a, b) => (a.group < b.group) ? 0 : ((b.group < a.group) ? -1 : 1));
        dispatch({ type: actions.SET_PRODUCTS, payload: data });
    };

    const applySKUAscendingOrder = () => {
        let data = [...products];
        data.sort((a, b) => (a.clientSKU > b.clientSKU) ? 1 : ((b.clientSKU > a.clientSKU) ? -1 : 0));
        dispatch({ type: actions.SET_PRODUCTS, payload: data });
    };

    const applySKUDescendingOrder = () => {
        let data = [...products];
        data.sort((a, b) => (a.clientSKU < b.clientSKU) ? 0 : ((b.clientSKU < a.clientSKU) ? -1 : 1));
        dispatch({ type: actions.SET_PRODUCTS, payload: data });
    };

    const handleAscendingOrder = () => {
        if (selectedType === 'group') {
            applyGroupAscendingOrder();
        } else {
            applySKUAscendingOrder();
        }
    };

    const handleDescendingOrder = () => {
        if (selectedType === 'group') {
            applyGroupDescendingOrder();
        } else {
            applySKUDescendingOrder();
        }
    };

    const handleCloseModal = () => {
        setFilterModal(false);
    };

    const findUniqueGroups = () => {
        var flags = [], output = [], l = products.length, i;
        for (i = 0; i < l; i++) {
            if (flags[products[i].group]) continue;
            flags[products[i].group] = true;
            output.push(products[i].group);
        }
        return output;
    }

    const findUniqueSKUs = () => {
        var flags = [], output = [], l = products.length, i;
        for (i = 0; i < l; i++) {
            if (flags[products[i].clientSKU]) continue;
            flags[products[i].clientSKU] = true;
            output.push(products[i].clientSKU);
        }
        return output;
    }

    const handleCheckChange = (e, v, item) => {
        let data = [...filteredData];
        // console.log(item);
        if (v) {
            data.push(item);
        } else {
            data = data.filter(d => d !== item);
        }
        // console.log(data);
        setFilteredData(data);
    };

    if (JSON.parse(localStorage.getItem('itek_user')) && JSON.parse(localStorage.getItem('itek_user')).userType === 'admin') {
        history.replace('./login');
        return null;
    }

    return (
        <>
            <div className="dashboard-page">
                <Box mt={2} ml={15} mr={15}>
                    <Grid container spacing={1}>
                        <Grid item lg={6} md={6} xs={6} align="left">
                            {/* <Typography>
                                iTek
                    </Typography>
                            <Typography className={classes.slogan} >
                                Multi-media control app
                    </Typography> */}
                            <img src={Logo} style={{ width: 154, height: 78, marginTop: -15 }} />
                            <Typography className={classes.slogan} >
                                Multi-media control app
                    </Typography>

                        </Grid>
                        <Grid item lg={4} md={4} xs={4} align="left">
                            <input value={searchText} placeholder="Search" onChange={handleSearchTextChange} className={classes.input} />
                            <SearchIcon className={classes.icon} />
                        </Grid>
                        <Grid item lg={2} md={2} xs={2} align="right">
                            <Typography className={classes.labelStyle}>
                                Welcome {user.name}
                            </Typography>
                            <Button
                                variant="text"
                                className={classes.buttonRight}
                                onClick={handleOnClick}
                            >
                                <Typography style={{marginRight: -30}} className={classes.buttonLabel}>
                                    Logout
                            </Typography>
                            </Button>
                            <Typography className={classes.buttonLabel}>
                                Customer ID: {user.id}
                            </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} xs={12}>
                            <Box minWidth={900}>
                                <Table>
                                    <TableHead>
                                        <TableRow className={classes.tableHeader}>
                                            <TableCell className={classes.cell} onClick={handleSortBySKU}>
                                                SKU
                                            <DownIcon className={classes.downIcon} />
                                            </TableCell>
                                            <TableCell className={classes.cell} onClick={handleSortByGroup}>
                                                Group
                                            <DownIcon className={classes.downIcon} />
                                            </TableCell>
                                            <TableCell className={classes.columnName}>
                                                Name
                                        </TableCell>
                                            <TableCell className={classes.columnName}>
                                                Versions
                                        </TableCell>
                                            <TableCell className={classes.columnName}>
                                                Short Description
                                        </TableCell>
                                            <TableCell className={classes.columnName}>
                                                Main Image
                                        </TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {products && (filteredData.length === 0 && searchText === '') ? products.map((product, index) => {
                                            return (
                                                <TableRow
                                                    key={index}
                                                    className={index % 2 === 1 ? classes.oddItem : null}
                                                >
                                                    <TableCell>
                                                        <Typography
                                                            variant="inherit"
                                                            color="textSecondary"
                                                            className={classes.labelStyle}
                                                        // style={{fontFamily: 'Roboto-light', color: 'grey'}}
                                                        >
                                                            {product.clientSKU ? product.clientSKU : product.sku}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            className={classes.labelStyle}
                                                        // style={{fontFamily: 'Roboto-light', color: 'grey'}}
                                                        >
                                                            {product.group}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            className={classes.labelStyle}
                                                        // style={{fontFamily: 'Roboto-light', color: 'grey'}}
                                                        >
                                                            {product.name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            className={classes.labelStyle}
                                                        // style={{fontFamily: 'Roboto-light', color: 'grey'}}
                                                        >
                                                            {product.versions.length}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            className={classes.labelStyle}
                                                        // style={{fontFamily: 'Roboto-light', color: 'grey'}}
                                                        >
                                                            {product.description.substring(0, 30)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <img
                                                            alt="Product"
                                                            src={product.thumbnail}
                                                            className={classes.image}
                                                        // onError={() => handleErrorOnImageLoad(product)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            variant="text"
                                                            color="primary"
                                                            className={classes.button}
                                                            onClick={() => handleDetailClick(product.sku)}
                                                        >
                                                            <Typography
                                                                // style={{fontFamily: 'Roboto-light', color: 'grey'}}
                                                                className={index % 2 === 1 ? classes.seeMoreGrey : classes.seeMoreYellow}>
                                                                See more details...
                                                    </Typography>
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }) : filteredData.length > 0 && searchText === '' ? products
                                            .filter(item => filteredData.includes(selectedType === 'group' ? item.group : item.clientSKU))
                                            .map((product, index) => {
                                                return (
                                                    <TableRow
                                                        key={index}
                                                        className={index % 2 === 1 ? classes.oddItem : null}
                                                    >
                                                        <TableCell>
                                                            <Typography
                                                                variant="inherit"
                                                                color="textSecondary"
                                                                className={classes.labelStyle}
                                                            // style={{fontFamily: 'Roboto-light', color: 'grey'}}
                                                            >
                                                                {product.clientSKU ? product.clientSKU : product.sku}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                className={classes.labelStyle}
                                                            // style={{fontFamily: 'Roboto-light', color: 'grey'}}
                                                            >
                                                                {product.group}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                className={classes.labelStyle}
                                                            // style={{fontFamily: 'Roboto-light', color: 'grey'}}
                                                            >
                                                                {product.name}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell >
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                className={classes.labelStyle}
                                                            // style={{fontFamily: 'Roboto-light', color: 'grey'}}
                                                            >
                                                                {product.versions.length}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                className={classes.labelStyle}
                                                            // style={{fontFamily: 'Roboto-light', color: 'grey'}}
                                                            >
                                                                {product.description.substring(0, 30)}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <img
                                                                alt="Product"
                                                                src={product.thumbnail}
                                                                className={classes.image}
                                                            // onError={() => handleErrorOnImageLoad(product)}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button
                                                                variant="text"
                                                                color="primary"
                                                                className={classes.button}
                                                                onClick={() => handleDetailClick(product.sku)}
                                                            >
                                                                <Typography
                                                                    // style={{fontFamily: 'Roboto-light', color: 'grey'}}
                                                                    className={index % 2 === 1 ? classes.seeMoreGrey : classes.seeMoreYellow}>
                                                                    See more details...
                                                    </Typography>
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }) : searchText !== '' && filteredData.map((product, index) => {
                                                return (
                                                    <TableRow
                                                        key={index}
                                                        className={index % 2 === 1 ? classes.oddItem : null}
                                                    >
                                                        <TableCell>
                                                            <Typography
                                                                variant="inherit"
                                                                color="textSecondary"
                                                                className={classes.labelStyle}
                                                            // style={{fontFamily: 'Roboto-light', color: 'grey'}}
                                                            >
                                                                {product.clientSKU ? product.clientSKU : product.sku}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                className={classes.labelStyle}
                                                            // style={{fontFamily: 'Roboto-light', color: 'grey'}}
                                                            >
                                                                {product.group}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                className={classes.labelStyle}
                                                            // style={{fontFamily: 'Roboto-light', color: 'grey'}}
                                                            >
                                                                {product.name}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell >
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                className={classes.labelStyle}
                                                            // style={{fontFamily: 'Roboto-light', color: 'grey'}}
                                                            >
                                                                {product.versions.length}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                className={classes.labelStyle}
                                                            // style={{fontFamily: 'Roboto-light', color: 'grey'}}
                                                            >
                                                                {product.description.substring(0, 30)}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <img
                                                                alt="Product"
                                                                src={product.thumbnail}
                                                                className={classes.image}
                                                            // onError={() => handleErrorOnImageLoad(product)}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button
                                                                variant="text"
                                                                color="primary"
                                                                className={classes.button}
                                                                onClick={() => handleDetailClick(product.sku)}
                                                            >
                                                                <Typography
                                                                    // style={{fontFamily: 'Roboto-light', color: 'grey'}}
                                                                    className={index % 2 === 1 ? classes.seeMoreGrey : classes.seeMoreYellow}>
                                                                    See more details...
                                                    </Typography>
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            {
                filterModal && (
                    <Box className={classes.modal} pt={2} pl={2}>
                        <div className={classes.close} onClick={handleCloseModal} align="center">
                            <XIcon color="#fff" size={20} />
                        </div>
                        <Grid container spacing={1} style={{ marginTop: 30 }} >
                            <Grid item lg={12} md={12} sm={12}>
                                <Button
                                    variant="text"
                                    color="primary"
                                    className={classes.button}
                                    onClick={handleAscendingOrder}
                                >
                                    <Typography
                                        className={classes.modalTypography}>
                                        Ascending Order
                                </Typography>
                                </Button>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12}>
                                <Button
                                    variant="text"
                                    color="primary"
                                    className={classes.button}
                                    onClick={handleDescendingOrder}
                                >
                                    <Typography
                                        className={classes.modalTypography}>
                                        Descending Order
                                </Typography>
                                </Button>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} className={classes.checkboxList}>
                                {products && selectedType === 'group' ? findUniqueGroups().map(item =>
                                    <>
                                        <FormControlLabel
                                            control={(
                                                <Checkbox
                                                    defaultChecked
                                                    onChange={(e, v) => handleCheckChange(e, v, item)}
                                                    name="item"
                                                />
                                            )}
                                            label={<Typography className={classes.modalTypography} color="textPrimary">{item}</Typography>}
                                        />
                                        <br />
                                    </>) : findUniqueSKUs().map(item =>
                                        <>
                                            <FormControlLabel

                                                control={(
                                                    <Checkbox
                                                        defaultChecked
                                                        onChange={(e, v) => handleCheckChange(e, v, item)}
                                                        name="item"
                                                    />
                                                )}
                                                label={<Typography className={classes.modalTypography} color="textPrimary">{item}</Typography>}
                                            />
                                            <br />
                                        </>)}
                            </Grid>
                        </Grid>
                    </Box>)
            }
        </>
    )
}
export default withRouter(DashboardPage);
