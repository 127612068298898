import React from 'react';
import PropTypes from 'prop-types';
import { closePageLoader } from '../helpers/commonHelper';

export default class SmoothImg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageLoaded: false,
            isValidSrc: !!props.src
        };
        this.showImage = e => {
            closePageLoader();
            if (e?.target?.height > 0) {
                this.setState({ imageLoaded: true });
            }
            if (this.state.isValidSrc && this.props.imagesArray && this.props.highRes) {
                if (!this.props.imagesArray.find(image => image === this.props.highRes)) {
                    if (this.props.imagesArray.find(i => i.name === this.props.name)) {
                        const data = this.props.imagesArray.filter(i => i.name !== this.props.name);
                        this.props.setImagesArray([...data, {
                            name: this.props.name,
                            src: this.props.highRes,
                            zIndex: this.props.zIndex
                        }]);
                    } else {
                        this.props.setImagesArray([...this.props.imagesArray, {
                            name: this.props.name,
                            src: this.props.highRes,
                            zIndex: this.props.zIndex
                        }]);
                    }
                }
            }
        };
        this.handleError = () => {
            closePageLoader();
            this.setState({ isValidSrc: false });
        }
        this.imageRef = React.createRef();
    }

    componentDidMount() {
        if (this.state.isValidSrc) {
            new Image().src = this.props.src;
        }
        if (!!this.imageRef.current && this.imageRef.current.complete) {
            this.showImage();
        }
    }

    render() {
        const { imageLoaded, isValidSrc } = this.state;
        const { src, alt, objectFit, loaderContainerStyle, loaderStyle } = this.props;

        return (
            <div className="smooth-image-wrapper">
                {isValidSrc ? (
                    <img
                        ref={this.imageRef}
                        className={`smooth-image img-${imageLoaded ? '' : 'hidden'}`}
                        style={this.props.style}
                        src={src}
                        alt={alt}
                        id={this.props.id}
                        onLoad={this.showImage}
                        onError={this.handleError}
                        width={this.props.width}
                        height={this.props.height}
                    />
                ) : (
                        <div className="smooth-no-image">{alt}</div>
                    )}
                {isValidSrc && !imageLoaded && (
                    <div className="smooth-preloader" style={loaderContainerStyle}>
                        <span className="loader" style={loaderStyle} />
                    </div>
                )}
            </div>
        );
    }
}

SmoothImg.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    objectFit: PropTypes.oneOf(['contain', 'fill', 'cover', 'none', 'scale-down'])
};

SmoothImg.defaultProps = {
    alt: 'not found',
    objectFit: 'contain'
};