import React from 'react';
import { startPageLoader } from '../helpers/commonHelper';
import { filterObjectByKeyName } from '../helpers/iterationHelper';
import MySlider from './mySliderVersion';
import MyVerticalSlider from './myVerticalSlider';
const CategorySwitcher = props => {
    const { hoverVersion, wrapperClass, iconWrapperClass, parentObj, title, isSlidable, arrowWidth, arrowHeight, arrowFill, clickOpensPopup, relState, changeScene, zIndex, openPopup, isVerticalSlider } = props;
    const subCategories = Object.values(parentObj).filter(x => typeof x === "object");
    const isOrderPresent = subCategories.filter(x => x.displayOrder).length === subCategories.length;
    let orderedArr = [];
    if (isOrderPresent) {
        subCategories.map(x => orderedArr[x.displayOrder - 1] = x)
    }
    const arrToMap = orderedArr.length ? orderedArr : subCategories;
    //const sortedArr = sortArrayOfObj(subCategories, "name");
    const handleClick = (obj, event) => {
        // console.log(obj, event?.target?.parentElement)
        if (clickOpensPopup) {
            const modalPos = {
                top: event?.target?.getBoundingClientRect().y + 20,
                left: event?.target?.getBoundingClientRect().x
            }
            const relState = event?.target?.parentElement?.getAttribute("data-state");
            const zIndex = obj.zIndex;
            console.log(obj)
            openPopup(obj, modalPos, relState, obj.zIndex);
        } else {
            startPageLoader();
            changeScene(relState, { ...obj, zIndex: obj.zIndex });
        }
    }
    const findStateName = (keyName) => {
        let result = {
            relState : "",
            zIndex: 0
        }
        switch (keyName) {
            case "ceilingDecor": result.relState = "currCeilingDecor"; result.zIndex = 80; break;
            case "centerDecor": result.relState = "currCenterDecor"; result.zIndex = 70; break;
            case "floorDecor": result.relState = "currFloorDecor"; result.zIndex = 60; break;
            case "floors": result.relState = "currFloor"; result.zIndex = 20; break;
            case "leftSideDecor": result.relState = "currLeftDecor"; result.zIndex = 40; break;
            // case "productVersions": result.relState = "currProd"; result.zIndex = 50; break;
            case "rightSideDecor": result.relState = "currRightDecor"; result.zIndex = 40; break;
            case "walls": result.relState = "currWallDecor"; result.zIndex = 10; break;
            case "wallsDecor": result.relState = "currWallDecor"; result.zIndex = 30; break;
        }
        return result; 
    }
    const renderIcon = (obj, key) => {
        let dataRelState = relState;
        let dataIndex = zIndex;
        if(relState === "decor" && obj){
            let decorObj = filterObjectByKeyName(JSON.parse(sessionStorage.getItem("scene")), "decor");
            Object.keys(decorObj).forEach(key => {
                if(decorObj.decorations.name === obj.name){
                    let dataVal = findStateName(key);
                    dataRelState = dataVal.relState;
                    dataIndex = dataVal.zIndex;
                }
            })
        }
        return (
            obj &&
            <div key={key} className={iconWrapperClass} onClick={e => handleClick(obj, e)} data-state={dataRelState} data-zIndex={dataIndex}>
                {obj.icon && <img src={obj.icon} style={{borderRadius: 10}} alt={`${obj.name}-icon`} className="icon" />}
                {obj.name && <h6>{obj.name}</h6>}
            </div>
        )
    }
    return (
        <div className={wrapperClass}>
            <h5 style={{fontWeight: 'bold'}}>Product Versions</h5>
            {
                isSlidable ?
                    <MySlider hoverVersion={hoverVersion} numOfSlides={arrToMap.length} arrowWidth={arrowWidth} arrowHeight={arrowHeight} arrowFill={arrowFill}>
                        {arrToMap.map((category, index) => renderIcon(category, index))}
                    </MySlider> :
                    isVerticalSlider ?
                        <MyVerticalSlider content={arrToMap} renderIcon={renderIcon} relState={parentObj.relState} zIndex={parentObj.zIndex}/> :
                        arrToMap.map((category, index) => renderIcon(category, index))
            }

        </div>
    )
}
export default CategorySwitcher;