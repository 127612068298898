export const PrevArrow = ({ className, style, onClick, arrowWidth, arrowHeight, arrowFill }) => {
    return (
        <div className={className} style={{ ...style }} onClick={onClick} >
            <svg xmlns="http://www.w3.org/2000/svg" width={arrowWidth || "30"} height={arrowHeight || "35"} viewBox="0 0 30 35">
                <g id="Polygon_26" data-name="Polygon 26" transform="translate(0 35) rotate(-90)" fill={arrowFill || "none"}>
                    <path d="M17.5,0,35,30H0Z" stroke="none" />
                    <path d="M 17.5 1.984636306762695 L 1.741039276123047 29 L 33.25896072387695 29 L 17.5 1.984636306762695 M 17.5 0 L 35 30 L 0 30 L 17.5 0 Z" stroke="none" fill="#707070" />
                </g>
            </svg>
        </div>
    )
}
export const NextArrow = ({ className, style, onClick, arrowWidth, arrowHeight, arrowFill }) => {
    return (
        <div className={className} style={{ ...style }} onClick={onClick} >
            <svg xmlns="http://www.w3.org/2000/svg" width={arrowWidth || "30"} height={arrowHeight || "35"} viewBox="0 0 30 35">
                <g id="Polygon_25" data-name="Polygon 25" transform="translate(30) rotate(90)" fill={arrowFill || "none"}>
                    <path d="M17.5,0,35,30H0Z" stroke="none" />
                    <path d="M 17.5 1.984636306762695 L 1.741039276123047 29 L 33.25896072387695 29 L 17.5 1.984636306762695 M 17.5 0 L 35 30 L 0 30 L 17.5 0 Z" stroke="none" fill="#707070" />
                </g>
            </svg>
        </div>
    )
}

export const PrevArrow2 = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.589" height="7.402" viewBox="0 0 16.589 7.402">
        <path id="Path_35" data-name="Path 35" d="M4507.652-40.949l-5.363,7.724,5.363,7.724" transform="translate(-24.93 -4501.072) rotate(90)" fill="none" stroke="#9b9b9b" stroke-width="2" />
    </svg>
)
export const NextArrow2 = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.589" height="7.402" viewBox="0 0 16.589 7.402">
        <path id="Path_34" data-name="Path 34" d="M4507.652-40.949l-5.363,7.724,5.363,7.724" transform="translate(41.519 4508.474) rotate(-90)" fill="none" stroke="#9b9b9b" stroke-width="2" />
    </svg>
)
export const startPageLoader = () => {
    const loaderElem = document.querySelector(".loader-container");
    if (loaderElem && loaderElem.classList.contains("d-none")) {
        loaderElem.classList.remove("d-none");
    }
}

export const closePageLoader = () => {
    const loaderElem = document.querySelector(".loader-container");
    if (loaderElem) {
        loaderElem.classList.add("d-none");
    }
}
export const downloadBase64File = (base64Data, filename) => {
    var element = document.createElement('a');
    element.setAttribute('href', base64Data);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}