import React from "react";
import Slider from "react-slick";
import {
    ChevronLeft as LeftIcon,
    ChevronRight as RightIcon
} from 'react-feather';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from "react-router-dom";

const ColorSlider = props => {
    const { numOfSlides } = props;
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: numOfSlides > 3 ? 3 : numOfSlides,
        slidesToScroll: 1,
        prevArrow: numOfSlides > 2 && <LeftIcon color="#000" />,
        nextArrow: numOfSlides > 2 && <RightIcon color="#000" />,
    };
    return (
        <Slider {...settings}>
            {props.children}
        </Slider>
    )
}
export default withRouter(ColorSlider);



