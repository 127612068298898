import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import SmoothImg from './smoothImg';
import { Modal } from 'react-bootstrap';
import CustomizeScene from '../pages/customizeScene';
import {startPageLoader} from '../helpers/commonHelper';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {},
    label: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight,
        fontSize: 10
    },
}));
const ColorCard = props => {
    const classes = useStyles();
    const { version, history, name, versionObj, loading } = props;

    const handleClick = e => {
        e && e.preventDefault();
        //history.push("customize-scene");
    }

    const toTitleCase = (str) => {
        return str.replace(
          /(\w*\W*|\w*)\s*/g,
          function(txt) {
          return(txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
          }
        ); 
      }
    return (
        <>
            {
                version &&
                <div>
                    <div className="slide-container" >
                        <div onClick={props.handleChangeVersion} style={{width: 60}}>
                            {versionObj && versionObj.icon ? <img
                                                src={versionObj.icon}
                                                style={{ height: 30, width: 30, borderRadius: 0 }}
                                            />
                            : <div style={{backgroundColor: version, height: 30, width: 30, borderRadius: 5, border: '1px solid #000', cursor: loading ? 'not-allowed' : 'pointer' }} />}
                            <label className={classes.label}>{toTitleCase(name)}</label>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
export default withRouter(ColorCard);