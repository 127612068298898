import React, { Component, useEffect, createRef, useCallback } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from "react-router-dom";
import { PrevArrow, NextArrow } from "../helpers/commonHelper";
import {
  ChevronLeft,
  ChevronRight
} from 'react-feather';
const MySlider = props => {
  const { hoverVersion, isPopupOpen, showDots, isInfinite, speed, slidesToShow, slidesToScroll, numOfSlides, arrowWidth, arrowHeight, arrowFill, isVertical } = props;
  const sliderRef = createRef();
  const scroll = useCallback(
    y => {
      if (hoverVersion) {
        if (y > 0) {
          return sliderRef?.current?.slickNext(); /// ? <- using description below 
        } else {
          return sliderRef?.current?.slickPrev();
        }
      }
    },
    [sliderRef]
  );
  useEffect(() => {
    if (!isPopupOpen) {
      window.addEventListener("wheel", e => {
        scroll(e.deltaY);
      });
    }
  }, [scroll]);
  const settings = {
    dots: showDots,
    infinite: true,
    speed: speed || 500,
    slidesToShow: numOfSlides < 3 ? numOfSlides : 3,
    slidesToScroll: slidesToScroll || 1,
    prevArrow: numOfSlides > 3 && <ChevronLeft fontSize={10} color="#000" />,
    nextArrow: numOfSlides > 3 && <ChevronRight fontSize={10} color="#000" />,
  };
  // console.log(numOfSlides)
  return (
    <Slider ref={sliderRef} {...settings}>
      {props.children}
    </Slider>
  )
}
export default withRouter(MySlider);



