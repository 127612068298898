// eslint-disable-next-line import/prefer-default-export
export const initialState = {
  user: {
    id: 1,
    name: 'David'
  },
  products: null,
  rotatorAPI: null,
  lastLocation: '',
};
