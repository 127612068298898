export const defaultFinder = obj => {
    let result;
    for (var p in obj) {
        if (obj.isDefault) {
            return obj;
        } else {
            if (typeof obj[p] === 'object') {
                result = defaultFinder(obj[p]);
                if (result) {
                    return result;
                }
            }
        }
    }
    return result;
}
export const sortArrayOfObj = (arr, key) => {
    return arr.sort((a, b) => {
        if (a.key < b.key) { return -1; }
        if (a.key > b.key) { return 1; }
        return 0;
    })
}
export const filterObjectByKeyName = (obj, str) => {
    let result = {}, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key) && key.toLocaleLowerCase().includes(str)) {
            result[key] = obj[key];
        }
    }
    return result;
}

// export const setStateName = scene => {
//     if(!scene) return;
//     const findStateName = (keyName) => {
//         switch (keyName) {
//             case "ceilingDecor" : return "currCeilingDecor"; break;
//             case "centerDecor" : return "currCenterDecor"; break;
//             case "floorDecor" : return "currFloorDecor"; break;
//             case "floors" : return "currFloor"; break;
//             case "leftSideDecor" : return "currLeftDecor"; break;
//             case "productVersions" : return "currProd"; break;
//             case "rightSideDecor" : return "currRightDecor"; break;
//             case "walls" : return "currWallDecor"; break;
//             case "wallsDecor" : return "currWallDecor"; break;
//             default : return "";
//         }
//     }
//     Object.keys(scene).map(sceneName => {
//         const values = scene[sceneName];
//         const findLastAndSet = val => {
//             let nextVal = Object.values(val).filter(x => typeof x === "object");
//             if (nextVal.length){
//                 findLastAndSet(nextVal);
//             } else {
//                 nextVal.stateName = findStateName(sceneName);
//                 return;
//             }
//         }
//         if(values){
//             findLastAndSet(values);
//         }
//     })
// }

