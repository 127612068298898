import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import SmoothImg from './smoothImg';
import { Modal } from 'react-bootstrap';
import CustomizeScene from '../pages/customizeScene';
import {startPageLoader} from '../helpers/commonHelper';
import cancelDarkIcon from '../assets/cancel-dark.svg';
const SceneCard = props => {
    const { scene, history, sku, user } = props;
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const closePopup = e => {
        // e?.preventDefault();
        setIsPopupOpen(false);
        document.getElementById('root').style.filter = 'blur(0px)'
    }
    const loaderContainerStyle = {
        width: "216px",
        height: "216px",
    };
    const handleClick = e => {
        startPageLoader();
        e && e.preventDefault();
        sessionStorage.setItem("scene", JSON.stringify(scene));
        sessionStorage.setItem("sku", JSON.stringify(sku));
        setIsPopupOpen(true);
        document.getElementById('root').style.filter = 'blur(10px)'
        //history.push("customize-scene");
    }
    const customizationRenderer = () => (
        <Modal show={isPopupOpen} backdrop="static" onHide={closePopup} dialogClassName="customize-popup-class">
            <button className="close-btn" style={{zIndex: 1000}} onClick={closePopup}><img className="close-icon" style={{width: 20}} src={cancelDarkIcon}></img></button>
            <CustomizeScene/>
        </Modal>
    )
    return (
        <>
            {
                scene &&
                <div>
                    <div className="slide-container" >
                        <div onClick={handleClick}>
                            <SmoothImg src={scene.image ? scene.image : scene.clients[user.id][sku].image} alt={`${scene.name} Image`}
                                loaderContainerStyle={loaderContainerStyle} width="216" height="216"
                            />
                        </div>
                        <h6>{scene.name ? scene.name : scene.clients[user.id][sku].name}</h6>
                    </div>
                </div>
            }
            {customizationRenderer()}
        </>
    );
}
export default withRouter(SceneCard);