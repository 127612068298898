import { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import {
    makeStyles,
    Backdrop
} from '@material-ui/core';
import { closePageLoader, startPageLoader, downloadBase64File } from '../helpers/commonHelper';
import mergeImages from 'merge-images';
import { defaultFinder, filterObjectByKeyName, setStateName } from '../helpers/iterationHelper';
import { findStateName } from '../typeCodes/typeCodes';
import CategorySwitcher from '../components/categorySwitcher';
import CategorySwitcherDecor from '../components/categorySwitcherDecor';
import CategorySwitcherProductVersion from '../components/categorySwitcherProductVersion';
import ImageCanvas from '../components/imageCanvas';
import firebase from 'firebase';
import { Modal } from 'react-bootstrap';
import { useStateValue } from '../services/state/State';
import closeIcon from '../assets/cancel.svg';
import cameraIcon from '../assets/camera.svg';
import cancelDarkIcon from '../assets/cancel-dark.svg';
const useStyles = makeStyles((theme) => ({
    root: {},
    icon: {
        width: 40,
        zIndex: 999,
    },
    icon1: {
        width: 40,
    },
    iconhovered: {
        width: 200,
        height: 200,
        borderRadius: 10,
        border: '1px solid #707070',
        boxShadow: '1px 1px 2px 2px rgba(0, 0, 0, 0.1)',
    },
    image: {
        width: 40,
        height: 40,
        borderRadius: 10,
        border: '1px solid #707070',
        boxShadow: '1px 1px 2px 2px rgba(0, 0, 0, 0.1)',
    },
}));
const CustomizeScene = props => {
    const ref1 = useRef();
    const classes = useStyles();
    const [state] = useStateValue();
    const { user } = state;
    const scene = JSON.parse(sessionStorage.getItem("scene"));
    let sku = "";
    if (sessionStorage.getItem("sku")) {
        sku = JSON.parse(sessionStorage.getItem("sku"));
    }
    const { floors, walls, decorations, ceilingDecor, centerDecor, floorDecor, leftSideDecor, rightSideDecor, wallsDecor, productVersions, clients } = scene;
    // const decorArray = Object.keys(decorations)
    //     .map(function (key) {
    //         if (key !== 'name') {
    //             return decorations[key];
    //         }
    //         return null;
    //     }).filter(a => a !== null);
    const [decorArray, setDecorArray] = useState(decorations && Object.keys(decorations)
        .map(function (key) {
            if (key !== 'name') {
                return decorations[key];
            }
            return null;
        }).filter(a => a !== null));
    // console.log(walls, scene.pisos)
    const [hovered, setHovered] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [currFloor, setCurrFloor] = useState({ ...defaultFinder(floors), zIndex: 20 });
    const [currWall, setCurrWall] = useState({ ...defaultFinder(walls ? walls : scene.pisos), zIndex: 10 });
    const [currWallDecor, setCurrWallDecor] = useState({ ...defaultFinder(wallsDecor ? wallsDecor : decorArray && decorArray.find(d => d.name.toLowerCase() === 'walls')), zIndex: 30 });
    const [currLeftDecor, setCurrLeftDecor] = useState({ ...defaultFinder(leftSideDecor ? leftSideDecor : decorArray && decorArray.find(d => d.name.toLowerCase() === 'lamps')), zIndex: 40 });
    const [currRightDecor, setCurrRightDecor] = useState({ ...defaultFinder(rightSideDecor ? rightSideDecor : decorArray && decorArray.find(d => d.name.toLowerCase() === 'plants')), zIndex: 40 });
    const [currProd, setCurrProd] = useState({ ...defaultFinder(productVersions ? productVersions : clients[user.id][sku]), zIndex: defaultFinder(productVersions ? productVersions : clients[user.id][sku]) ? defaultFinder(productVersions ? productVersions : clients[user.id][sku]).zIndex : 50 });
    const [currFloorDecor, setCurrFloorDecor] = useState({ ...defaultFinder(floorDecor ? floorDecor : decorArray && decorArray.find(d => d.name.toLowerCase() === 'rugs')), zIndex: 20 });
    const [currCenterDecor, setCurrCenterDecor] = useState({ ...defaultFinder(centerDecor ? centerDecor : decorArray && decorArray.find(d => d.name.toLowerCase() === 'center table')), zIndex: 70 });
    const [currCeilingDecor, setCurrCeilingDecor] = useState({ ...defaultFinder(ceilingDecor ? ceilingDecor : decorArray && decorArray.find(d => d.name.toLowerCase() === 'ceiling')), zIndex: 80 });
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [modalBody, setModalBody] = useState({});
    const [modalPos, setModalPOs] = useState({});
    const [modalRelState, setModalRelState] = useState("");
    const [modalLayerIndex, setModalLayerIndex] = useState(0);
    const [dbaMail, setDbaMail] = useState("");
    const [imagesArray, setImagesArray] = useState([]);
    const [openedCat, setOpenedCat] = useState(null);
    const [hoverVersion, setHoverVersion] = useState(false);
    const [hoverDecor, setHoverDecor] = useState(false);
    const [hoverDecorPopup, setHoverDecorPopup] = useState(false);
    // console.log('decorArray',decorArray);
    const closePopup = e => {
        // console.log('here')
        // e?.preventDefault();
        setIsPopupOpen(false);
    }
    const openPopup = (content, position, relState, zIndex) => {
        const obj = content && Object.values(content).filter(o => typeof o === "object");
        setModalPOs({ top: position.top - 150, left: position.left });
        setModalRelState(relState);
        setModalLayerIndex(zIndex);
        setModalBody(obj);
        setOpenedCat(obj);
        // console.log('obj', obj);
    };
    useEffect(() => {
        if (modalBody && Object.keys(modalBody).length) {
            setIsPopupOpen(true);
        }
    }, [modalBody])

    useEffect(() => {
        if (!isPopupOpen) {
            setHovered(false);
        }
    }, [isPopupOpen])
    let dateStr = new Date();
    let filePath = `/user customized images/test_user ${dateStr}`;
    const storageRef = firebase.storage().ref(filePath)
    useEffect(() => {
        const dbRefObject = firebase.database().ref().child('appData');
        dbRefObject.on('value', snap => {
            setDbaMail(snap.val()?.notificationEmail);
            closePageLoader();
        });
    }, []);
    const download = e => {
        e.preventDefault();
        startPageLoader();
        // console.log(imagesArray);
        // const imgArr = [
        //     currWall.highRes ? currWall.highRes : currWall.image && currWall.image,
        //     currFloor.highRes ? currFloor.highRes : currFloor.image && currFloor.image,
        //     currWallDecor.highRes ? currWallDecor.highRes : currWallDecor.image && currWallDecor.image,
        //     currLeftDecor.highRes ? currLeftDecor.highRes : currLeftDecor.image && currLeftDecor.image,
        //     currRightDecor.highRes ? currRightDecor.highRes : currRightDecor.image && currRightDecor.image,
        //     currProd.highRes ? currProd.highRes : currProd.image && currProd.image,
        //     currFloorDecor.highRes ? currFloorDecor.highRes : currFloorDecor.image && currFloorDecor.image,
        //     currCenterDecor.highRes ? currCenterDecor.highRes : currCenterDecor.image && currCenterDecor.image,
        //     currCeilingDecor.highRes ? currCeilingDecor.highRes : currCeilingDecor.image && currCeilingDecor.image
        // ].filter(x => x !== "" && x !== undefined && x.includes('https:'));
        const data = imagesArray.sort(function(a, b){return a.zIndex - b.zIndex});
        // console.log(data);
        const imgArr = [
            currWall.highRes ? currWall.highRes : currWall.image && currWall.image,
            currFloor.highRes ? currFloor.highRes : currFloor.image && currFloor.image,
            ...data.map(i => i.src),
            currProd.highRes ? currProd.highRes : currProd.image && currProd.image,
        ].filter(x => x !== "" && x !== undefined && x.includes('https:'));
        // console.log(imgArr);
        // mergeImages(["Light Concrete.png", "Dark Red.png", "Pendant Lamp 2.png"], { crossOrigin: "Anonymous" }).then(res => {
        mergeImages(imgArr, { crossOrigin: "Anonymous" }).then(res => {
            storageRef.putString(res, 'data_url').then(function (snapshot) {
                // console.log('Uploaded a data_url string!');
                const imgName = `test_user ${dateStr}`;
                const userName = "Test User";
                const reqString = `https://enigmatic-forest-77998.herokuapp.com/api/contact?imgName=${imgName}&userName=${userName}&time=${new Date().toLocaleString()}&dbaMail=${dbaMail}`
                fetch(reqString, {
                    method: 'POST',
                    mode: 'no-cors'
                }).then(res => {
                    const loaderElem = document.querySelector(".download-loader");
                    if (loaderElem && !loaderElem.classList?.contains("d-none")) {
                        loaderElem.classList.add("d-none");
                    }
                    closePageLoader();
                    // alert("Mobutek Team is now notified about these brilliant customizations. Thank you!!");
                })
            });
            document.querySelector('.com-img').src = res;
            downloadBase64File(res, `test_user ${dateStr}`)
            closePageLoader();
            document.querySelector('.com-img').src = "";
        })
    }

    const shuffle = e => {
        e.preventDefault();
        // startPageLoader();
        const wallsCat = Object.keys(walls).filter(w => w !== "name");
        var random = Math.floor(Math.random() * wallsCat.length);
        const wallsSubCat = walls[wallsCat[random]];
        const wallsSubCatConverted = Object.keys(wallsSubCat).filter(w => w !== "name").filter(w => w !== "icon").filter(w => w !== "zIndex");
        random = Math.floor(Math.random() * wallsSubCatConverted.length);
        // console.log(wallsSubCat[wallsSubCatConverted[random]])
        if (wallsSubCat[wallsSubCatConverted[random]]) {
            setCurrWall(wallsSubCat[wallsSubCatConverted[random]]);
        }

        const floorsCat = Object.keys(floors).filter(w => w !== "name");
        random = Math.floor(Math.random() * floorsCat.length);
        const floorsSubCat = floors[floorsCat[random]];
        const floorsSubCatConverted = Object.keys(floorsSubCat).filter(w => w !== "name").filter(w => w !== "icon").filter(w => w !== "zIndex");
        random = Math.floor(Math.random() * floorsSubCatConverted.length);
        // console.log(floorsSubCat[floorsSubCatConverted[random]])
        if (floorsSubCat[floorsSubCatConverted[random]]) {
            setCurrFloor(floorsSubCat[floorsSubCatConverted[random]]);
        }
        const decors = [...decorArray]
        decors.forEach(decor => {
            const data = Object.values(decor).filter(o => typeof o === "object");
            random = Math.floor(Math.random() * data.length);
            // data.forEach(d => d.isDefault = false);
            for (let i = 0; i < data.length; i ++) {
                if (i === random) {
                    // console.log(i)
                    data[i].isDefault = true;
                } else {
                    data[i].isDefault = false;
                }
            }
            // const item = data[random];
            // item.isDefault = true;
            // data[random] = item;
        });
        // setDecorArray(decors);
        // closePageLoader();
    }
    const changeScene = (stateName, newVal) => {
        // console.log(stateName, newVal)
        // const showAlert = () => {
        //     closePageLoader();
        //     alert("already selected!!!");
        // }
        // console.log(stateName, newVal)
        switch (stateName) {
            case "currFloor":
                // if (currFloor.image === newVal.image) showAlert(); else setCurrFloor(newVal); break;
                setCurrFloor(newVal); break;
            case "currWall":
                // if (currWall.image === newVal.image) showAlert(); else setCurrWall(newVal); break;
                setCurrWall(newVal); break;
            case "currWallDecor":
                // if (currWallDecor.image === newVal.image) showAlert(); else setCurrWallDecor(newVal); break;
                setCurrWallDecor(newVal); break;
            case "decor":
                // if (currLeftDecor.image === newVal.image) showAlert(); else setCurrLeftDecor(newVal); break;
                setCurrLeftDecor(newVal); break;
            // case "currLeftDecor":
            //     if (currLeftDecor.image === newVal.image) showAlert(); else setCurrLeftDecor(newVal); break;
            case "currRightDecor":
                // if (currRightDecor.image === newVal.image) showAlert(); else setCurrRightDecor(newVal); break;
                setCurrRightDecor(newVal); break;
            case "currProd":
                // if (currProd.image === newVal.image) showAlert(); else setCurrProd(newVal); break;
                setCurrProd(newVal); break;
            case "currFloorDecor":
                // if (currFloorDecor.image === newVal.image) showAlert(); else setCurrFloorDecor(newVal); break;
                setCurrFloorDecor(newVal); break;
            case "currCenterDecor":
                // if (currCenterDecor.image === newVal.image) showAlert(); else setCurrCenterDecor(newVal); break;
                setCurrCenterDecor(newVal); break;
            case "currCeilingDecor":
                // if (currCeilingDecor.image === newVal.image) showAlert(); else setCurrCeilingDecor(newVal); break;
                setCurrCeilingDecor(newVal); break;
            default:
                closePageLoader();
        }
        closePageLoader();
    }

    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {
        if (ref1 && ref1.current) {
            if (ref1.current.contains(e.target)) {
                // inside click
                return;
            }
            // outside click 
            setIsPopupOpen(false);
        }
    };

    return (
        <div className="customize-scene-page">
            <div className="left-section">
                {(walls || scene.pisos) && <CategorySwitcher parentObj={walls ? walls : scene.pisos} wrapperClass="vertical-switcher" iconWrapperClass="icon-wrapper" clickOpensPopup openPopup={openPopup} changeScene={changeScene} zIndex={10} isVerticalSlider relState="currWall" />}
                {
                    <ImageCanvas imagesArray={imagesArray} setImagesArray={setImagesArray} decorations={decorArray} currFloor={currFloor} currWall={currWall} currWallDecor={currWallDecor} currLeftDecor={currLeftDecor} currRightDecor={currRightDecor} currProd={currProd} currFloorDecor={currFloorDecor} currCenterDecor={currCenterDecor} currCeilingDecor={currCeilingDecor} />
                }
                {floors && <CategorySwitcher parentObj={floors} wrapperClass="vertical-switcher" iconWrapperClass="icon-wrapper" clickOpensPopup openPopup={openPopup} changeScene={changeScene} zIndex={100} isVerticalSlider relState="currFloor" />}
            </div>
            <div className="right-section">
                <div className="heading">
                    <img src={cameraIcon} alt="camera-icon" className="icon" />
                    <h2>Virtual Scenario</h2>
                </div>
                {/* {productVersions && <CategorySwitcher parentObj={productVersions} wrapperClass="product-switcher" iconWrapperClass="icon-wrapper" isSlidable arrowWidth="15" arrowHeight="17.5" arrowFill="#474747" relState="currProd" changeScene={changeScene} zIndex={50} />} */}
                {clients && <div style={{marginTop: -30}} onMouseEnter={() => setHoverVersion(true)} onMouseOver={() => setHoverVersion(true)} onMouseLeave={() => setHoverVersion(false)}>
                    <CategorySwitcherProductVersion hoverVersion={hoverVersion} isPopupOpen={isPopupOpen} parentObj={clients[user.id][sku]} wrapperClass="product-switcher" iconWrapperClass="icon-wrapper" isSlidable arrowWidth="15" arrowHeight="17.5" arrowFill="#474747" relState="currProd" changeScene={changeScene} zIndex={50} /></div>}
                {/* <h4 style={{ marginTop: 30 }}>Please customize the scene</h4> */}
                {/* {!decorations &&
                    Object.keys(filterObjectByKeyName(scene, "decor")).length &&
                    <CategorySwitcher parentObj={filterObjectByKeyName(scene, "decor")} title="Decorations" wrapperClass="decoration-switcher"
                        relState="decor" iconWrapperClass="icon-wrapper" clickOpensPopup openPopup={openPopup} />
                } */}
                {/* {decorations && Object.keys(decorations).length < 7 ? <CategorySwitcher parentObj={decorations} title="Decorations" wrapperClass="decoration-switcher"
                        relState="decor" iconWrapperClass="icon-wrapper" clickOpensPopup openPopup={openPopup} zIndex={100} />
                    : <CategorySwitcherProductVersion parentObj={decorations} wrapperClass="decoration-switcher" iconWrapperClass="icon-wrapper" isSlidable changeScene={changeScene} zIndex={50} />} */}
                {decorations && <div style={{marginTop: -80}} onMouseEnter={() => setHoverDecor(true)} onMouseOver={() => setHoverDecor(true)} onMouseLeave={() => setHoverDecor(false)}>
                    <CategorySwitcher hoverDecor={hoverDecor} isPopupOpen={isPopupOpen} parentObj={decorations} isSlidable title="Decorations" wrapperClass="product-switcher" arrowWidth="15" arrowHeight="17.5" arrowFill="#474747"
                    relState="decor" iconWrapperClass="icon-wrapper" clickOpensPopup openPopup={openPopup} zIndex={100} /></div>}
                {hovered && (
                    <div style={{ top: modalPos.top - 25, left: modalPos.left - 360, position: 'absolute', zIndex: 999, backgroundColor: '#fff', borderRadius: 10 }}>
                        {selectedItem.icon && <img src={selectedItem.icon} alt={`${selectedItem.name}-icon`} className={classes.iconhovered} />}
                    </div>
                )}
                <Modal show={isPopupOpen} backdrop={true} keyboard={false} onHide={closePopup} dialogClassName="slider-popup-class" style={modalPos}>
                    <div ref={ref1} onMouseEnter={() => setHoverDecorPopup(true)} onMouseOver={() => setHoverDecorPopup(true)} onMouseLeave={() => setHoverDecorPopup(false)}>
                        <button className="close-btn" onClick={closePopup}><img className="close-icon" style={{ width: 20 }} src={cancelDarkIcon}></img></button>
                        {modalBody && <CategorySwitcherDecor hoverDecorPopup={hoverDecorPopup} openedCat={openedCat} decorArray={decorArray} setDecorArray={setDecorArray} isDecor={true} selectedItem={selectedItem} setSelectedItem={setSelectedItem} hovered={hovered} setHovered={setHovered} forPopup parentObj={modalBody} wrapperClass="popup-wrapper" iconWrapperClass="icon-wrapper" arrowWidth="12" arrowHeight="14.5" arrowFill="#474747" changeScene={changeScene} isVerticalSlider zIndex={modalLayerIndex} relState={modalRelState} />}
                    </div>
                </Modal>
                <div className="img-shuffle">
                    <button onClick={shuffle}>Shuffle</button>
                </div>
                <div className="img-downloader">
                    <button onClick={download}>Download Image</button>
                </div>
            </div>
            <div className="download-loader d-none">
                <div className="download-loader-container row">
                    <div className="head col-lg-4">
                        <h5>Please be patient. Don't hit refresh and don't close the window.</h5>
                        <h5>We are imaging your awesome customizations.</h5>
                        <h5>This might take a minute or two..</h5>
                    </div>
                    <img className="col-lg-8" src="icons/loadingGIF3.gif" />
                </div>
            </div>
        </div>
    )
}
export default withRouter(CustomizeScene);