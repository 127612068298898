import React, { Component, useEffect } from "react";
import Slider from "react-slick";
import {
  ChevronLeft,
  ChevronRight
} from 'react-feather';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from "react-router-dom";
const MyGallerySlider = props => {
  const { showDots, isInfinite, speed, slidesToShow, slidesToScroll, numOfSlides, arrowWidth, arrowHeight, arrowFill, isVertical } = props;
  const settings = {
    dots: showDots,
    infinite: isInfinite || true,
    speed: speed || 500,
    slidesToShow: slidesToShow || 3,
    slidesToScroll: slidesToScroll || 1,
    prevArrow: <ChevronLeft fontSize={10} color="#000" />,
    nextArrow: <ChevronRight fontSize={10} color="#000" />,
  };
  return (
    <Slider {...settings}>
      {props.children}
    </Slider>
  )
}
export default withRouter(MyGallerySlider);



