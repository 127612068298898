import React, { Component, useEffect, createRef, useCallback } from "react";
import Slider from "react-slick";
import {
  makeStyles,
} from '@material-ui/core';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from "react-router-dom";
import { PrevArrow, NextArrow } from "../helpers/commonHelper";
import {
  ChevronUp,
  ChevronDown
} from 'react-feather';
const useStyles = makeStyles((theme) => ({
  root: {},
  slider: {
      width: 200,
      // height: 220
  },
}));
const MySliderVertical = props => {
  const classes = useStyles();
  const { hoverDecorPopup, isPopupOpen, showDots, isInfinite, speed, slidesToShow, slidesToScroll, numOfSlides, arrowWidth, arrowHeight, arrowFill, isVertical, showArrows } = props;
  const sliderRef = createRef();
  const scroll = useCallback(
    y => {
      if(hoverDecorPopup) {
        if (y > 0) {
          return sliderRef?.current?.slickNext(); /// ? <- using description below 
        } else {
          return sliderRef?.current?.slickPrev();
        }
      }
    },
    [sliderRef]
  );
  useEffect(() => {
    if (!isPopupOpen) {
      window.addEventListener("wheel", e => {
        scroll(e.deltaY);
      });
    }
  }, [scroll]);
  const settings = {
    dots: showDots,
    infinite: true,
    speed: speed || 500,
    slidesToShow: numOfSlides < 3 ? numOfSlides : 3,
    slidesToScroll: slidesToScroll || 1,
  };
  return (
    <>
    {numOfSlides >= 3 && <ChevronUp onClick={() => sliderRef?.current?.slickPrev()} fontSize={10} color="#000" style={{alignSelf: 'center'}} />}
    <Slider className={classes.slider} arrows={false} vertical={true} verticalSwiping={true} ref={sliderRef} {...settings}>
      {props.children}
    </Slider>
    {numOfSlides >= 3 && <ChevronDown onClick={() => sliderRef?.current?.slickNext()} fontSize={10} color="#000" style={{alignSelf: 'center'}} />}
    </>
  )
}
export default withRouter(MySliderVertical);



