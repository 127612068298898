import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Box, Grid, makeStyles, Button, Link, Typography, SvgIcon } from '@material-ui/core';
import MySlider from '../components/mySlider';
import SceneCard from '../components/sceneCard';
import firebase from 'firebase';
import { firebaseConfig } from '../config/firebase.utils';
import { closePageLoader, startPageLoader } from '../helpers/commonHelper';
import LoginBackground from '../assets/images/login-bg.jpg';
import Logo from '../assets/images/favicon.svg';
import { Modal } from 'react-bootstrap';
import {
    Lock as LockIcon
} from 'react-feather';
import { useStateValue } from '../services/state/State';
import { setUser } from '../services/Settings';
import { actions } from '../services/state/Reducer';

const useStyles = makeStyles((theme) => ({
    root: {},
    bulkOperations: {
        position: 'relative',
    },
    card: {
        position: 'absolute',
        left: '10%',
        top: '10%',
        borderRadius: 10,
        backgroundColor: '#f5f5f5',
        width: 500,
        // height: 500,
        opacity: 0.8
    },
    grid: {
        width: '100%',
        height: '100%',
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5)
    },
    inputLabel: {
        marginTop: theme.spacing(3),
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight
    },
    input: {
        width: '100%',
        height: 35,
    },
    image: {
        width: '100%'
    },
    logo: {
        width: 30
    },
    label: {
        position: 'absolute',
        top: '25%',
    },
    title: {
        fontSize: 80,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight
    },
    subtitle: {
        fontSize: 150,
        color: '#fff',
        fontWeight: 'bold',
        marginTop: -20,
        fontFamily: theme.typography.fontFamily,
    },
    button: {
        // width: 100,
        backgroundColor: '#F7BD37',
        borderRadius: 15,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        boxShadow: '1px 1px 3px 3px rgba(0, 0, 0, 0.1)',
        'text-transform': 'none',
        '&:hover': {
            backgroundColor: '#F7BD37'
        },
    },
    item: {
        marginTop: theme.spacing(6),
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight,
        cursor: 'pointer'
    },
    itemWithoutCursor: {
        marginTop: theme.spacing(6),
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight,
    },
    buttonLabel: {
        color: '#fff',
        fontSize: 18,
        fontFamily: theme.typography.fontFamily,
    },
    bottomLabel: {
        fontSize: 10,
        fontWeight: '700',
        fontFamily: theme.typography.fontFamily,
    },
    bottomLabelWithCursor: {
        fontSize: 10,
        fontWeight: '700',
        fontFamily: theme.typography.fontFamily,
        cursor: 'pointer'
    },
    icon: {
        color: '#fff',
        fontSize: 10,
    },
    svg: {
        marginLeft: theme.spacing(2),
        height: 15,
        width: 15
    },
    appTitle: {
        fontFamily: theme.typography.fontFamily,
    }
}));

const ForgotPassword = props => {
    const classes = useStyles();
    const history = useHistory();
    const [state, dispatch] = useStateValue();
    const [username, setUsername] = useState('');

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handleOnClick = (e) => {
        if (username === '') {
            alert('Please enter username.');
        } else {
            startPageLoader();
            firebase.auth().sendPasswordResetEmail(username)
            .then(user => {
                alert('Password change link is sent to your email.');
                closePageLoader();
            })
            .catch(err => {
                // console.log(err);
                closePageLoader();
            })
        }
    };

    useEffect(() => {
        closePageLoader();
    }, []);
    return (
        <>
            <div className="login-page">
                <div className="left">
                </div>
                <div className="right">
                    <img src={LoginBackground} className={classes.image} />
                </div>
            </div>
            <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={6}>
                    <Box className={classes.card}>
                        <Grid container spacing={1} className={classes.grid} justify="center" alignItems="center">
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <img src={Logo} className={classes.logo} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} align="right">
                                <label className={classes.appTitle}>iTek App</label>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <label className={classes.inputLabel}>Username</label><br />
                                <input value={username} onChange={handleUsernameChange}
                                onKeyPress={(e) => (e.key === "Enter") ? handleOnClick() : null}
                                 className={classes.input} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} className={classes.item}>
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    color="primary"
                                    onClick={handleOnClick}
                                >
                                    <Typography className={classes.buttonLabel}>
                                        Submit
                                    </Typography>
                                    <SvgIcon className={classes.svg}>
                                        <LockIcon className={classes.icon} />
                                    </SvgIcon>
                                </Button>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} align="right" className={classes.item}>
                                <Link style={{ fontSize: 12 }} onClick={() => history.push('/login')}>Back to Login</Link>
                            </Grid>
                            {/* <Grid item lg={6} md={6} sm={6} xs={6} className={classes.itemWithoutCursor}>
                                <label className={classes.bottomLabelWithCursor} onClick={() => history.push('/register')}>Want to test our services?</label>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} align="right" className={classes.itemWithoutCursor}>
                                <label className={classes.bottomLabel}>Get assistance</label>
                            </Grid> */}
                        </Grid>
                    </Box>
                </Grid>
                <Grid lg={6} md={6}>
                    <div className={classes.label}>
                        <label className={classes.title}>Make it</label><br />
                        <label className={classes.subtitle}>Real</label>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}
export default withRouter(ForgotPassword);
