import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { reducer } from './services/state/Reducer';
import { StateProvider } from './services/state/State';
import { initialState } from './services/state/InitialState';
import reportWebVitals from './reportWebVitals';
import { createMuiTheme, ThemeProvider, StylesProvider, jssPreset } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    "fontFamily": 'Roboto',
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
   }
});


ReactDOM.render(
  <StateProvider initialState={initialState} reducer={reducer}>
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
  </StateProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
