import React, { useEffect } from 'react';
import { closePageLoader } from '../helpers/commonHelper';
import SmoothImg from './smoothImg';
const ImageCanvas = props => {
    const { currFloor, currWall, currWallDecor, currLeftDecor, currRightDecor, currProd, currFloorDecor, currCenterDecor, currCeilingDecor, decorations } = props;
    const loaderContainerStyle = {
        width: "480px",
        height: "480px",
    };
    // console.log(
    //     currFloor.name,
    //     currWall.name,
    //     currWallDecor.name,
    //     currLeftDecor.name,
    //     currLeftDecor.image,
    //     currRightDecor.name,
    //     currRightDecor.image,
    //     currFloorDecor.name,
    //     currCenterDecor.name,
    //     currCeilingDecor.name
    // )
    // console.log(decorations);
    return (
        <div className="canvas-container">
            {/* {console.log(currProd)} */}
            <div className="img-canvas">
                {currFloor.name && <SmoothImg loaderContainerStyle={loaderContainerStyle} width="480" height="480" id={currFloor.name.toLowerCase()} src={currFloor.image} alt={currFloor.name} style={{ zIndex: currFloor.zIndex }} />}
                {currWall.name && <SmoothImg loaderContainerStyle={loaderContainerStyle} width="480" height="480" id={currWall.name.toLowerCase()} src={currWall.image} alt={currWall.name} style={{ zIndex: currWall.zIndex }} />}
                {currProd.name && <SmoothImg loaderContainerStyle={loaderContainerStyle} width="480" height="480" id={currProd.name.toLowerCase()} src={currProd.image} alt={currProd.name} style={{zIndex:currProd.zIndex ? currProd.zIndex : 10}}/>}
                {/* {currCenterDecor.name && <SmoothImg loaderContainerStyle={loaderContainerStyle} width="480" height="480" id={currCenterDecor.name.toLowerCase()} src={currCenterDecor.image} alt={currCenterDecor.name} style={{zIndex:currCenterDecor.zIndex ? currCenterDecor.zIndex : 10}}/>} */}
                {/* {currWallDecor.name && <SmoothImg loaderContainerStyle={loaderContainerStyle} width="480" height="480" id={currWallDecor.name.toLowerCase()} src={currWallDecor.image} alt={currWallDecor.name} style={{zIndex:currWallDecor.zIndex ? currWallDecor.zIndex : 10}}/>}
            {currLeftDecor.name && <SmoothImg loaderContainerStyle={loaderContainerStyle} width="480" height="480" id={currLeftDecor.name.toLowerCase()} src={currLeftDecor.image} alt={currLeftDecor.name} style={{zIndex:currLeftDecor.zIndex ? currLeftDecor.zIndex : 20}}/>}
            {currRightDecor.name && <SmoothImg loaderContainerStyle={loaderContainerStyle} width="480" height="480" id={currRightDecor.name.toLowerCase()} src={currRightDecor.image} alt={currRightDecor.name} style={{zIndex:currRightDecor.zIndex ? currRightDecor.zIndex : 10}}/>}
            {currFloorDecor.name && <SmoothImg loaderContainerStyle={loaderContainerStyle} width="480" height="480" id={currFloorDecor.name.toLowerCase()} src={currFloorDecor.image} alt={currFloorDecor.name} style={{zIndex:currFloorDecor.zIndex ? currFloorDecor.zIndex : 10}}/>}
            
            {currCeilingDecor.name && <SmoothImg loaderContainerStyle={loaderContainerStyle} width="480" height="480" id={currCeilingDecor.name.toLowerCase()} src={currCeilingDecor.image} alt={currCeilingDecor.name} style={{zIndex:currCeilingDecor.zIndex ? currCeilingDecor.zIndex : 10}}/>} */}
                {decorations && decorations.map(decor => decor && (
                    <>
                        {Object.keys(decor).filter(c => decor[c].isDefault === true).map(decor1 =>
                            <>
                                {/* {console.log(decor[decor1])} */}
                                <SmoothImg name={decor.name} imagesArray={props.imagesArray} setImagesArray={props.setImagesArray} loaderContainerStyle={loaderContainerStyle} width="480" height="480" highRes={decor[decor1].highRes} src={decor[decor1].image} alt={decor[decor1].name} zIndex={parseInt(decor.zIndex, 10)} style={{ zIndex: parseInt(decor.zIndex, 10) }} />
                            </>
                        )}
                    </>
                ))}
                <img className="com-img" />
            </div>
        </div>
    );
};
export default ImageCanvas;