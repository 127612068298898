import React, { useState } from 'react';
import { PrevArrow2, NextArrow2 } from '../helpers/commonHelper';
import {
    ChevronUp,
    ChevronDown
  } from 'react-feather';
const MyVerticalSlider = props => {
    const { content, renderIcon, relState } = props;
    const [activeSlides, setActiveSlides] = useState([0,1,2,3]);
    const showArrows = content.length > 4;
    const handleClick = (direction) => {
        let temp = [...activeSlides], temp3;
        if(direction === 'next') {
            temp3 = [temp[0], temp[1], temp[2]];
            if(temp[0]===0){
                temp3.unshift(content.length-1)
            }else{
                temp3.unshift(temp[0]-1);
            }
            setActiveSlides(temp3);
        } else {
            temp3 = [temp[1], temp[2], temp[3]];
            if(temp[3]===content.length-1){
                temp3.push(0);
            }else {
                temp3.push(temp[3]+1);
            }
        }
        setActiveSlides(temp3);
    }
    return (
        <>
            {showArrows && <ChevronUp fontSize={10} onWheel={() => handleClick("prev")} color="#000" onClick={() => handleClick("prev")} />}
            {activeSlides.map(p => renderIcon(content[p]))}
            {showArrows && <ChevronDown fontSize={10} onWheel={() => handleClick("prev")} color="#000" onClick={() => handleClick("next")} />}
        </>
    )
}
export default MyVerticalSlider;