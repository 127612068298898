export const setUser = (user) => {
    localStorage.setItem('itek_user', JSON.stringify(user));
}

export const getUser = () => JSON.parse(localStorage.getItem('itek_user'));

export const removeUser = () => localStorage.removeItem('itek_user');

export const walls = [
    {label: 'Brick', value: 'brick'},
    {label: 'Stone', value: 'stone'},
    {label: 'Stucco', value: 'stucco'},
    {label: 'Other', value: 'other'},
]

export const floors = [
    {label: 'Wood', value: 'wood'},
    {label: 'Tiles', value: 'tiles'},
    {label: 'Stone', value: 'stone'},
    {label: 'Other', value: 'other'},
]