import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Box, Grid, makeStyles, Button, Link, Typography, SvgIcon } from '@material-ui/core';
import MySlider from '../components/mySlider';
import SceneCard from '../components/sceneCard';
import firebase from 'firebase';
import { closePageLoader, startPageLoader } from '../helpers/commonHelper';
import LoginBackground from '../assets/images/login-bg.jpg';
import Logo from '../assets/images/favicon.svg';
import { Modal } from 'react-bootstrap';
import {
    Lock as LockIcon,
    Eye as EyeIcon,
    EyeOff as EyeOffIcon,
} from 'react-feather';
import { useStateValue } from '../services/state/State';
import { setUser } from '../services/Settings';
import { actions } from '../services/state/Reducer';
import { verifyEmail } from '../services/Validations';

const useStyles = makeStyles((theme) => ({
    root: {},
    bulkOperations: {
        position: 'relative',
    },
    card: {
        position: 'absolute',
        left: '10%',
        top: '10%',
        borderRadius: 10,
        backgroundColor: '#f5f5f5',
        width: 500,
        // height: 500,
        opacity: 0.8
    },
    grid: {
        width: '100%',
        height: '100%',
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5)
    },
    inputLabel: {
        marginTop: theme.spacing(3),
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight
    },
    input: {
        width: '100%',
        height: 35,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight,
    },
    image: {
        width: '100%'
    },
    logo: {
        width: 30
    },
    label: {
        position: 'absolute',
        top: '25%',
    },
    title: {
        fontSize: 80,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight
    },
    subtitle: {
        fontSize: 150,
        color: '#fff',
        fontWeight: 'bold',
        marginTop: -20,
        fontFamily: theme.typography.fontFamily,
    },
    button: {
        // width: 100,
        backgroundColor: '#F7BD37',
        borderRadius: 15,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        boxShadow: '1px 1px 3px 3px rgba(0, 0, 0, 0.1)',
        'text-transform': 'none',
        '&:hover': {
            backgroundColor: '#F7BD37'
        },
    },
    item: {
        marginTop: theme.spacing(6),
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight,
        cursor: 'pointer'
    },
    itemWithoutCursor: {
        marginTop: theme.spacing(6),
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight,
    },
    buttonLabel: {
        color: '#fff',
        fontSize: 18,
        fontFamily: theme.typography.fontFamily,
    },
    bottomLabel: {
        fontSize: 10,
        fontWeight: '700',
        fontFamily: theme.typography.fontFamily,
        color: '#000',
        cursor: 'pointer'
    },
    icon: {
        color: '#fff',
        fontSize: 10,
    },
    svg: {
        marginLeft: theme.spacing(2),
        height: 15,
        width: 15
    },
    appTitle: {
        fontFamily: theme.typography.fontFamily,
    }
}));

const RegisterPage = props => {
    const classes = useStyles();
    const history = useHistory();
    const [state, dispatch] = useStateValue();
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleNameChange = (e) => {
        setName(e.target.value);
    };
    const handleCompanyChange = (e) => {
        setCompany(e.target.value);
    };
    const handlePhoneNoChange = (e) => {
        setPhoneNo(e.target.value);
    };

    const handleUsernameChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const generateUserId = async () => {
        let id = 100;
        let response = await firebase.firestore()
            .collection('clients')
            .orderBy('id', 'desc')
            .limit(1)
            .get()
            .then((snapshot) => {
                snapshot.forEach((element) => {
                    id = parseInt(element.data().id.split('-')[1]);
                });
                return (id = id + 1);
            });
        // console.log('ITEK-' + response);
        return 'ITEK-' + response;
    };


    const handleOnClick = (e) => {
        if (name === '') {
            alert('Please enter name.');
        } else if (company === '') {
            alert('Please enter company.');
        } else if (phoneNo === '') {
            alert('Please enter phone number.');
        } else if (email === '') {
            alert('Please enter email.');
        } else if (!verifyEmail(email)) {
            alert('Please enter valid email address.');
        } else if (password === '') {
            alert('Please enter password');
        } else {
            startPageLoader();
            firebase.auth().createUserWithEmailAndPassword(email, password)
                .then(async(user) => {
                    user.user.sendEmailVerification();
                    const id = await generateUserId();
                    firebase.firestore().collection('clients').doc(email).set({
                        id: id,
                        name: name,
                        email: email,
                        password: password,
                        company: company,
                        phoneNo: phoneNo,
                        approved: false,
                    }).then(() => {
                        setName('');
                        setCompany('');
                        setPhoneNo('');
                        setEmail('');
                        setPassword('');
                        closePageLoader();
                        alert('Your account has been created successfully.');
                    });
                })
                .catch(err => {
                    // console.log(err);
                    closePageLoader();
                    alert('Some error occured while creating your account.')
                })
        }
    };

    useEffect(() => {
        closePageLoader();
    }, []);
    return (
        <>
            <div className="register-page">
                <div className="left">
                </div>
                <div className="right">
                    <img src={LoginBackground} className={classes.image} />
                </div>
            </div>
            <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={6}>
                    <Box className={classes.card}>
                        <Grid container spacing={1} className={classes.grid} justify="center" alignItems="center">
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <img src={Logo} className={classes.logo} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} align="right">
                                <label className={classes.appTitle}>iTek App</label>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <label className={classes.inputLabel}>Name</label><br />
                                <input value={name} onChange={handleNameChange} className={classes.input} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <label className={classes.inputLabel}>Company</label><br />
                                <input value={company} onChange={handleCompanyChange} className={classes.input} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <label className={classes.inputLabel}>Phone Number</label><br />
                                <input value={phoneNo} onChange={handlePhoneNoChange} className={classes.input} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <label className={classes.inputLabel}>Email</label><br />
                                <input value={email} onChange={handleUsernameChange} className={classes.input} />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <label className={classes.inputLabel}>Password</label><br />
                                {showPassword ? <EyeOffIcon color="#000" fontSize={15} onClick={() => setShowPassword(false)} style={{position: 'absolute', right: 80, marginTop: 5}} /> :
                                <EyeIcon color="#000" fontSize={15} onClick={() => setShowPassword(true)} style={{position: 'absolute', right: 80, marginTop: 5}} />}
                                <input value={password} onChange={handlePasswordChange} onKeyPress={(e) => (e.key === "Enter") ? handleOnClick() : null}
                                 type={!showPassword && "password"}  className={classes.input} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} className={classes.item}>
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    color="primary"
                                    onClick={handleOnClick}
                                >
                                    <Typography className={classes.buttonLabel}>
                                        Register
                                    </Typography>
                                    <SvgIcon className={classes.svg}>
                                        <LockIcon className={classes.icon} />
                                    </SvgIcon>
                                </Button>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} align="right" className={classes.item}>
                                <Link
                                    style={{ fontSize: 12 }}
                                    onClick={() => history.push('/login')}
                                >Already have an account?</Link>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} className={classes.itemWithoutCursor}>
                                <label className={classes.bottomLabel}>Want to test our services?</label>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} align="right" className={classes.itemWithoutCursor}>
                            <a className={classes.bottomLabel} href="mailto:info@itektools.com?subject=Information%20about%20iTek%20App&body=Hi%20Itek%20Tools%2C%0D%0A%0D%0AMy%20company%20is%20interested%20in%20testing%20your%20app%20to%20manage%20the%20multimedia%20content%20for%20our%20products%2C%20this%20is%20our%20information%20so%20we%20can%20schedule%20a%20meeting%3A%0D%0A%0D%0ACompany%3A%0D%0AManager%20Name%3A%0D%0APhone%20Number%3A%0D%0A%0D%0AThank%20you!%0D%0A">
                                    <label className={classes.bottomLabel}>Get assistance</label>
                                </a>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid lg={6} md={6}>
                    <div className={classes.label}>
                        <label className={classes.title}>Make it</label><br />
                        <label className={classes.subtitle}>Real</label>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}
export default withRouter(RegisterPage);
