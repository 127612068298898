import React, { useState, createRef, useCallback, useEffect } from 'react';
import { PrevArrow2, NextArrow2 } from '../helpers/commonHelper';
import {
    ChevronUp,
    ChevronDown
} from 'react-feather';
const MyVerticalSliderPopup = props => {
    const { content, renderIcon, relState } = props;
    const [activeSlides, setActiveSlides] = useState([0, 1, 2]);
    const showArrows = content.length > 3;
    const [calling, setCalling] = useState(false);

    const sliderRef = createRef();
    const scroll = useCallback(
        y => {
            if (y > 0) {
                return handleClick("next"); /// ? <- using description below 
            } else {
                return handleClick("prev");
            }
        },
        [sliderRef]
    );
    useEffect(() => {
        // window.addEventListener("wheel", e => {
        //     scroll(e.deltaY);
        // });
        // var loading = false
        // window.addEventListener("wheel", (e) => {
        //     if (!loading) {
        //         loading = true;
        //         scroll(e.deltaY);
        //         loading = false
        //     }
        // })
        function handleWheel(e){
            window.removeEventListener("wheel", handleWheel)
  
            setTimeout(()=>{
                window.addEventListener("wheel", handleWheel);
                scroll(e.deltaY);
            },500);
      }
  
      
      window.addEventListener("wheel", handleWheel)
    }, [scroll]);
    // console.log(renderIcon)
    const handleClick = (direction) => {
        let temp = [...activeSlides], temp2;
        if (direction === 'next') {
            temp2 = [temp[0], temp[1]];
            if (temp[0] === 0) {
                temp2.unshift(content.length - 1)
            } else {
                temp2.unshift(temp[0] - 1);
            }
            setActiveSlides(temp2);
        } else {
            temp2 = [temp[1], temp[2]];
            if (temp[2] === content.length - 1) {
                temp2.push(0);
            } else {
                temp2.push(temp[2] + 1);
            }
        }
        setActiveSlides(temp2);
    }
    return (
        <div ref={sliderRef}>
            {showArrows && <div style={{ width: '100%', cursor: 'pointer' }} onClick={() => handleClick("next")} align="center"><ChevronUp className="slider-popup-class-icon-inside" fontSize={10} color="#000" onClick={() => handleClick("next")} align="center" /></div>}
            {activeSlides.map(p => renderIcon(content[p]))}
            {showArrows && <div style={{ width: '100%', cursor: 'pointer' }} onClick={() => handleClick("prev")} align="center"><ChevronDown className="slider-popup-class-icon-inside" fontSize={10} color="#000" onClick={() => handleClick("prev")} align="center" /></div>}
        </div>
    )
}
export default MyVerticalSliderPopup;