import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Box, Grid, makeStyles, Button, Link, Typography, CardMedia
} from '@material-ui/core';
import SmoothImg from './smoothImg';
import { saveAs } from "file-saver"
import DownloadIcon from '../assets/down-arrow.png'
import RectangleIcon from '../assets/Rectangle-80.png'
import SearchIcon from '../assets/search.png'

const useStyles = makeStyles((theme) => ({
    root: {},
    image: {
        width: 150,
        height: 150,
    },
    imageBlurred: {
        width: 150,
        height: 150,
        'filter': 'blur(2px)',
    },
    downloadIcon: {
        width: 20,
        height: 20,
        'backdrop-filter': 'blur(2px)'
    }
}));
const GalleryCard = props => {
    const classes = useStyles();
    const { scene, history } = props;
    const [showOptions, setShowOptions] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const closePopup = e => {
        // console.log('close')
        e?.preventDefault();
        setIsPopupOpen(false);
    }
    const loaderContainerStyle = {
        width: "150px",
        height: "150px",
    };

    const handleOpenClick = async () => {
        saveAs(scene.url, "downloadedImage.png");
    };

    const handleDownloadClick = async () => {
        fetch(scene.url).then(res => res.blob())
            .then(res => {
                saveAs(res, "downloadedImage.png");
            }).catch(err => {
                // closePageLoader();
                alert('Some error occured.');
            });
    };

    return (
        <>
            {
                scene &&
                <div>
                    <div className="gallery-slide-container" >
                    {showOptions && <div 
                    onMouseEnter={() => setShowOptions(true)} 
                    onMouseOver={() => setShowOptions(true)}
                    style={{ position: 'absolute', top: 100, zIndex: 10, flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly', width: 150 }}>
                                <div style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: 'grey', opacity: 0.7, borderRadius: 5 }}>
                                    <a href={scene.url} target="_blank">
                                        <img src={SearchIcon} className={classes.downloadIcon} />
                                    </a>
                                </div>
                                <div style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: 'grey', opacity: 0.7, borderRadius: 5 }}>
                                    <img src={DownloadIcon} onClick={handleDownloadClick} className={classes.downloadIcon} />
                                </div>
                            </div>}
                        <div 
                        onMouseEnter={() => setShowOptions(true)}
                        onMouseLeave={() => setShowOptions(false)}
                        onMouseOut={() => setShowOptions(false)}
                        className={showOptions ? classes.imageBlurred : classes.image}>
                            <SmoothImg src={scene.url} alt={`${scene.name} Image`}
                                loaderContainerStyle={loaderContainerStyle}
                            />
                        </div>
                        <h6 style={{marginTop: 10, fontSize: 15}}>{scene.name}</h6>
                    </div>
                </div>
            }
        </>
    );
}
export default withRouter(GalleryCard);