import { useEffect, useState, version } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import {
    Box, Grid, makeStyles, Button, Link, Typography, CardMedia,
    Divider, Dialog, SvgIcon, IconButton, CardContent,
    CircularProgress, Backdrop
} from '@material-ui/core';
import ColorSlider from '../components/colorSlider';
import ColorCard from '../components/ColorCard';
import firebase from 'firebase';
import { closePageLoader, startPageLoader, wr360_player } from '../helpers/commonHelper';
import { useStateValue } from '../services/state/State';
import { actions } from '../services/state/Reducer';
import { getUser } from '../services/Settings';
import {
    Edit as EditIcon,
    ChevronLeft as LeftIcon,
    ChevronRight as RightIcon,
    X as XIcon,
    Save as SaveIcon
} from 'react-feather';
import { saveAs } from "file-saver"
import FileDropzone from '../components/FileDropzone';
import MyGallerySlider from '../components/myGallerySlider';
import MyFileSlider from '../components/myFileSlider';
import GalleryCard from '../components/galleryCard';
import FileCard from '../components/FileCard';
import View360 from '../assets/images/360view.png';
import VirtualScenario from '../assets/images/virtualscenario.png';
import Gallery from '../assets/images/gallery.png';
import Gallery2 from '../assets/image-gallery-black.png';
import EmbededCode from '../assets/images/embededcode.png';
import EmbededCode1 from '../assets/images/embededcode1.png';
import Download from '../assets/images/download.png';
import Sofa from '../assets/sofa.png';
import DownloadIcon from '../assets/down-arrow.png'
import UploadIcon from '../assets/cloud-computing.svg';
import DowloadImageIcon from '../assets/dowloadimageicon.png';
import EmbeddedViewIcon from '../assets/embeddedviewicon.png';
import { v4 as uuidv4 } from 'uuid';
import cancelDarkIcon from '../assets/cancel-dark.svg';

const useStyles = makeStyles((theme) => ({
    root: {},
    coverImage: {
        height: 400,
        marginLeft: 10,
        marginRight: 10,
        // 'background-repeat': 'no-repeat',
        // 'background-size': '100%',
        // width: 400,
        // height: 400,
        // padding: '8vw'
    },
    image: {
        width: 60,
        borderRadius: 5,
        border: '1px solid #000',
        boxShadow: '1px 1px 2px 2px #ccc',
        marginTop: 15,
        marginBottom: 15
    },
    imageIcon: {
        width: 80,
        marginTop: 50,
        cursor: 'pointer'
    },
    sofaIcon: {
        width: 130,
        marginTop: -45,
        cursor: 'pointer'
    },
    cardIcon: {
        width: 40,
        cursor: 'pointer'
    },
    iconLabel: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight
    },
    title: {
        fontSize: 40,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight
    },
    button: {
        'text-transform': 'none',
        '&:focus': {
            outline: 'none'
        },
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    buttonLabel: {
        fontSize: 13,
        color: '#000',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight
    },
    buttonLabelGrey: {
        fontSize: 13,
        color: 'grey',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight
    },
    labelClickable: {
        fontSize: 13,
        color: 'grey',
        cursor: 'pointer',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight
    },
    labelClickableSelected: {
        fontSize: 13,
        color: 'grey',
        cursor: 'pointer',
        // 'border-bottom': '3px solid #f7bd37',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight
    },
    downIcon: {
        color: 'grey',
        fontSize: 10,
        marginLeft: 20,
        cursor: 'pointer',
    },
    divider: {
        marginTop: 30,
        height: 5,
        borderRadius: 2,
        backgroundColor: '#f7bd37',
        width: '80%',
        marginLeft: '10%',
        marginRight: '10%',
        marginBottom: 20
    },
    details: {
        marginTop: 20,
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '10%',
        paddingRight: '10%'
    },
    leftImageIcon: {
        width: 40,
        cursor: 'pointer',
        marginTop: 10,
    },
    bottomImageIcon: {
        width: 30,
        cursor: 'pointer',
        marginTop: 10,
    },
    cursor: {
        cursor: 'pointer'
    },
    modal: {
        width: '80%',
        height: 350,
        borderRadius: 10,
        backgroundColor: '#f7f7f7',
        borderColor: '#707070',
        // opacity: 0.98,
        position: 'absolute',
        left: '10%',
        bottom: '10%',
        boxShadow: '1px 1px 3px 3px rgba(211,211,211, 0.1)',
        zIndex: 10,
    },
    modalGallery: {
        width: '100%',
        height: '100%',
        borderRadius: 10,
        backgroundColor: '#fff',
        opacity: 0.9,
        position: 'absolute',
        left: 0,
        top: 0,
        padding: '9%',
        zIndex: 10,
    },
    embededTitle: {
        marginTop: 10,
        fontSize: 30,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight
    },
    bottomLabel: {
        fontSize: 15,
        fontWeight: theme.typography.fontWeightMedium,
        fontFamily: theme.typography.fontFamily,
    },
    card: {
        'border': '1px solid #e3e3e3',
        width: 288,
        height: 192,
        'overflow-wrap': 'break-word'
    },
    close: {
        position: 'absolute',
        top: 20,
        right: 20,
        borderRadius: 15,
        height: 29,
        width: 29,
        backgroundColor: 'black',
    },
    closeGallery: {
        position: 'absolute',
        top: '15%',
        right: '15%',
        borderRadius: 15,
        height: 29,
        width: 29,
        backgroundColor: 'black',
        cursor: 'pointer'
    },
    closeScreen: {
        position: 'absolute',
        top: '5%',
        right: '2%',
        borderRadius: 15,
        height: 29,
        width: 29,
        backgroundColor: 'black',
        cursor: 'pointer'
    },
    disabledCloseScreen: {
        position: 'absolute',
        top: '5%',
        right: '2%',
        borderRadius: 15,
        height: 29,
        width: 29,
        backgroundColor: 'black',
        cursor: 'not-allowed',
    },
    shortDivider: {
        marginTop: 10,
        height: 3,
        borderRadius: 2,
        backgroundColor: '#f7bd37',
        width: '60%',
        marginLeft: '10%',
        marginRight: '10%',
        marginBottom: 20
    },
    iframe: {
        height: '100%',
        width: '100%',
        border: '0px'
    },
    wrapper: {
        overflow: 'hidden',
        padding: 0,
        height: 500,
        // width: 500,
        marginLeft: 10,
        marginRight: 10,
    },
    input: {
        width: '70%',
        height: 35,
    },
    titleInput: {
        width: '50%',
        height: 35,
        marginLeft: '25%'
    },
    imagesimple: {
        width: 130,
        marginTop: -45,
        cursor: 'pointer',
    },
    imageBlurred: {
        width: 130,
        marginTop: -45,
        cursor: 'pointer',
        'filter': 'blur(2px)',
    },
    downloadIcon: {
        width: 20,
        height: 20,
        'backdrop-filter': 'blur(2px)'
    },
    uploadButton: {
        // width: 100,
        backgroundColor: '#F7BD37',
        borderRadius: 15,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        boxShadow: '1px 1px 3px 3px rgba(0, 0, 0, 0.1)',
        'text-transform': 'none',
        '&:hover': {
            backgroundColor: '#F7BD37'
        },
    },
    uploadButtonLabel: {
        color: '#fff',
        fontSize: 18,
        fontFamily: theme.typography.fontFamily,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const DetailsPage = props => {
    const classes = useStyles();
    const history = useHistory();
    const [state, dispatch] = useStateValue();
    const { user, products, rotatorAPI } = state;
    const [scenes, setScenes] = useState({});
    const [product, setProduct] = useState(null);
    const [updateIndex, setUpdateIndex] = useState(0);
    const [updateIndex2, setUpdateIndex2] = useState(0);
    const [selected, setSelected] = useState(true);
    const [galleryItems, setGalleryItems] = useState(null);
    const [selectedVersion, setSelectedVersion] = useState(null);
    const [showEmbededModal, setShowEmbededModal] = useState(false);
    const [showGalleryModal, setShowGalleryModal] = useState(false);
    const [html, setHtml] = useState(null);
    const [editing, setEditing] = useState(false);
    const [name, setName] = useState('');
    const [sku, setSKU] = useState('');
    const [group, setGroup] = useState('');
    const [description, setDescription] = useState('');
    const [api, setAPI] = useState(null);
    const [files, setFiles] = useState(null);
    const [imageName, setImageName] = useState('');
    const [uploadImage, setUploadImage] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);

    const reloadXml = (url) => {
        if (rotatorAPI && rotatorAPI.images) {
            try {
                setTimeout(() => {
                    rotatorAPI.reload(
                        // XML path to the same or different 360 viewer config xml.
                        url,
                        // Optional RootPath if CDN or Master Config support is used (PRO). Usually null.
                        null,
                        // Optional callback which is called when reload is complete.
                        function () {
                            closePageLoader();
                            rotatorAPI.toolbar.playbackStop();
                            setLoading(false);
                        },
                        // Optional zero-based index of the image to load / show first upon reload (-1 or empty to use config settings).
                        rotatorAPI.images.getCurrentImageIndex(),
                        // Reload row index for multi-row 3d views
                        rotatorAPI.images.getCurrentRowIndex());
                }, 0);
            } catch (error) {
                // console.log(error)
            }
        }
    }

    const loadThreeSixtyView = (url) => {
        setLoading(true);
        try {
            setTimeout(() => {
                window.$(document).ready(function () {
                    window.$('#wr360PlayerId').rotator({
                        licenseFileURL: 'license.lic',
                        configFileURL: url,
                        graphicsPath: '../assets/imagerotator/html/img/basic',
                        alt: '',
                        googleEventTracking: false,
                        apiReadyCallback: function (api) {
                            closePageLoader();
                            api.toolbar.playbackStop();
                            setAPI(api);
                            dispatch({ type: actions.SET_ROTATOR_API, payload: api });
                            setLoading(false);
                        },
                    });
                });
            }, 0);
        } catch (error) {
            // console.log(error)
        }
    }

    useEffect(() => {
        setFiles(null);
        startPageLoader();
        const currentUser = getUser();
        if (!currentUser) {
            history.replace('/login');
        } else {
            if (products && props && props.match && props.match.params && props.match.params.sku) {
                let item = products.find(p => p.sku == props.match.params.sku);
                // console.log(item)
                setSelectedVersion(item.versions.length > 0 && item.versions[0]);
                setProduct(item);
                setName(item.name && item.name);
                setSKU(item.clientSKU && item.clientSKU);
                setGroup(item.group && item.group);
                setDescription(item.description && item.description);
                // console.log(item.versions[0].embededLink)
                loadThreeSixtyView(item.versions.length > 0 && item.versions[0].embededLink);
                    reloadXml(item.versions.length > 0 && item.versions[0].embededLink);
                firebase.firestore().collection('clients').doc(user.id === 'ITEK-100' ? user.id : user.email).collection('products').doc(item.sku).onSnapshot(docs =>
                    setGalleryItems(docs.data().gallery))
            } else {
                history.goBack();
            }
            closePageLoader();
        }
        
        return () => {
            console.log('clean up', rotatorAPI)
            if (rotatorAPI) {
                rotatorAPI.delete();
            }
        };
    }, []);

    useEffect(() => {
        if (products && !uploading) {
            let item = products.find(p => p.sku == props.match.params.sku);
            setProduct(item);
            setName(item.name && item.name);
            setSKU(item.clientSKU && item.clientSKU);
            setGroup(item.group && item.group);
            setDescription(item.description && item.description);
        }
    }, [editing, uploading])

    useEffect(() => {
        if (showGalleryModal) {
            document.getElementById('main').style.filter = 'blur(8px)'
        } else {
            document.getElementById('main').style.filter = 'blur(0px)'
        }
    }, [showGalleryModal]);

    const handleOnClick = () => {
        history.goBack();
    };

    const fileDownloadHandler = async () => {
        if (selectedVersion.claimedQuotes >= selectedVersion.quotesPerMonth) {
            alert('Your quote per month limit has been exceeded for this product version.');
        } else {
            let image = document.getElementById('wr360image_wr360PlayerId').getAttribute('src');
            // console.log(image);
            let data = image.split('images/');
            image = data[0] + 'images/highres/' + data[1];
            // console.log(image);
            // startPageLoader();
            fetch(image).then(res => res.blob())
                .then(res => {
                    saveAs(res, "downloadedImage.png");
                }).catch(err => {
                    // closePageLoader();
                    alert('Some error occured.');
                });
            let versions = product.versions;
            let index = versions.findIndex(v => v === selectedVersion);
            versions[index].claimedQuotes = versions[index].claimedQuotes + 1;
            firebase.firestore().collection('clients').doc(user.id === 'ITEK-100' ? user.id : user.email).collection('products').doc(product.sku).update({
                versions: versions
            });
        }
    };

    const handleGoBack = () => {
        if (rotatorAPI) {
            rotatorAPI.delete();
        }
        history.replace('/dashboard');
    };

    const handleOpenModal = () => {
        setShowEmbededModal(true);
    };

    const handleCloseModal = () => {
        setShowEmbededModal(false);
    };

    const handleOpenGalleryModal = () => {
        setShowGalleryModal(true);
    };

    const handleCloseGalleryModal = () => {
        setShowGalleryModal(false);
    };

    const handleChangeVersion = (version) => {
        setLoading(true);
        // startPageLoader();
        reloadXml(version.embededLink);
        setSelectedVersion(version);
    };

    const handleSaveChanges = () => {
        startPageLoader();
        firebase.firestore().collection('clients').doc(user.id === 'ITEK-100' ? user.id : user.email).collection('products').doc(product.sku).update({
            name: name,
            clientSKU: sku,
            group: group,
            description: description
        }).then(() => {
            closePageLoader();
            setEditing(false);
        })
            .catch(() => {
                closePageLoader();
                alert('Some error occured while updating the entries.')
            })
    };

    const handleModalClose = () => {
        setUploadImage(false);
    }

    const handleOnClickUpload = () => {
        setUploadImage(false);
        setUploading(true);
        const ref = firebase.storage().ref().child("images/" + uuidv4());
        ref.put(files).then(res => {
            ref.getDownloadURL().then(url => {
                firebase.firestore().collection('clients').doc(user.id === 'ITEK-100' ? user.id : user.email).collection('products').doc(product.sku).update({
                    gallery: product.gallery && product.gallery.length > 0 ? [...product.gallery, {
                        name: imageName,
                        url: url
                    }]  : [{
                        name: imageName,
                        url: url
                    }]
                })
                    .then(() => {
                        setImageName('');
                        setUploadImage(false);
                        setUploading(false);
                    }).catch(() => setUploading(false))
            });
        })
    }

    const handleMouseEnter = (file) => {
        let data = [...product.galleryFiles];
        let index = data.findIndex(d => d === file);
        data[index].showOptions = true;
        setProduct({
            ...product,
            galleryFiles: data
        });
    }

    const handleMouseExit = (file) => {
        let data = [...product.galleryFiles];
        let index = data.findIndex(d => d === file);
        data[index].showOptions = false;
        setProduct({
            ...product,
            galleryFiles: data
        });
    }

    if (JSON.parse(localStorage.getItem('itek_user')) && JSON.parse(localStorage.getItem('itek_user')).userType === 'admin') {
        history.goBack();
        return null;
    }

    return (
        <>
            <div id="main" className="details-page">
                <Box mt={2} ml={15} mr={15}>
                    <div style={{ zIndex: 10, position: 'absolute', top: 50, right: 50, cursor: 'pointer'}} onClick={() => handleGoBack()} align="center">
                    <img className="close-icon" src={cancelDarkIcon} onClick={handleGoBack}></img>
                    </div>
                    <Grid container spacing={1}
                        alignItems="center"
                        justify="center" style={{ minHeight: '100vh' }}>
                        <Grid item lg={7} md={7} sm={12} align="center">
                            <Grid item lg={3} md={3} xs={3} >
                                {product && <ColorSlider isInfinite numOfSlides={product.versions.length} >
                                    {
                                        product &&
                                        product.versions.map((version, index) => <ColorCard key={index} loading={loading} versionObj={version} version={version.version} name={version.name} handleChangeVersion={() => !loading && handleChangeVersion(version)} />)
                                    }
                                </ColorSlider>}
                            </Grid>
                            <Grid container spacing={1} style={{ width: '90%' }} alignItems='center'>
                                {/* <Grid item lg={1} md={1} sm={1}>
                                    <LeftIcon color="#000" className={classes.cursor} />
                                </Grid> */}
                                <Grid item lg={12} md={12} sm={12}>
                                    {/* <Grid item lg={12} md={12} sm={12}> */}
                                    {product && selectedVersion &&
                                        <>
                                            {/* <div id="imageDiv" className={classes.wrapper}>
                                                {product.versions.map(version => version === selectedVersion && (
                                                    // <iframe id="imgIFrame" src={version.embededLink} frameborder="0" scrolling="no" allowFullScreen=""
                                                    // className={classes.iframe}
                                                ))}
                                            </div> */}
                                            <div id="content" className={classes.wrapper}>

                                                <div id="wr360PlayerId" className="wr360_player" style={{ backgroundColor: '#fff' }}>
                                                </div>

                                            </div>
                                        </>
                                    }
                                </Grid>
                                {/* <Grid item lg={1} md={1} sm={1}>
                                    <RightIcon color="#000" className={classes.cursor} />
                                </Grid> */}
                                <Grid item lg={12} md={12} sm={12}>
                                    <Grid container spacing={1}>
                                        <Grid item lg={6} md={6} sm={6} className={classes.item} align="center">
                                            <img
                                                alt="Product"
                                                src={EmbeddedViewIcon}
                                                className={classes.bottomImageIcon}
                                                onClick={handleOpenModal}
                                            />
                                            <Typography className={classes.buttonLabelGrey} onClick={handleOpenModal} align="center">
                                                Embedded 360 View
                                </Typography>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} className={classes.item} align="center">
                                            <img
                                                alt="Product"
                                                src={DowloadImageIcon}
                                                className={classes.bottomImageIcon}
                                                onClick={fileDownloadHandler}
                                            />
                                            <Typography className={classes.labelClickable} onClick={fileDownloadHandler} align="center">
                                                Download 4k Image
                                </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {product && <Grid item lg={5} md={5} sm={12}>
                            <Typography className={classes.buttonLabel} align="center">
                                Customer ID: {user.id}
                            </Typography>
                            {editing ?
                                <input value={name} onChange={(e) => setName(e.target.value)} className={classes.titleInput} align="center" />
                                : <Typography className={classes.title} align="center">
                                    {product.name}
                                </Typography>}
                            <div className={classes.divider} />
                            <Typography className={classes.buttonLabelGrey} align="center">
                                Product Details
                                {editing ?
                                    <SaveIcon className={classes.downIcon} onClick={handleSaveChanges} /> :
                                    <EditIcon className={classes.downIcon} onClick={() => setEditing(true)} />}
                            </Typography>
                            <Grid container spacing={1} className={classes.details} align="center">
                                <Grid item lg={4} md={4} sm={4} align='left'>
                                    <Typography className={classes.buttonLabel} >
                                        SKU
                            </Typography>
                                    {editing ?
                                        <input value={sku} onChange={(e) => setSKU(e.target.value)} className={classes.input} />
                                        : <Typography className={classes.buttonLabelGrey}>
                                            {product.clientSKU ? product.clientSKU : product.sku}
                                        </Typography>}
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                    <Typography className={classes.buttonLabel}>
                                        Family
                            </Typography>
                                    {editing ?
                                        <input value={group} onChange={(e) => setGroup(e.target.value)} className={classes.input} />
                                        : <Typography className={classes.buttonLabelGrey}>
                                            {product.group}
                                        </Typography>}
                                </Grid>
                                <Grid item lg={4} md={4} sm={4} align='right'>
                                    <Typography className={classes.buttonLabel}>
                                        Versions
                            </Typography>
                                    <Typography className={classes.buttonLabelGrey}>
                                        {product.versions.length}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <div style={{ marginTop: 20, marginLeft: '10%' }}>
                                <Typography className={classes.buttonLabel}>
                                    Short Description
                            </Typography>
                                {editing ?
                                    <input value={description} onChange={(e) => setDescription(e.target.value)} className={classes.input} />
                                    : <Typography className={classes.buttonLabelGrey}>
                                        {product.description.substring(0, 30)}
                                    </Typography>}
                                {/* <Typography className={classes.buttonLabelGrey}>
                                    {product.description.substring(0, 30)}
                                </Typography> */}
                            </div>
                            <Grid container spacing={1}>
                                <Grid item lg={4} md={4} sm={4} className={classes.item} align="center">
                                    <img
                                        alt="Product"
                                        src={View360}
                                        className={classes.imageIcon}
                                    />
                                    <Typography className={classes.labelClickableSelected} align="center">
                                        360 View
                                </Typography>
                                    <div className={classes.shortDivider} />
                                </Grid>
                                <Grid item lg={4} md={4} sm={4} className={classes.item} align="center">
                                    <img
                                        alt="Product"
                                        src={VirtualScenario}
                                        className={classes.imageIcon}
                                        onClick={() => {
                                            if (rotatorAPI) {
                                                rotatorAPI.delete();
                                            }
                                            dispatch({ type: actions.SET_ROTATOR_API, payload: null });
                                            history.push(`/virtual-scenario/${product.sku}`);
                                        }}
                                    />
                                    <Typography className={classes.labelClickable} onClick={() => {
                                        if (rotatorAPI) {
                                            rotatorAPI.delete();
                                        }
                                        dispatch({ type: actions.SET_ROTATOR_API, payload: null });
                                        history.push(`/virtual-scenario/${product.sku}`);
                                    }} align="center">
                                        Virtual Scenario
                                </Typography>
                                </Grid>
                                <Grid item lg={4} md={4} sm={4} className={classes.item} align="center">
                                    <img
                                        alt="Product"
                                        src={Gallery}
                                        className={classes.imageIcon}
                                        onClick={handleOpenGalleryModal}
                                    />
                                    <Typography className={classes.labelClickable} onClick={handleOpenGalleryModal} align="center">
                                        Gallery
                                </Typography>
                                </Grid>
                            </Grid>
                        </Grid>}
                    </Grid>
                </Box>
            </div >
            {showEmbededModal && (
                <Box className={classes.modal} pt={2} pl={7}>
                    <div className={classes.close} onClick={handleCloseModal} align="center">
                        <XIcon color="#fff" size={20} />
                    </div>
                    <Grid container spacing={4}>
                        <Grid item lg={5} md={5} sm={6} align="right">
                            <img
                                alt="Product"
                                src={EmbededCode1}
                                className={classes.leftImageIcon}
                            />
                        </Grid>
                        <Grid item lg={7} md={7} sm={6} align="left" >
                            <Typography className={classes.embededTitle} >
                                Embedded Code
                                </Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            <label className={classes.bottomLabel}>iFrame</label>
                            <div className={classes.card}>
                                <p style={{ fontSize: 12, width: '100%', }}>
                                    {`<iframe width="305" height="305" src="${selectedVersion.frameUrl}" frameborder="0" scrolling="no" style="background-color: transparent; border: 0;" allowfullscreen></iframe>`}</p>
                            </div>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            <label className={classes.bottomLabel}>Responsive</label>
                            <div className={classes.card}>
                                <p style={{ fontSize: 12, width: '100%', }}>
                                    {`<div style="padding: 100% 0 0 0; width: 100%; position: relative;">  <iframe src="${selectedVersion.frameUrl}" frameborder="0" scrolling="no" style="background-color: transparent; border: 0; position: absolute; top: 0; left: 0; width: 100%; height: 100%;" allowfullscreen></iframe></div>`}</p>
                            </div>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            <label className={classes.bottomLabel}>URL</label>
                            <div className={classes.card}>
                                <p style={{ fontSize: 12, width: '100%', }}>
                                    {`${selectedVersion.frameUrl}`}</p>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            )}
            {showGalleryModal && (
                <Box className={classes.modalGallery} pt={2} pl={7}>
                    <div className={classes.closeGallery} onClick={handleCloseGalleryModal} align="center">
                        <XIcon color="#fff" size={20} />
                    </div>
                    <Grid container spacing={4}>
                        <Grid item lg={5} md={5} sm={6} align="right">
                            <img
                                alt="Product"
                                src={Gallery2}
                                className={classes.leftImageIcon}
                            />
                        </Grid>
                        <Grid item lg={7} md={7} sm={6} align="left" >
                            <Typography className={classes.embededTitle} >
                                Product Gallery
                                </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                            {galleryItems && galleryItems.length > 0 && <div style={{ marginRight: 80, marginLeft: 80 }}>
                                <MyGallerySlider slidesToShow={galleryItems.length > 3 ? 4 : galleryItems.length} isInfinite numOfSlides={galleryItems.length} >
                                    {
                                        galleryItems &&
                                        galleryItems.map((scene, index) =>
                                            <GalleryCard key={index} scene={scene} />
                                        )
                                    }
                                </MyGallerySlider>
                            </div>
                            }
                            <Box mt={3}>
                                <img src={UploadIcon} style={{ width: 40 }} onClick={() => setUploadImage(true)} /><br />
                                <label className={classes.iconLabel} style={{ marginTop: 10, fontSize: 12, }}>Upload Images</label>
                            </Box>
                            <Grid container spacing={1} align="center">
                                <Grid item lg={3} md={3} sm={2} xs={2} />
                                <Grid item lg={6} md={6} sm={8} xs={8}>
                                    <Grid container spacing={1} align="center">
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <label className={classes.iconLabel} style={{ marginTop: 50, fontSize: 12, }}>Other Files</label><br />
                                        </Grid>
                                        <Grid item lg={1} md={1} sm={1} xs={1} />
                                        <Grid item lg={10} md={10} sm={10} xs={10} align="center">
                                            {product.galleryFiles && <div style={{ marginRight: 10, marginLeft: 10 }}>
                                                <MyFileSlider slidesToShow={product.galleryFiles.length > 3 ? 4 : product.galleryFiles.length} isInfinite numOfSlides={product.galleryFiles.length} >
                                                    {
                                                        product.galleryFiles.map((file, index) =>
                                                            <FileCard key={index} file={file} />
                                                        )
                                                    }
                                                </MyFileSlider>
                                            </div>}
                                        </Grid>
                                        <Grid item lg={1} md={1} sm={1} xs={1} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )
            }
            <Dialog
                maxWidth="md"
                fullWidth
                onClose={handleModalClose}
                open={uploadImage}
            >
                <Box m={3}>
                    <Grid container spacing={3} className={classes.modalTitle}>
                        <Grid
                            item
                            xs={10}
                            md={11}
                            lg={11}
                        >
                            <Typography
                                variant="h3"
                                color="textPrimary"
                                className={classes.embededTitle}
                            >
                                Upload Images
              </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            md={1}
                            lg={1}
                            align="right"
                        >
                            <IconButton
                                onClick={handleModalClose}
                            >
                                <SvgIcon fontSize="small">
                                    <XIcon />
                                </SvgIcon>
                            </IconButton>
                        </Grid>
                        <Grid item lg={12} md={12} xs={12}>
                            <Divider />
                            <CardContent>
                                <FileDropzone fileType=".png, .jpeg, .jpg, .gif" showNameField file={files} setFile={setFiles} imageName={imageName} setImageName={setImageName} handleUploadImage={handleOnClickUpload} />
                            </CardContent>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
            {uploading && (
                <Backdrop className={classes.backdrop} open={uploading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </>
    )
}
export default withRouter(DetailsPage);
