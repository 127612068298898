import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Box, Grid, makeStyles, Button, Link, Typography, CardMedia
} from '@material-ui/core';
import SmoothImg from './smoothImg';
import { saveAs } from "file-saver"
import DownloadIcon from '../assets/down-arrow.png'
import RectangleIcon from '../assets/Rectangle-80.png'
import SearchIcon from '../assets/search.png'
import CardFile from '../assets/cad-file-format-symbol.png';
import PDFIcon from '../assets/pdf.svg';
import CADIcon from '../assets/cad.svg';
import PSDIcon from '../assets/psd.svg';
import OtherFileIcon from '../assets/file.svg';

const useStyles = makeStyles((theme) => ({
    root: {},
    cardIcon: {
        width: 40,
        height: 40,
        cursor: 'pointer'
    },
    iconLabel: {
        fontSize: 12,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold
    },
}));
const FileCard = props => {
    const classes = useStyles();
    const { file, history, handleMouseEnter, handleMouseExit } = props;

    const handleFileDownload = async (file) => {
        fetch(file.file).then(res => res.blob())
            .then(res => {
                saveAs(res, `file${file.extension}`);
            }).catch(err => {
                // closePageLoader();
                alert('Some error occured.');
            });
    };

    return (
        <>
            {
                file &&
                <div>
                    <div className="file-slide-container" >
                        <label className={classes.iconLabel}>{file.name}</label><br />
                        <img
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseExit}
                            onMouseOut={handleMouseExit}
                            className={classes.cardIcon}
                            alt="Product"
                            src={file.extension === '.pdf' ? PDFIcon : file.extension === '.psd' ? PSDIcon : file.extension === '.cad' ? CADIcon : OtherFileIcon}
                            onClick={() => handleFileDownload(file)}
                        /><br />
                    </div>
                </div>
            }
        </>
    );
}
export default withRouter(FileCard);