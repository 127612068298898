export const actions = {
  SET_USER: 'set_user',
  SET_PRODUCTS: 'set_products',
  SET_ROTATOR_API: 'set_rotator_api',
  SET_LAST_LOCATION: 'set_last_location',
};

export const reducer = (state, action) => {
  // console.log(action);
  switch (action.type) {
    case actions.SET_USER:
      return {
        ...state,
        user: action.payload
      };
    case actions.SET_PRODUCTS:
      return {
        ...state,
        products: action.payload
      };
    case actions.SET_ROTATOR_API:
      return {
        ...state,
        rotatorAPI: action.payload
      };
    case actions.SET_LAST_LOCATION:
      return {
        ...state,
        lastLocation: action.payload
      };
    default:
      return state;
  }
};
