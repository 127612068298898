import React, { useState } from 'react';
import {
    makeStyles,
    Backdrop
} from '@material-ui/core';
import { startPageLoader, closePageLoader } from '../helpers/commonHelper';
import { filterObjectByKeyName } from '../helpers/iterationHelper';
import MySlider from './mySlider';
import MyVerticalSlider from './myVerticalSlider';
import MyVerticalSliderPopup from './myVerticalSliderPopup';
import MySliderVertical from './mySliderVertical';
const useStyles = makeStyles((theme) => ({
    root: {},
    icon: {
        width: 40,
        zIndex: 999,
    },
    icon1: {
        width: 40,
    },
    iconhovered: {
        width: 300,
        height: 300,
        borderRadius: 10,
    },
    image: {
        width: 40,
        height: 40,
        borderRadius: 10,
        border: '1px solid #707070',
        boxShadow: '1px 1px 2px 2px rgba(0, 0, 0, 0.1)',
    },
}));
const CategorySwitcherDecor = props => {
    const classes = useStyles();
    const { hoverDecorPopup, isDecor, openedCat, decorArray, setDecorArray, wrapperClass, iconWrapperClass, parentObj, title, isSlidable, arrowWidth, arrowHeight, arrowFill, clickOpensPopup, relState, changeScene, zIndex, openPopup, isVerticalSlider, forPopup } = props;
    const subCategories = Object.values(parentObj).filter(x => typeof x === "object");
    const isOrderPresent = subCategories.filter(x => x.displayOrder).length === subCategories.length;
    let orderedArr = [];
    if (isOrderPresent) {
        subCategories.map(x => orderedArr[x.displayOrder - 1] = x)
    }
    const arrToMap = orderedArr.length ? orderedArr : subCategories;
    //const sortedArr = sortArrayOfObj(subCategories, "name");
    const handleClick = (obj, event) => {
        if (isDecor) {
            const item = openedCat.find(o => o.name === obj.name);
            const remainingItems = openedCat.filter(o => o.name !== obj.name);
            remainingItems.forEach(item => item.isDefault = false);
            item.isDefault = true;
            const newArr = [...remainingItems, item];
            const data = decorArray ? [...decorArray] : [];
            newArr.forEach(item => {
                data.forEach(d => {
                    // console.log(item, d[item.id])
                    if (d[item.id.toLowerCase()]) {
                        // console.log(item, d[item.id])
                        d[item.id.toLowerCase()] = item;
                    }
                })
            })
            setDecorArray(data);
        }
        if (clickOpensPopup) {
            const modalPos = {
                top: event?.target?.getBoundingClientRect().y + 20,
                left: event?.target?.getBoundingClientRect().x
            }
            const relState = event?.target?.parentElement?.getAttribute("data-state");
            const zIndex = event?.target?.parentElement?.getAttribute("data-zIndex");
            // console.log(relState, zIndex)
            openPopup(obj, modalPos, relState, obj.zIndex);
        } else {
            startPageLoader();
            changeScene(relState, { ...obj, zIndex: obj.zIndex });
            closePageLoader();
        }
    }
    const findStateName = (keyName) => {
        let result = {
            relState: "",
            zIndex: 0
        }
        switch (keyName) {
            case "ceilingDecor": result.relState = "currCeilingDecor"; result.zIndex = 80; break;
            case "centerDecor": result.relState = "currCenterDecor"; result.zIndex = 70; break;
            case "floorDecor": result.relState = "currFloorDecor"; result.zIndex = 60; break;
            case "floors": result.relState = "currFloor"; result.zIndex = 20; break;
            case "leftSideDecor": result.relState = "currLeftDecor"; result.zIndex = 40; break;
            // case "productVersions": result.relState = "currProd"; result.zIndex = 50; break;
            case "rightSideDecor": result.relState = "currRightDecor"; result.zIndex = 40; break;
            case "walls": result.relState = "currWallDecor"; result.zIndex = 10; break;
            case "wallsDecor": result.relState = "currWallDecor"; result.zIndex = 30; break;
        }
        return result;
    }
    const renderIcon = (obj, key) => {
        let dataRelState = relState;
        let dataIndex = zIndex;
        // console.log(obj)
        if (relState === "decor" && obj) {
            let decorObj = filterObjectByKeyName(JSON.parse(sessionStorage.getItem("scene")), "decor");
            Object.keys(decorObj).forEach(key => {
                if (decorObj.decorations.name === obj.name) {
                    let dataVal = findStateName(key);
                    dataRelState = dataVal.relState;
                    dataIndex = dataVal.zIndex;
                }
            })
        }
        // console.log(obj)
        return (
            obj &&
            <>
                <div key={key} className={iconWrapperClass} style={{ backgroundColor: obj.isDefault && '#E6E5E8' }} onClick={e => handleClick(obj, e)} data-state={dataRelState} data-zIndex={dataIndex}>
                    {obj.icon && <img src={obj.icon} className={classes.image} />}
                    {obj.name && <h6>{obj.name}</h6>}
                </div>
            </>
        )
    }

    const onMouseIn = (obj) => {
        props.setHovered(true);
        props.setSelectedItem(obj);
    }
    const onMouseOut = () => {
        props.setHovered(false);
        props.setSelectedItem(null);
    }

    const renderIconPopup = (obj, key) => {
        let dataRelState = relState;
        let dataIndex = zIndex;
        // console.log(obj)
        if (relState === "decor" && obj) {
            let decorObj = filterObjectByKeyName(JSON.parse(sessionStorage.getItem("scene")), "decor");
            Object.keys(decorObj).forEach(key => {
                if (decorObj.decorations.name === obj.name) {
                    let dataVal = findStateName(key);
                    dataRelState = dataVal.relState;
                    dataIndex = dataVal.zIndex;
                }
            })
        }
        // console.log(obj)
        return (
            obj &&
            <>
                {/* {console.log('selectedKey',selectedKey)} */}
                {/* {hovered && (obj.isDefault === true || obj.isDefault === false) && selectedKey && (
            <div onMouseUp={() => onMouseIn(obj.icon)} onMouseDown={() => onMouseIn(obj.icon)} onMouseEnter={() => onMouseIn(obj.icon)} onMouseMove={() => onMouseIn(obj.icon)} onMouseOver={() => onMouseIn(obj.icon)} onMouseOut={() => onMouseOut()} style={{position: 'absolute', left: 0, zIndex: 99999, backgroundColor: '#fff', padding: 5, borderRadius: 5,}}>
            {obj.icon && selectedKey === obj.icon && <img src={obj.icon} alt={`${obj.name}-icon`} className={classes.iconhovered} />}
            </div>
            )} */}
                <div key={key} className={iconWrapperClass} style={{ backgroundColor: obj.isDefault && '#E6E5E8', padding: 5 }} onClick={e => handleClick(obj, e)} data-state={dataRelState} data-zIndex={dataIndex}>
                    {obj.icon && <img src={obj.icon} onMouseUp={() => onMouseIn(obj)} onMouseDown={() => onMouseIn(obj)} onMouseEnter={() => onMouseIn(obj)} onMouseMove={() => onMouseIn(obj)} onMouseOver={() => onMouseIn(obj)} onMouseOut={() => onMouseOut()} style={{
                        width: 40,
                        height: 40,
                        borderRadius: 10,
                        border: '1px solid #707070',
                        boxShadow: '1px 1px 2px 2px rgba(0, 0, 0, 0.1)',
                    }} />}
                    {obj.name && <h6 style={{ fontSize: 14 }}>{obj.name}</h6>}
                </div>
            </>
        )
    }
    return (
        <div className={wrapperClass}>
            <h5 style={{ fontWeight: 'bold' }}>{parentObj.name}</h5>
            {
                isSlidable ?
                    <MySlider numOfSlides={arrToMap.length} arrowWidth={arrowWidth} arrowHeight={arrowHeight} arrowFill={arrowFill}>
                        {arrToMap.map((category, index) => renderIcon(category, index))}
                    </MySlider> :
                    isVerticalSlider && forPopup ?
                        <MySliderVertical hoverDecorPopup={hoverDecorPopup} numOfSlides={arrToMap.length} arrowWidth={arrowWidth} arrowHeight={arrowHeight} arrowFill={arrowFill} >
                            {arrToMap.map((category, index) => renderIconPopup(category, index))}
                        </MySliderVertical> :
                        isVerticalSlider ?
                            <MyVerticalSlider content={arrToMap} renderIcon={renderIcon} relState={parentObj.relState} zIndex={parentObj.zIndex} /> :
                            arrToMap.map((category, index) => renderIcon(category, index))
            }

        </div>
    )
}
export default CategorySwitcherDecor;