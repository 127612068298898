import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Box, Grid, makeStyles, Button, Link, Typography, SvgIcon } from '@material-ui/core';
import MySlider from '../components/mySlider';
import SceneCard from '../components/sceneCard';
import firebase from 'firebase';
import { firebaseConfig } from '../config/firebase.utils';
import { closePageLoader, startPageLoader } from '../helpers/commonHelper';
import LoginBackground from '../assets/images/login-bg.jpg';
import Logo from '../assets/images/favicon.svg';
import { Modal } from 'react-bootstrap';
import {
    Lock as LockIcon,
    Eye as EyeIcon,
    EyeOff as EyeOffIcon,
} from 'react-feather';
import * as firebaseui from "firebaseui";
import { useStateValue } from '../services/state/State';
import { setUser } from '../services/Settings';
import { actions } from '../services/state/Reducer';

const useStyles = makeStyles((theme) => ({
    root: {},
    bulkOperations: {
        position: 'relative',
    },
    card: {
        position: 'absolute',
        left: '10%',
        top: '10%',
        borderRadius: 10,
        backgroundColor: '#f5f5f5',
        width: 500,
        // height: 500,
        opacity: 0.8
    },
    grid: {
        width: '100%',
        height: '100%',
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5)
    },
    inputLabel: {
        marginTop: theme.spacing(3),
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight
    },
    input: {
        width: '100%',
        height: 35,
    },
    image: {
        width: '100%'
    },
    logo: {
        width: 30
    },
    label: {
        position: 'absolute',
        top: '25%',
    },
    title: {
        fontSize: 80,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight
    },
    subtitle: {
        fontSize: 150,
        color: '#fff',
        fontWeight: 'bold',
        marginTop: -20,
        fontFamily: theme.typography.fontFamily,
    },
    button: {
        // width: 100,
        backgroundColor: '#F7BD37',
        borderRadius: 15,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        boxShadow: '1px 1px 3px 3px rgba(0, 0, 0, 0.1)',
        'text-transform': 'none',
        '&:hover': {
            backgroundColor: '#F7BD37'
        },
    },
    item: {
        marginTop: theme.spacing(6),
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight,
        cursor: 'pointer'
    },
    itemWithoutCursor: {
        marginTop: theme.spacing(6),
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight,
    },
    buttonLabel: {
        color: '#fff',
        fontSize: 18,
        fontFamily: theme.typography.fontFamily,
    },
    bottomLabel: {
        fontSize: 10,
        fontWeight: '700',
        fontFamily: theme.typography.fontFamily,
        color: '#000',
        cursor: 'pointer'
    },
    bottomLabelWithCursor: {
        fontSize: 10,
        fontWeight: '700',
        fontFamily: theme.typography.fontFamily,
        cursor: 'pointer'
    },
    icon: {
        color: '#fff',
        fontSize: 10,
    },
    svg: {
        marginLeft: theme.spacing(2),
        height: 15,
        width: 15
    },
    appTitle: {
        fontFamily: theme.typography.fontFamily,
    }
}));

if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
}
const LoginPage = props => {
    const classes = useStyles();
    const history = useHistory();
    const [state, dispatch] = useStateValue();
    const [showModal, setModal] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleOnClick = async (e) => {
        if (username === '') {
            alert('Please enter username.');
        } else if (password === '') {
            alert('Please enter password');
        } else {
            setModal(true);
            if (username.includes('admin')) {
                firebase.firestore().collection('clients').get().then(snapshot => snapshot.forEach(doc => {
                    console.log('here')
                    if (doc.data().email === username) {
                        if (doc.data()) {
                            const user = {
                                ...doc.data(),
                                userType: 'admin',
                            }
                            delete user['password'];
                            setUser(user);
                            dispatch({ type: actions.SET_USER, payload: {
                                ...doc.data(),
                                userType: 'admin',
                            } });
                            closePageLoader();
                            history.push('/admin-dashboard');
                        }
                    }
                }))
                // let user = {
                //     name: 'Admin',
                //     username: 'admin@app.itektools.com',
                //     userType: 'admin',
                // }
                // setUser(user);
                // dispatch({ type: actions.SET_USER, payload: user });

                // const uiConfig = {
                //     signInSuccessUrl: "/admin-dashboard", //This URL is used to return to that page when we got success response for phone authentication.
                //     signInOptions: [firebase.auth.PhoneAuthProvider.PROVIDER_ID],
                //     tosUrl: "/admin-dashboard"
                // };
                // var ui = new firebaseui.auth.AuthUI(firebase.auth());
                // ui.start(document.querySelector('#firebaseui-auth-container'), uiConfig);

                // history.push('/admin-dashboard');
            } else {
                startPageLoader();
                firebase.auth().signInWithEmailAndPassword(username, password)
                    .then(user => {
                        if (user.user.emailVerified || username === 'demo@gmail.com') {
                            firebase.firestore().collection('clients').get().then(snapshot => snapshot.forEach(doc => {
                                if (doc.data().email === username) {
                                    if (doc.data().approved) {
                                        const user = {
                                            ...doc.data(),
                                            userType: 'user',
                                        }
                                        delete user['password'];
                                        setUser(user);
                                        dispatch({ type: actions.SET_USER, payload: {
                                            ...doc.data(),
                                            userType: 'user',
                                        } });
                                        closePageLoader();
                                        history.push('/dashboard');
                                    } else {
                                        closePageLoader();
                                        alert('Your account is not approved by admin.');
                                    }
                                }
                            }))
                        } else {
                            closePageLoader();
                            alert('Please verify your email using link sent to your email account.')
                        }
                    })
                    .catch(err => {
                        // console.log(err);
                        closePageLoader();
                        alert('Invalid username or password')
                    })
            }
        }
    };

    useEffect(() => {
        closePageLoader();
    }, []);
    return (
        <>
            {/* <Modal show={showModal} backdrop="static"
                keyboard={false} onHide={() => setModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Two Factor Authentication</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="firebaseui-auth-container" style={{height: 500, width: 500}}></div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal> */}
            {!showModal &&
            <>
            <div className="login-page">
                <div className="left">
                </div>
                <div className="right">
                    <img src={LoginBackground} className={classes.image} />
                </div>
            </div>
            <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={6}>
                    <Box className={classes.card}>
                        <Grid container spacing={1} className={classes.grid} justify="center" alignItems="center">
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <img src={Logo} className={classes.logo} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} align="right">
                                <label className={classes.appTitle}>iTek App</label>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <label className={classes.inputLabel}>Username</label><br />
                                <input value={username} onChange={handleUsernameChange} className={classes.input} />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <label className={classes.inputLabel}>Password</label><br />
                                {showPassword ? <EyeOffIcon color="#000" fontSize={15} onClick={() => setShowPassword(false)} style={{ position: 'absolute', right: 80, marginTop: 5 }} /> :
                                    <EyeIcon color="#000" fontSize={15} onClick={() => setShowPassword(true)} style={{ position: 'absolute', right: 80, marginTop: 5 }} />}
                                <input value={password} onChange={handlePasswordChange} type={!showPassword && "password"}
                                    onKeyPress={(e) => (e.key === "Enter") ? handleOnClick() : null}
                                    className={classes.input} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} className={classes.item}>
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    color="primary"
                                    onClick={handleOnClick}
                                >
                                    <Typography className={classes.buttonLabel}>
                                            Login
                                    </Typography>
                                    <SvgIcon className={classes.svg}>
                                        <LockIcon className={classes.icon} />
                                    </SvgIcon>
                                </Button>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} align="right" className={classes.item}>
                                <Link style={{ fontSize: 12 }} onClick={() => history.push('/forgotpassword')}>Forgot your password?</Link>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} className={classes.itemWithoutCursor}>
                                <label className={classes.bottomLabelWithCursor} onClick={() => history.push('/register')}>Want to test our services?</label>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} align="right" className={classes.itemWithoutCursor}>
                                <a className={classes.bottomLabel} href="mailto:info@itektools.com?subject=Information%20about%20iTek%20App&body=Hi%20Itek%20Tools%2C%0D%0A%0D%0AMy%20company%20is%20interested%20in%20testing%20your%20app%20to%20manage%20the%20multimedia%20content%20for%20our%20products%2C%20this%20is%20our%20information%20so%20we%20can%20schedule%20a%20meeting%3A%0D%0A%0D%0ACompany%3A%0D%0AManager%20Name%3A%0D%0APhone%20Number%3A%0D%0A%0D%0AThank%20you!%0D%0A">
                                    <label className={classes.bottomLabel}>Get assistance</label>
                                </a>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid lg={6} md={6}>
                    <div className={classes.label}>
                        <label className={classes.title}>Make it</label><br />
                        <label className={classes.subtitle}>Real</label>
                    </div>
                </Grid>
            </Grid>
            </>}
            {/* <div id="firebaseui-auth-container" style={{ height: 500, width: 500 }}></div> */}
        </>
    )
}
export default withRouter(LoginPage);
