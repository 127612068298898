/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  makeStyles,
  TextField
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MoreIcon from '@material-ui/icons/MoreVert';
import bytesToSize from './bytesToSize';

const useStyles = makeStyles((theme) => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5
  },
  image: {
    width: 130
  },
  info: {
    marginTop: theme.spacing(1)
  },
  list: {
    maxHeight: 320
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold
  },
  subtitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightLight
  },
  uploadButton: {
    // width: 100,
    backgroundColor: '#F7BD37',
    borderRadius: 15,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    boxShadow: '1px 1px 3px 3px rgba(0, 0, 0, 0.1)',
    'text-transform': 'none',
    '&:hover': {
      backgroundColor: '#F7BD37'
    },
  },
  uploadButtonLabel: {
    color: '#fff',
    fontSize: 15,
    fontFamily: theme.typography.fontFamily,
  },
}));

function FilesDropzone({
  fileType, handleUploadImage, showNameField, file, setFile, imageName, setImageName, buttonTitle, className, ...rest
}) {
  const classes = useStyles();
  // const [file, setFile] = useState(null);

  const handleDrop = useCallback((acceptedFile) => {
    setFile(acceptedFile[0]);
  }, []);

  const handleRemove = () => {
    setFile(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    accept: fileType,
    onDrop: handleDrop,
  });

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div>
          <img
            alt="Select file"
            className={classes.image}
            src="/static/images/undraw_add_file2_gvbb.svg"
          />
        </div>
        <div>
          <Typography
            gutterBottom
            variant="h3"
            className={classes.label}
          >
            Select file
          </Typography>
          <Box mt={2}>
            <Typography
              color="textPrimary"
              variant="body1"
              className={classes.subtitle}
            >
              Drop file here or click
              {' '}
              <Link underline="always">browse</Link>
              {' '}
              thorough your machine
            </Typography>
          </Box>
        </div>
      </div>
      {file && (
        <>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List className={classes.list}>
              <ListItem>
                <ListItemIcon>
                  <FileCopyIcon />
                </ListItemIcon>
                <ListItemText
                  primary={file.name}
                  primaryTypographyProps={{ variant: 'h5' }}
                  secondary={bytesToSize(file.size)}
                />
                {/* <Tooltip title="More options">
                  <IconButton edge="end">
                    <MoreIcon />
                  </IconButton>
                </Tooltip> */}
              </ListItem>
            </List>
            {showNameField && <Box mt={1}>
              <TextField
                label="Enter Name"
                value={imageName}
                onChange={(e) => setImageName(e.target.value)}
                variant="outlined"
                fullWidth
              />
            </Box>}
          </PerfectScrollbar>
          <div className={classes.actions}>
            {/* <Button
              onClick={handleRemove}
              size="small"
            >
              Remove Image
            </Button> */}
            <Button
              color="secondary"
              size="small"
              variant="contained"
              className={classes.uploadButton}
              onClick={handleUploadImage}
            >
              <Typography className={classes.uploadButtonLabel}>
                {buttonTitle ? buttonTitle : 'Upload Image'}
              </Typography>
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

FilesDropzone.propTypes = {
  className: PropTypes.string,
  fileType: PropTypes.string,
  handleUploadCSV: PropTypes.func,
  file: PropTypes.object,
  setFile: PropTypes.func,
};

export default FilesDropzone;
